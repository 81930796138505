"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("el-form", {
    ref: "resetForm",
    staticClass: "login-form",
    attrs: {
      model: _vm.resetForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "title-container"
  }, [_c("img", {
    staticClass: "login-logo",
    attrs: {
      src: require("@/assets/img/logo.png")
    }
  }), _c("h3", {
    staticClass: "title"
  }, [_vm._v(" Password Change ")])]), _c("el-form-item", {
    attrs: {
      prop: "password"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "password"
    }
  })], 1), _c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.password"),
      name: "password",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.resetForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.resetForm, "password", $$v);
      },
      expression: "resetForm.password"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "confirmPassword"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "password"
    }
  })], 1), _c("el-input", {
    key: _vm.passwordType,
    ref: "confirmPassword",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.confirmPassword"),
      name: "confirmPassword",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.resetForm.confirmPassword,
      callback: function ($$v) {
        _vm.$set(_vm.resetForm, "confirmPassword", $$v);
      },
      expression: "resetForm.confirmPassword"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("el-button", {
    staticStyle: {
      width: "40%",
      "margin-left": "150px",
      "margin-bottom": "30px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v(" Change ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;