import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { getOrdersDispatches, generateFax, generateInvoice, generateEway, cancelEway, generateEInvoice, cancelEInvoice, downloadMergedInvoiceEwayPdf, regenerateInvoice } from "@/api/orders-dispatches";
import Pagination from "@/components/Pagination/index.vue";
import { getQuery, formatJson } from "@/utils/index";
import { exportJson2Excel } from "@/utils/excel";
import Inr from "@/components/Inr/index.vue";
import DispatchList from "./components/DispatchList.vue";
import { getAdmins } from "@/api/admins";
import { getUnits } from "@/api/locations/units";
import { searchDealers } from "@/api/dealers";
import { AdminModule } from "@/store/modules/admin";
import Axios from "axios";
import router from "@/router";
import { searchProducts } from "@/api/products";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = false;
    this.downloadLoading = false;
    this.productListLoading = false;
    this.products = [];
    this.dateRange = "";
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.unitManagers = [];
    this.unitList = [];
    this.dealerList = [];
    this.dealerListLoading = false;
    this.selectedProduct = null;
    this.filteredProducts = [];
    this.isAllSelected = false;
    this.statusOptions = ["Pending", "Request Approval", "Approved", "Rejected"];
    this.statusColorMap = {
      Pending: "warning",
      "Request Approval": "primary",
      Approved: "success",
      Rejected: "danger"
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      unitId: undefined,
      adminId: undefined,
      agentId: undefined,
      status: undefined,
      entryNumber: undefined,
      createdTimestamp: undefined,
      "ordersProductsDispatch.ordersProduct.productsVariationId": undefined,
      "ordersProductsDispatch.ordersProduct.productId": undefined,
      sort: "id,DESC",
      filter: {
        dealerId: "$eq",
        unitId: "$eq",
        adminId: "$eq",
        agentId: "$eq",
        status: "$eq",
        "ordersProductsDispatch.ordersProduct.productsVariationId": "$eq",
        "ordersProductsDispatch.ordersProduct.productId": "$eq",
        entryNumber: "$contL",
        createdTimestamp: "between"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.loggedInAdmin = null;
  }
  activated() {
    if (AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    this.loggedInAdmin = AdminModule;
    // check if logged in user is Agent/Admin
    if (this.loggedInAdmin.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = this.loggedInAdmin.agentId;
    }
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    // get dispatch list
    this.getList();
    // get unit managers
    this.fetchUnitManagers();
    if (!this.listQuery.adminId) {
      this.fetchAllUnitList();
    }
  }
  async getList() {
    try {
      const data = await getOrdersDispatches(getQuery(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async downloadFax() {
    this.listLoading = true;
    try {
      const params = {};
      if (this.listQuery.adminId) {
        params.adminId = this.listQuery.adminId;
      }
      if (this.listQuery.unitId) {
        params.unitId = this.listQuery.unitId;
      }
      if (this.isAllSelected) {
        params.selectedAll = true;
      }
      if (this.listQuery.dealerId) {
        params.dealerId = this.listQuery.dealerId;
      }
      if (this.listQuery.status) {
        params.status = this.listQuery.status;
      }
      if (this.dateRange) {
        params.startDate = this.dateRange.start;
        params.endDate = this.dateRange.end;
      }
      if (Object.keys(params).length) {
        const data = await generateFax(getQuery(params));
        window.open(data.url, "_blank");
        // const filename: string = "fax_" + new Date().getTime() + ".pdf";
        // this.downloadFile(data.url, filename);
      } else {
        this.$message({
          message: "Please select atleast one filter",
          type: "warning"
        });
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async fetchUnitManagers() {
    let managerUnitId;
    // if adding & logged in user is unit manager
    if (this.loggedInAdmin.adminsRoleId === 3) {
      managerUnitId = this.loggedInAdmin.id;
      // set to form data
      this.listQuery.adminId = this.loggedInAdmin.id;
      // fetch units as per manager
      this.fetchUnitList(managerUnitId);
    }
    this.unitManagers = await getAdmins(getQuery({
      id: managerUnitId,
      enabled: true,
      adminsRoleId: 3,
      filter: {
        id: "$eq",
        enabled: "$eq",
        adminsRoleId: "$eq"
      }
    }));
  }
  async fetchUnitList(adminId = 0) {
    try {
      this.unitList = await getUnits(getQuery({
        enable: true,
        "admin.id": adminId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          "admin.id": "$eq"
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchAllUnitList() {
    try {
      this.unitList = await getUnits(getQuery({
        enable: true,
        sort: "name,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
      let all = {
        name: "Select All",
        id: 0,
        code: "SA"
      };
      this.unitList.unshift(all);
    } catch (err) {}
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await searchDealers({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getProductList(query) {
    try {
      this.productListLoading = true;
      if (query && query.length > 2) {
        this.products = await searchProducts(getQuery({
          keyword: query
        }));
      }
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  handleProductSelection(value) {
    if (value) {
      if (value.startsWith("product-")) {
        this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = +value.replace("product-", "");
        this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = undefined;
      } else if (value.startsWith("variation-")) {
        this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = undefined;
        this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = +value.replace("variation-", "");
      }
    } else {
      // Clear the product filters when value is null or empty
      this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = undefined;
      this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = undefined;
    }
    this.handleFilter();
  }
  handleFilter() {
    if (this.dateRange) {
      this.listQuery.createdTimestamp = [this.dateRange.start, this.dateRange.end].join(",");
    } else {
      this.listQuery.createdTimestamp = undefined;
    }
    // getch units if unit manager selected
    if (this.listQuery.adminId) {
      this.fetchUnitList(this.listQuery.adminId);
    } else {
      this.fetchAllUnitList();
    }
    if (this.listQuery.unitId === 0) {
      this.isAllSelected = true;
      this.listQuery.unitId = undefined;
    } else {
      this.isAllSelected = false;
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async downloadInvoice(id = 0) {
    // get invoice url
    this.listLoading = true;
    const url = await generateInvoice(id);
    if (url) {
      window.open(url, "_blank");
      // const filename = "invoice_" + id + ".pdf";
      // this.downloadFile(data.url, filename);
    } else {
      this.$message({
        message: "Failed to generate invoice",
        type: "error"
      });
    }
    this.listLoading = false;
  }
  async generateBill(id = 0, module = null) {
    this.listLoading = true;
    let url = null;
    if (module === "way") {
      const ewayRes = await generateInvoice(id);
      if (ewayRes && ewayRes.status === "success") {
        url = ewayRes.data;
      }
    } else if (module === "einvoice") {
      const eInvoiceRes = await generateInvoice(id);
      if (eInvoiceRes && eInvoiceRes.status === "success") {
        url = eInvoiceRes.data.EinvoicePdf;
      }
    }
    if (url) {
      window.open(url, "_blank");
    } else {
      this.$message({
        message: "Failed to generate invoice",
        type: "error"
      });
    }
    this.listLoading = false;
  }
  downloadFile(url, filename) {
    Axios.get(url, {
      responseType: "blob"
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      this.$message({
        message: error,
        type: "error"
      });
    });
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Mobile Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "emailAddress", "mobileNumber", "enabled", "createdTimestamp"];
    const data = formatJson(filterVal, this.list);
    exportJson2Excel(tHeader, data, this.$tc("route.adminManagement.admins.title"));
    this.downloadLoading = false;
  }
  async createEInvoice(id = 0) {
    // generate E-invoice
    try {
      this.listLoading = true;
      this.$confirm("Do you want to create E-invoice?", "Create E-invoice", {
        confirmButtonText: "Yes",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "No",
        type: "error"
      }).then(async () => {
        const generateEInvoiceResponse = await generateEInvoice(id);
        if (generateEInvoiceResponse && generateEInvoiceResponse.status === "success") {
          this.$message({
            message: "E-invoice created successfully",
            type: "success"
          });
          // refresh list to display einvoice data
          this.getList();
        } else {
          this.$message({
            message: "Failed to create E-invoice",
            type: "error"
          });
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "Request Cancel"
        });
      });
      this.listLoading = false;
    } catch (err) {
      this.listLoading = false;
    }
  }
  async cancelEInvoice(id = 0) {
    // cancel E-invoice
    try {
      this.listLoading = true;
      this.$confirm("Do you want to cancel E-invoice?", "Cancel E-invoice", {
        confirmButtonText: "Yes",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "No",
        type: "error"
      }).then(async () => {
        const cancelEInvoiceResponse = await cancelEInvoice(id);
        if (cancelEInvoiceResponse && cancelEInvoiceResponse.status === "success") {
          this.$message({
            message: "E-invoice cancelled successfully",
            type: "success"
          });
          // refresh list to display einvoice data
          this.getList();
        } else {
          this.$message({
            message: "Failed to cancel E-invoice",
            type: "error"
          });
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "Request Cancel"
        });
      });
      this.listLoading = false;
    } catch (err) {
      this.listLoading = false;
    }
  }
  async createEWay(id = 0) {
    // generate E-way
    try {
      this.listLoading = true;
      this.$confirm("Do you want to create E-way?", "Create E-way", {
        confirmButtonText: "Yes",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "No",
        type: "error"
      }).then(async () => {
        const generateEWayResponse = await generateEway(id);
        if (generateEWayResponse && generateEWayResponse.status === "success") {
          this.$message({
            message: "E-way created successfully",
            type: "success"
          });
          // refresh list to display eway data
          this.getList();
        } else {
          this.$message({
            message: "Failed to create E-way",
            type: "error"
          });
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "Request Cancel"
        });
      });
      this.listLoading = false;
    } catch (err) {
      console.log(err);
      this.listLoading = false;
    }
  }
  async cancelEWay(id = 0) {
    // cancel E-way
    try {
      this.listLoading = true;
      this.$confirm("Do you want to cancel E-way?", "Cancel E-way", {
        confirmButtonText: "Yes",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "No",
        type: "error"
      }).then(async () => {
        const cancelEWayResponse = await cancelEway(id);
        if (cancelEWayResponse && cancelEWayResponse.status === "success") {
          this.$message({
            message: "E-way cancelled successfully",
            type: "success"
          });
          // refresh list to display eway data
          this.getList();
        } else {
          this.$message({
            message: "Failed to cancel E-way",
            type: "error"
          });
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "Request Cancel"
        });
      });
      this.listLoading = false;
    } catch (err) {
      this.listLoading = false;
    }
  }
  getSummaries(param) {
    const {
      columns,
      data
    } = param;
    const sums = [];
    columns.forEach((column, index) => {
      // first column
      if (index === 0) {
        sums[index] = "Total";
        return;
      }
      // quantity & Grand Total column
      if (index === 5 || index === 6) {
        // get all values
        const values = data.map(item => Number(item[column.property]));
        // loop over & null check
        if (!values.every(value => isNaN(value))) {
          // sum  them using reduce
          sums[index] = values.reduce((prev, curr) => {
            // value conversion (do check with Sandesh if toFixed something else required for decimal precision)
            const value = Number(curr);
            // null check
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0); // initial value
        } // loop if ends
      } // index check ends
    }); // columm loop ends
    // return sums
    return sums;
  }
  async downloadMergedPdf(id = 0) {
    // get invoice url
    this.listLoading = true;
    try {
      const data = await downloadMergedInvoiceEwayPdf(id);
      if (data) {
        window.open(data, "_blank");
      } else {
        this.$message({
          message: "Failed to generate invoice",
          type: "error"
        });
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  showUpdateEwayDialogCondition(data) {
    // check if cancel date is present
    if (data.ewayCancelDate) {
      return false;
    }
    let disableUpdateEwayOption = true;
    let dateConditionCheck = false;
    // Add 7 days to the eWayBillDate
    const canUpdateEwayTillDate = this.$moment(data.ewayBillDate, "YYYY-MM-DDTHH:mm:ss.SSS").add(7, "days").format("YYYY-MM-DDTHH:mm:ss.SSS");
    const currentDate = this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    if (this.$moment(currentDate).isBefore(this.$moment(canUpdateEwayTillDate, "YYYY-MM-DDTHH:mm:ss.SSS"))) {
      dateConditionCheck = true;
    }
    if (data.ewayBillNo && !data.ewayCancelDate && dateConditionCheck) {
      disableUpdateEwayOption = false;
    }
    return disableUpdateEwayOption;
  }
  goTo(url) {
    router.push({
      path: url
    });
  }
  getFormatedURL(url = null) {
    if (!url) {
      return "#";
    }
    // Check if the URL starts with 'https://'
    if (!url.startsWith("https://")) {
      // If it does not start with 'http://' or 'https://', prepend 'https://'
      if (!url.startsWith("http://")) {
        url = "https://" + url;
      } else {
        // If it starts with 'http://', replace 'http://' with 'https://'
        url = url.replace("http://", "https://");
      }
    }
    return url;
  }
  async regenerateInvoiceToS3(id) {
    try {
      await regenerateInvoice(id);
      this.$message.success("Invoice regenerated successfully");
    } catch (error) {
      this.$message.error("Error regenerating the invoice");
    }
  }
  isSuperAdmin() {
    // @Todo: Need to change this to id basis.
    return ["Super Admin"].includes(AdminModule.roles.toString());
  }
};
default_1 = __decorate([Component({
  name: "OrdersDispatchList",
  components: {
    Pagination,
    Inr,
    DispatchList
  }
})], default_1);
export default default_1;