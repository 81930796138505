"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-avatar", {
    attrs: {
      shape: _vm.shape,
      fit: _vm.fit,
      size: _vm.size,
      src: _vm.src
    }
  }, [_c("img", {
    attrs: {
      src: "/img/" + _vm.getFileIcon() + ".png"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;