var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "14px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "14px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "140px"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.sort,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "sort", $$v);
      },
      expression: "listQuery.sort"
    }
  }, _vm._l(_vm.sortOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY hh:mm a"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "80",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "referenceNumber",
      label: "Reference"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.referenceNumber) + " "), scope.row.attachment1 ? _c("el-link", {
          attrs: {
            type: "primary",
            href: scope.row.attachment1,
            target: "_blank"
          }
        }, [_c("i", {
          staticClass: "el-icon-paperclip"
        }, [_vm._v(" View Attachment ")])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      prop: "dealer",
      label: "Dealer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("i", {
          staticClass: "el-icon-suitcase"
        }), _vm._v(" " + _vm._s(scope.row.dealer.name) + " "), scope.row.dealer.city ? _c("i", {
          staticClass: "el-icon-place"
        }, [_vm._v(" " + _vm._s(scope.row.dealer.city) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      prop: "status",
      label: "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status === "failure" ? _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: scope.row.errorMessage,
            placement: "top-start"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.statusColorMap[scope.row.status]
          }
        }, [_vm._v(" " + _vm._s(scope.row.status) + " ")])], 1) : _c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.statusColorMap[scope.row.status]
          }
        }, [_vm._v(" " + _vm._s(scope.row.status) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "right",
      prop: "amount",
      label: "Amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.amount
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      prop: "paymentType",
      label: "Type"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      prop: "paymentMode",
      label: "Mode"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      prop: "comments",
      label: "Comments"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Created On",
      width: "140",
      align: "center",
      prop: "transactionDate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.transactionDate || scope.row.createdTimestamp, "DD-MM-YYYY")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status === "pending" ? _c("div", [_c("el-popconfirm", {
          attrs: {
            title: "Are you sure?"
          },
          on: {
            confirm: function ($event) {
              return _vm.approveForm(scope.row, scope.$index);
            }
          }
        }, [_c("el-button", {
          attrs: {
            slot: "reference",
            type: "success",
            size: "mini",
            icon: "el-icon-check"
          },
          slot: "reference"
        })], 1), _vm._v("   "), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-close"
          },
          on: {
            click: function ($event) {
              return _vm.toggleRejectFormDialog(scope.row, scope.$index);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c("el-dialog", {
    attrs: {
      title: "Reject Transaction",
      visible: _vm.rejectFormVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.rejectFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "errorMessage"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autocomplete: "off",
      rows: 5,
      placeholder: "Enter reason for rejection..."
    },
    model: {
      value: _vm.formData.errorMessage,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "errorMessage", $$v);
      },
      expression: "formData.errorMessage"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.rejectFormVisible = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.validateForm
    }
  }, [_vm._v(" Confirm ")])], 1)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };