"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block policy-discount-form"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_vm.formData.discountType === "percent" ? _c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Dealers Role",
      prop: "dealersRoleId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Select Role"
    },
    model: {
      value: _vm.formData.dealersRoleId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealersRoleId", $$v);
      },
      expression: "formData.dealersRoleId"
    }
  }, _vm._l(_vm.roleList, function (role) {
    return _c("el-option", {
      key: role.id,
      attrs: {
        label: role.name,
        value: role.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Range",
      prop: "rangeId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Select Range"
    },
    model: {
      value: _vm.formData.rangeId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "rangeId", $$v);
      },
      expression: "formData.rangeId"
    }
  }, _vm._l(_vm.rangeList, function (range) {
    return _c("el-option", {
      key: range.id,
      attrs: {
        label: range.name,
        value: range.id
      }
    });
  }), 1)], 1), !_vm.isEdit ? _c("el-form-item", {
    attrs: {
      label: "Discount Type",
      prop: "discountType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "percent"
    },
    model: {
      value: _vm.formData.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "discountType", $$v);
      },
      expression: "formData.discountType"
    }
  }, [_vm._v(" Percent ")]), _c("el-radio", {
    attrs: {
      label: "nett"
    },
    model: {
      value: _vm.formData.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "discountType", $$v);
      },
      expression: "formData.discountType"
    }
  }, [_vm._v(" Nett ")])], 1) : _vm._e(), _vm.formData.discountType === "percent" ? _c("el-form-item", {
    attrs: {
      label: "Discount",
      prop: "percentDiscount"
    }
  }, [_c("el-input-number", {
    attrs: {
      required: "",
      precision: 2,
      step: 1,
      max: 100
    },
    model: {
      value: _vm.formData.percentDiscount,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "percentDiscount", $$v);
      },
      expression: "formData.percentDiscount"
    }
  }), _c("span", [_vm._v(" (%)")])], 1) : _vm._e(), _vm.formData.discountType === "nett" ? _c("el-form-item", {
    attrs: {
      label: "Product",
      prop: "productId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      remote: "",
      "remote-method": _vm.fetchProducts,
      placeholder: "Search product"
    },
    on: {
      change: _vm.fetchVariantions
    },
    model: {
      value: _vm.formData.productId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "productId", $$v);
      },
      expression: "formData.productId"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.name,
        value: product.id
      }
    }, [_vm._v(" " + _vm._s(product.catalogueCode) + " - " + _vm._s(product.name) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.formData.discountType === "nett" && _vm.formData.productId ? _c("el-table", {
    staticStyle: {
      width: "80%",
      "margin-top": "20px"
    },
    attrs: {
      data: _vm.policyDiscountList,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Variations",
      align: "center",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.productsVariation ? [_vm._v(" " + _vm._s(scope.row.productsVariation.name) + " ")] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      label: " Nett Rate",
      align: "center",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            precision: 2,
            step: 1,
            max: 10000,
            min: 0
          },
          model: {
            value: scope.row.nettDiscount,
            callback: function ($$v) {
              _vm.$set(scope.row, "nettDiscount", $$v);
            },
            expression: "scope.row.nettDiscount"
          }
        })];
      }
    }], null, false, 4029028325)
  })], 1) : _vm._e(), _c("br"), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;