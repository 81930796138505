"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCompany = exports.getCompanyById = exports.getCompanies = exports.deleteCompany = exports.defaultCompanyData = exports.createCompany = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCompanyData = exports.defaultCompanyData = {
  id: 0,
  enabled: true,
  name: '',
  gstNumber: null,
  panNumber: null,
  addressLine1: '',
  addressLine2: '',
  bankAccountNumber: null,
  bankBranch: null,
  bankIfscCode: null,
  bankName: null,
  logo: null,
  pincodeId: null,
  pincode: {
    id: null
  }
};
const getCompanies = params => (0, _request.default)({
  url: '/companies',
  method: 'get',
  params
});
exports.getCompanies = getCompanies;
const getCompanyById = id => (0, _request.default)({
  url: `/companies/${id}`,
  method: 'get'
});
exports.getCompanyById = getCompanyById;
const updateCompany = (id, data) => (0, _request.default)({
  url: `/companies/${id}`,
  method: 'patch',
  data
});
exports.updateCompany = updateCompany;
const deleteCompany = id => (0, _request.default)({
  url: `/companies/${id}`,
  method: 'delete'
});
exports.deleteCompany = deleteCompany;
const createCompany = data => (0, _request.default)({
  url: '/companies/',
  method: 'post',
  data
});
exports.createCompany = createCompany;