"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-steps", {
    attrs: {
      active: _vm.activeStep,
      "align-center": ""
    }
  }, [_c("el-step", {
    attrs: {
      title: "Step 1",
      description: "Select Products"
    },
    nativeOn: {
      click: function ($event) {
        return _vm.goToStep(0);
      }
    }
  }), _c("el-step", {
    attrs: {
      title: "Step 2",
      description: "Add Address"
    },
    nativeOn: {
      click: function ($event) {
        return _vm.goToStep(1);
      }
    }
  }), _c("el-step", {
    attrs: {
      title: "Step 3",
      description: "Preview"
    },
    nativeOn: {
      click: function ($event) {
        return _vm.goToStep(2);
      }
    }
  })], 1), _c("el-divider"), _vm.activeStep === 0 ? _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    ref: "ordersProductTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.ordersProducts,
      border: "",
      fit: "",
      "highlight-current-row": "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Product",
      prop: "productId",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (scope) {
        return [_c("el-select", {
          attrs: {
            filterable: "",
            remote: "",
            clearable: "",
            "remote-method": _vm.getProductList,
            loading: _vm.productListLoading,
            placeholder: "Select Product",
            size: "mini"
          },
          model: {
            value: _vm.selectedVariationId,
            callback: function ($$v) {
              _vm.selectedVariationId = $$v;
            },
            expression: "selectedVariationId"
          }
        }, _vm._l(_vm.products, function (product) {
          return _c("el-option-group", {
            key: product.id,
            attrs: {
              label: product.name
            }
          }, _vm._l(product.productsVariation, function (item) {
            return _c("el-option", {
              key: item.id,
              attrs: {
                label: product.name + "-" + item.name,
                value: item.id
              }
            }, [_c("span", [_vm._v(_vm._s(item.name))])]);
          }), 1);
        }), 1), _vm.formData.status === "in cart" ? _c("el-button", {
          attrs: {
            disabled: !_vm.selectedVariationId,
            size: "mini",
            type: "success",
            icon: "el-icon-plus"
          },
          on: {
            click: function ($event) {
              return _vm.addVariation(scope);
            }
          }
        }) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function (scope) {
        return [scope.row.product ? _c("strong", {
          staticClass: "text-small"
        }, [_vm._v(" " + _vm._s(scope.row.product.name) + " ")]) : _vm._e()];
      }
    }], null, false, 1141601599)
  }), _c("el-table-column", {
    attrs: {
      label: "Variation",
      prop: "productsVariationId",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.productsVariation ? _c("strong", {
          staticClass: "text-small"
        }, [_vm._v(" " + _vm._s(scope.row.productsVariation.name) + " ")]) : _vm._e()];
      }
    }], null, false, 750040870)
  }), _c("el-table-column", {
    attrs: {
      label: "Cartons",
      prop: "numberOfCartons",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            min: 0,
            max: 100,
            step: 1,
            "step-strictly": ""
          },
          on: {
            change: function ($event) {
              return _vm.checkQuantity(scope.$index, scope.row);
            }
          },
          model: {
            value: scope.row.numberOfCartons,
            callback: function ($$v) {
              _vm.$set(scope.row, "numberOfCartons", $$v);
            },
            expression: "scope.row.numberOfCartons"
          }
        })];
      }
    }], null, false, 2554149024)
  }), _c("br"), _c("el-table-column", {
    attrs: {
      label: "Boxes",
      prop: "numberOfBoxes",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            min: 0,
            max: 100,
            step: 1,
            "step-strictly": ""
          },
          on: {
            change: function ($event) {
              return _vm.checkQuantity(scope.$index, scope.row);
            }
          },
          model: {
            value: scope.row.numberOfBoxes,
            callback: function ($$v) {
              _vm.$set(scope.row, "numberOfBoxes", $$v);
            },
            expression: "scope.row.numberOfBoxes"
          }
        })];
      }
    }], null, false, 889186197)
  }), _c("el-table-column", {
    attrs: {
      label: "Pieces",
      prop: "numberOfPieces",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            min: 0,
            disabled: true,
            step: 1,
            "step-strictly": ""
          },
          model: {
            value: scope.row.numberOfPieces,
            callback: function ($$v) {
              _vm.$set(scope.row, "numberOfPieces", $$v);
            },
            expression: "scope.row.numberOfPieces"
          }
        })];
      }
    }], null, false, 1752714422)
  }), _c("el-table-column", {
    attrs: {
      label: "MRP",
      prop: "mrp",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: !scope.row.allowPriceEdit,
            size: "mini",
            min: 0,
            precision: 2,
            step: 1
          },
          model: {
            value: scope.row.mrp,
            callback: function ($$v) {
              _vm.$set(scope.row, "mrp", $$v);
            },
            expression: "scope.row.mrp"
          }
        })];
      }
    }], null, false, 656331872)
  }), _vm.formData.status === "in cart" ? _c("el-table-column", {
    attrs: {
      align: "center",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "danger",
            icon: "el-icon-delete",
            circle: ""
          },
          on: {
            click: function ($event) {
              return _vm.removeProduct(scope.$index, scope.row);
            }
          }
        })];
      }
    }], null, false, 1756440040)
  }) : _vm._e()], 1), _c("el-select", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ordersProducts.length >= 5,
      expression: "ordersProducts.length >= 5"
    }],
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.getProductList,
      loading: _vm.productListLoading,
      placeholder: "Select Product",
      size: "mini"
    },
    model: {
      value: _vm.selectedVariationId,
      callback: function ($$v) {
        _vm.selectedVariationId = $$v;
      },
      expression: "selectedVariationId"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option-group", {
      key: product.id,
      attrs: {
        label: product.name
      }
    }, _vm._l(product.productsVariation, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: product.name + "-" + item.name,
          value: item.id
        }
      }, [_c("span", [_vm._v(_vm._s(item.name))])]);
    }), 1);
  }), 1), _vm.formData.status === "in cart" ? _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ordersProducts.length >= 5,
      expression: "ordersProducts.length >= 5"
    }],
    attrs: {
      disabled: !_vm.selectedVariationId,
      size: "mini",
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.addVariation(_vm.scope);
      }
    }
  }) : _vm._e(), _c("div", [_c("br"), _vm.formData.status === "in cart" ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.saveProducts
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]) : _vm._e(), _c("p", {
    staticClass: "text-small text-italic",
    attrs: {
      color: "grey"
    }
  }, [_vm._v(" (save after modifications) ")])], 1)], 1) : _vm._e(), _vm.activeStep === 1 ? _c("div", [_vm.formData.status !== "Delivery Confirmed" ? _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Billing Address",
      prop: "billingAddressId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Billing Address"
    },
    model: {
      value: _vm.formData.billingAddressId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "billingAddressId", $$v);
      },
      expression: "formData.billingAddressId"
    }
  }, _vm._l(_vm.addressList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "11px"
      }
    }, [_vm._v(_vm._s(_vm.getFullAddress(item)))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "11px"
      }
    }, [_vm._v("   | ID: " + _vm._s(item.id))])]);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Shipping Address",
      prop: "deliveryAddressId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Shipping Address"
    },
    model: {
      value: _vm.formData.deliveryAddressId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "deliveryAddressId", $$v);
      },
      expression: "formData.deliveryAddressId"
    }
  }, _vm._l(_vm.addressList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "11px"
      }
    }, [_vm._v(_vm._s(_vm.getFullAddress(item)))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "11px"
      }
    }, [_vm._v("   | ID: " + _vm._s(item.id))])]);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Comments",
      prop: "comments"
    }
  }, [_c("el-input", {
    attrs: {
      name: "comments",
      placeholder: "Comments"
    },
    model: {
      value: _vm.formData.comments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1), _vm.formData.status === "in cart" ? _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.activeStep === 2 ? _c("div", [_c("order-info-tab", {
    attrs: {
      data: _vm.formData
    }
  }), _c("order-user-tab", {
    attrs: {
      data: _vm.formData
    }
  }), _vm.validateOrder() ? _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.placeOrder
    }
  }, [_vm._v(" Place Order ")])], 1) : _c("el-alert", {
    attrs: {
      title: _vm.note,
      type: "warning",
      center: "",
      "show-icon": "",
      closable: false
    }
  }), _c("br")], 1) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;