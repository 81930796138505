"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _ordersTransactions = require("@/api/orders-transactions");
var _utils = require("@/utils");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.listLoading = true;
    this.list = [];
    this.tableKey = 0;
  }
  created() {
    this.getList(this.orderId);
  }
  activated() {
    this.getList(this.orderId);
  }
  async getList(id) {
    try {
      this.listLoading = true;
      const data = await (0, _ordersTransactions.getOrdersTransactions)((0, _utils.getQuery)({
        sort: 'id,DESC',
        orderId: this.orderId,
        filter: {
          orderId: '$eq'
        }
      }));
      this.list = data;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "orderId", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrdersTransactionTab',
  components: {
    Inr: _index.default
  }
})], default_1);
var _default = exports.default = default_1;