"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _moqsDiscounts = require("@/api/moqs-discounts");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _utils = require("@/utils");
var _dealersRoles = require("@/api/dealers-roles");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _moqsDiscounts.defaultIMoqsDiscountData);
    this.loading = false;
    this.productList = [];
    this.roleList = [];
    this.moqsDiscountList = [];
    this.moqId = 0;
    this.selectedProduct = null;
    this.rules = {
      productId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      dealersRoleId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _moqsDiscounts.defaultIMoqsDiscountData);
      // assign moq ID
      this.moqId = parseInt(this.$route.params && this.$route.params.moqId);
      this.formData.moqId = this.moqId;
    }
    // get dealers roles
    this.fetchDealersRoles();
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _moqsDiscounts.getMoqsDiscountById)(id);
      this.formData = data;
      this.moqId = data.moqId;
      // get selected product
      if (this.formData.productId) {
        const selectedProduct = await (0, _products.getProductById)(this.formData.productId);
        this.productList = [selectedProduct];
        // fetch variations
        this.fetchVariantions();
      }
      // Just for test
      const title = '#' + this.formData.id;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchProducts(query) {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.searchProducts)((0, _utils.getQuery)({
          keyword: query
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchVariantions() {
    this.moqsDiscountList = [];
    if (this.formData.productId && this.formData.dealersRoleId) {
      const productId = this.formData.productId;
      const dealersRoleId = this.formData.dealersRoleId;
      // get selected product
      this.selectedProduct = await (0, _products.getProductById)(productId);
      // get variations for selected product
      const variations = await (0, _products.getVariations)((0, _utils.getQuery)({
        productId,
        sort: 'name,ASC',
        filter: {
          productId: '$eq'
        }
      }));
      // map data
      if (variations.length) {
        // check if variation already saved to moq discounts
        const savedVariations = await (0, _moqsDiscounts.getMoqsDiscounts)((0, _utils.getQuery)({
          moqId: this.moqId,
          dealersRoleId,
          productId,
          filter: {
            moqId: '$eq',
            productId: '$eq',
            dealersRoleId: '$eq'
          }
        }));
        // map variation ID to each object
        const moqObj = savedVariations.reduce((acc, obj) => {
          acc[obj.productsVariationId] = obj;
          return acc;
        }, {});
        for (const variant of variations) {
          const objToSave = {
            enabled: true,
            moqId: this.moqId,
            dealersRoleId: this.formData.dealersRoleId,
            productId,
            productsVariation: {
              id: variant.id,
              name: variant.name
            },
            looseQuantity: 0,
            cartonQuantity: 0,
            minimumQuantity: 0,
            discount: 0
          };
          if (moqObj.hasOwnProperty(variant.id)) {
            objToSave.id = moqObj[variant.id].id;
            objToSave.looseQuantity = moqObj[variant.id].minimumQuantity;
            objToSave.minimumQuantity = moqObj[variant.id].minimumQuantity;
            objToSave.discount = moqObj[variant.id].discount;
          }
          this.moqsDiscountList.push(objToSave);
        }
      }
    }
  }
  async fetchDealersRoles() {
    this.roleList = await (0, _dealersRoles.getDealersRoles)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  quantityChange(index, qtyType = 'carton') {
    switch (qtyType) {
      case 'pieces':
        this.moqsDiscountList[index].cartonQuantity = 0;
        this.moqsDiscountList[index].minimumQuantity = this.moqsDiscountList[index].looseQuantity;
        break;
      case 'carton':
        this.moqsDiscountList[index].looseQuantity = 0;
        this.moqsDiscountList[index].minimumQuantity = this.moqsDiscountList[index].cartonQuantity * this.selectedProduct.masterCarton;
        break;
      default:
        break;
    }
    // validate percent
    this.validatePercent(index);
  }
  validatePercent(index) {
    if (this.moqsDiscountList[index].minimumQuantity) {
      if (!this.moqsDiscountList[index].discount) {
        this.moqsDiscountList[index].discount = '1';
      }
    } else {
      this.moqsDiscountList[index].discount = 0;
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      for (const key in this.moqsDiscountList) {
        // remove rows with quantity: 0
        if (this.moqsDiscountList[key].minimumQuantity === 0) {
          if (this.moqsDiscountList[key].id) {
            this.moqsDiscountList[key].enabled = false;
          } else {
            this.moqsDiscountList.splice(+key, 1);
          }
        }
      }
      if (this.moqsDiscountList.length) {
        await (0, _moqsDiscounts.manageMoqsDiscount)(this.moqsDiscountList);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Moq discount saved',
        type: 'success',
        duration: 2000
      });
      // router.push("/moqs-discounts/list/" + this.formData.moqId);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'MoqsDiscountDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;