"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendRecoveryMail = exports.fileTransferHeaders = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const sendRecoveryMail = data => (0, _request.default)({
  url: '/queue/email',
  method: 'post',
  data
});
exports.sendRecoveryMail = sendRecoveryMail;