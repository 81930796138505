"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rangesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const rangesSubMenu = exports.rangesSubMenu = [{
  path: '/ranges/list/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/ranges/list.vue'))),
  name: 'productManagement.range.title',
  meta: {
    title: 'productManagement.range.title',
    roles: ['Super Admin', 'Admin', 'Read Only'],
    noCache: true,
    icon: 'tree'
  }
}, {
  path: '/ranges/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/ranges/manage.vue'))),
  name: 'productManagement.range.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.range.manage',
    noCache: true,
    activeMenu: '/ranges/list',
    hidden: true
  }
}, {
  path: '/ranges/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/ranges/manage.vue'))),
  name: 'productManagement.range.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.range.manage',
    noCache: true,
    activeMenu: '/ranges/list',
    hidden: true
  }
}];