"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateState = exports.getStates = exports.getStateById = exports.deleteState = exports.defaultStateData = exports.createState = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultStateData = exports.defaultStateData = {
  id: 0,
  enabled: true,
  countryId: undefined,
  name: '',
  country: {
    id: undefined
  }
};
const getStates = params => (0, _request.default)({
  url: '/states',
  method: 'get',
  params
});
exports.getStates = getStates;
const getStateById = id => (0, _request.default)({
  url: `/states/${id}`,
  method: 'get'
});
exports.getStateById = getStateById;
const updateState = (id, data) => (0, _request.default)({
  url: `/states/${id}`,
  method: 'patch',
  data
});
exports.updateState = updateState;
const deleteState = id => (0, _request.default)({
  url: `/states/${id}`,
  method: 'delete'
});
exports.deleteState = deleteState;
const createState = data => (0, _request.default)({
  url: '/states/',
  method: 'post',
  data
});
exports.createState = createState;