"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _agents = require("@/api/agents");
var _dealers = require("@/api/dealers");
var _admin = require("@/store/modules/admin");
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
var _index4 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = false;
    this.downloadLoading = false;
    this.dateRange = "";
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.agentList = [];
    this.dealerList = [];
    this.stateList = [];
    this.cityList = [];
    this.dealerListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.totalGrandTotal = "";
    this.statusColorMap = {
      Pending: "warning",
      "Request Approval": "primary",
      Approved: "success",
      Rejected: "danger"
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      hsnNumber: undefined,
      startDate: undefined,
      endDate: undefined,
      sort: "id,ASC"
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.loggedInAdmin = null;
  }
  activated() {
    if (_admin.AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    this.loggedInAdmin = _admin.AdminModule;
    // check if logged in user is Agent/Admin
    if (this.loggedInAdmin.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = this.loggedInAdmin.agentId;
    }
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    // get dispatch list
    this.getStateList();
    this.fetchAgentList();
    this.getList();
  }
  async getList() {
    try {
      const data = await (0, _ordersDispatches.dealerWiseOsOdReport)(this.listQuery);
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getCityList(query = null) {
    try {
      if (query && query.length > 2) {
        this.cityListLoading = true;
        this.cityList = await (0, _cities.getCities)((0, _index2.getQuery)({
          name: query,
          filter: {
            name: "$contL"
          }
        }));
      }
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchAgentList() {
    try {
      this.agentList = await (0, _agents.getAgents)((0, _index2.getQuery)({
        enable: true,
        sort: "name,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getStateList(query = null) {
    try {
      this.stateList = await (0, _states.getStates)((0, _index2.getQuery)({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
    } catch (error) {} // eslint-disable-line  no-empty
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await (0, _ordersDispatches.downloadOsOdExcel)(query);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dealer-os-od.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "DealerWiseOsOdReportList",
  components: {
    Pagination: _index.default,
    Inr: _index3.default,
    Enabled: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;