"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ledgers = require("@/api/ledgers");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _dealers = require("@/api/dealers");
var _utils = require("@/utils");
var _index2 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _agents = require("@/api/agents");
var _admin = require("@/store/modules/admin");
var _ordersDispatches = require("@/api/orders-dispatches");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.BASE_URL = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.dealerList = [];
    this.agentList = [];
    this.listLoading = false;
    this.dealerListLoading = false;
    this.agentListLoading = false;
    this.dateRange = {
      start: "",
      end: ""
    };
    this.openingBalance = 0;
    this.closingBalance = 0;
    this.isAgent = false;
    this.loggedInAdmin = null;
    this.isReadOnly = false;
    this.isAccountManager = false;
    this.downloadLoading = false;
    this.listQuery = {
      page: 1,
      limit: 50,
      dealerId: undefined,
      agentId: undefined,
      startDate: undefined,
      endDate: undefined
    };
  }
  activated() {
    if (_admin.AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    if (_admin.AdminModule.roles[0] === "Account Manager") {
      this.isAccountManager = true;
    }
    // get logged in admin
    this.loggedInAdmin = _admin.AdminModule;
    // set date range filter for current month
    this.dateRange = {
      start: this.$moment("2022-04-01").format("YYYY-MM-01 12:00") + " am",
      end: this.$moment("2024-08-7").endOf("m").format("YYYY-MM-DD 11:59") + " pm"
    };
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateRange = {
        start: this.$route.query.startDate,
        end: this.$route.query.endDate
      };
    }
    // fetch agent list
    this.getAgentList();
    // check if logged in user is Agent/Admin
    if (_admin.AdminModule.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = _admin.AdminModule.agentId;
    }
    // check if dealer ID is passed in query params
    if (this.$route.query.dealerId) {
      this.listQuery.dealerId = +this.$route.query.dealerId;
      // fetch selected dealer
      this.getDealerById(this.listQuery.dealerId);
      // fetch ledger data
      this.getList();
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      if (this.dateRange) {
        this.listQuery.startDate = this.dateRange.start;
        this.listQuery.endDate = this.dateRange.end;
      } else {
        this.$notify({
          type: "warning",
          title: "Warning",
          message: "Please select a daterange"
        });
        return false;
      }
      if (!this.listQuery.dealerId && !this.listQuery.agentId) {
        this.$notify({
          type: "warning",
          title: "Warning",
          message: "Please select a dealer or agent"
        });
        return false;
      }
      const data = await (0, _ledgers.getLedgerList)(this.listQuery);
      this.list = data.data;
      this.total = data.total;
      this.openingBalance = data.openingBalance;
      this.closingBalance = data.closingBalance;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    } finally {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getDealerById(dealerId = null) {
    this.dealerListLoading = true;
    const data = await (0, _dealers.getDealerById)(dealerId);
    if (data) {
      this.dealerList = [data];
    }
    this.dealerListLoading = false;
  }
  async getAgentList(query = null) {
    try {
      if (query && query.length > 2) {
        this.agentListLoading = true;
        this.agentList = await (0, _agents.getAgents)((0, _utils.getQuery)({
          name: query,
          filter: {
            name: "$contL"
          }
        }));
      }
      this.agentListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await (0, _ledgers.downloadExcel)(query);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ledgers.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async downloadInvoice(id = 0) {
    try {
      // get invoice url
      this.listLoading = true;
      const url = await (0, _ordersDispatches.generateInvoice)(id);
      if (url) {
        window.open(url, "_blank");
      } else {
        this.$message({
          message: "Failed to generate invoice",
          type: "error"
        });
      }
    } catch (error) {} finally {
      this.listLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "LedgerList",
  components: {
    Pagination: _index.default,
    Inr: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;