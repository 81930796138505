"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.orderInfoData ? _c("el-tabs", {
    attrs: {
      type: "border-card"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Products"
    }
  }, [_c("order-product-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Info"
    }
  }, [_c("order-info-tab", {
    attrs: {
      data: _vm.orderInfoData
    },
    on: {
      refresh: _vm.getOrderInfo
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Dealer"
    }
  }, [_c("order-user-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Dispatches"
    }
  }, [_c("order-dispatch-tab", {
    attrs: {
      data: _vm.orderInfoData.ordersProduct
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Transactions"
    }
  }, [_c("orders-transaction-tab", {
    attrs: {
      "order-id": _vm.orderId
    }
  })], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;