"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _order = require("@/api/order");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _router = _interopRequireDefault(require("@/router"));
var _index2 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _dealersTransporters = require("@/api/dealers-transporters");
var _utils = require("@/utils");
var _admins = require("@/api/admins");
var _units = require("@/api/locations/units");
var _admin = require("@/store/modules/admin");
var _dealers = require("@/api/dealers");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _ordersDispatches.defaultOrdersDispatchData);
    this.loading = false;
    this.ordersProductsListLoading = false;
    this.order = null;
    this.ordersProductsDispatch = [];
    this.transporterList = [];
    this.quantityToBeDispatched = 0;
    this.quantityToSettle = 0;
    this.totalCartons = 0;
    this.tableKey = 0;
    this.unitManagers = [];
    this.unitList = [];
    this.dealerList = [];
    this.dealerLoading = false;
    this.dealerAddress = null;
    this.rules = {
      quantity: {
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      },
      dealersTransporterId: {
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      },
      adminId: {
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      },
      unitId: {
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      },
      dealerId: {
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      },
      privateMarka: [{
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      }, {
        min: 1,
        max: 20,
        message: 'Length should be between 1 - 20',
        trigger: 'blur'
      }],
      dispatchDate: {
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      }
    };
    this.loggedInAdmin = null;
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    this.loggedInAdmin = _admin.AdminModule;
    this.formData = Object.assign({}, _ordersDispatches.defaultOrdersDispatchData);
    this.tempTagView = Object.assign({}, this.$route);
    // set default dispatch date as current
    const currentDate = new Date();
    this.formData.dispatchDate = currentDate;
    // next tick
    this.$nextTick(() => {
      // get unit managers
      this.fetchUnitManagers();
      // get dealers
      // this.getDealerList();
      // do Add Operations
      this.doAddOperations();
    });
  }
  doAddOperations() {}
  async getPendingDispatch(orderId) {
    try {
      this.ordersProductsListLoading = true;
      const products = await (0, _order.getDispatchProducts)(0);
      products.map(val => {
        val.outstandingQty = 0;
      });
      this.ordersProductsDispatch = products;
      this.ordersProductsListLoading = false;
    } catch (err) {
      this.ordersProductsListLoading = false;
    }
  }
  async fetchTransporterList(dealerId = 0) {
    // reset transporter ID
    this.formData.dealersTransporterId = null;
    // fetch dealers transporters
    this.transporterList = await (0, _dealersTransporters.getDealersTransporters)((0, _utils.getQuery)({
      enabled: true,
      dealerId,
      sort: 'isPrimary,ASC',
      filter: {
        enabled: '$eq',
        dealerId: '$eq'
      }
    }));
    // if length is 1 show selected by default
    switch (this.transporterList.length) {
      case 0:
        this.formData.dealersTransporterId = null;
        break;
      case 1:
        this.formData.dealersTransporterId = this.transporterList[0].id;
        break;
      default:
        break;
    }
  }
  async fetchUnitManagers() {
    let managerUnitId;
    // if adding & logged in user is unit manager
    if (!this.isEdit && this.loggedInAdmin.adminsRoleId === 3) {
      managerUnitId = this.loggedInAdmin.id;
      // set to form data
      this.formData.adminId = this.loggedInAdmin.id;
      // fetch units as per manager
      this.fetchUnitList();
    }
    // if editing, then get saved unit manager id
    if (this.isEdit) {
      managerUnitId = this.formData.adminId;
    }
    this.unitManagers = await (0, _admins.getAdmins)((0, _utils.getQuery)({
      id: managerUnitId,
      enabled: true,
      adminsRoleId: 3,
      filter: {
        id: '$eq',
        enabled: '$eq',
        adminsRoleId: '$eq'
      }
    }));
  }
  async fetchUnitList() {
    try {
      // reset unit ID
      this.formData.unitId = null;
      this.resetFields();
      this.unitList = await (0, _units.getUnits)((0, _utils.getQuery)({
        enable: true,
        'admin.id': this.formData.adminId,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq',
          'admin.id': '$eq'
        }
      }));
      switch (this.unitList.length) {
        case 0:
          this.formData.unitId = null;
          break;
        case 1:
          this.formData.unitId = this.unitList[0].id;
          break;
        default:
          break;
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  isDateAllowed(time) {
    return time.getTime() > Date.now();
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title}`;
  }
  quantityUpdated(row) {
    // update totalCartonCount at product level
    row.totalCartonCount = parseFloat((row.quantity / row.product.masterCarton).toFixed(2));
    // check if its positive quantity
    this.quantityToSettle = this.ordersProductsDispatch.reduce((prevQuantity, dispatch) => {
      return prevQuantity + +dispatch.quantity;
    }, 0 // initial value
    );
    if (this.quantityToSettle) {
      // calc total carton qty
      this.totalCartons = this.ordersProductsDispatch.reduce((prevQuantity, dispatch) => {
        return prevQuantity + +dispatch.totalCartonCount;
      }, 0 // initial value
      );
      // round up to nearest decimal
      this.totalCartons = parseFloat(this.totalCartons.toFixed(2));
    }
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        if (this.quantityToSettle > 0) {
          this.saveForm();
        } else {
          this.$notify({
            title: 'Warning',
            message: 'Please add at least 1 quantity to dispatch',
            type: 'warning',
            duration: 2000
          });
          return false;
        }
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      // delete admin, dealer, agent, transporter object from formData if exists
      if (this.formData.admin) {
        delete this.formData.admin;
      }
      if (this.formData.dealer) {
        delete this.formData.dealer;
      }
      if (this.formData.agent) {
        delete this.formData.agent;
      }
      if (this.formData.dealersTransporter) {
        delete this.formData.dealersTransporter;
      }
      // format date
      this.formData.dispatchDate = this.$moment(this.formData.dispatchDate).format('YYYY-MM-DD');
      this.formData.unit = {
        id: this.formData.unitId
      };
      // assign total carton count
      this.formData.totalCartonCount = this.totalCartons;
      // assign ordersProductsDispatch to formData
      this.formData.ordersProductsDispatch = this.ordersProductsDispatch;
      // remove dispatch where quantity is 0
      this.formData.ordersProductsDispatch = this.formData.ordersProductsDispatch.filter(dispatch => {
        return dispatch.quantity > 0;
      });
      let response = {
        status: 'info',
        message: 'Try again!'
      };
      if (!this.isEdit) {
        response = await (0, _ordersDispatches.createOrdersDispatch)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: response.status,
        message: response.message,
        type: response.status,
        duration: 2000
      });
      _router.default.push('/orders-dispatches/list');
    } catch (err) {
      this.loading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      this.loading = true;
      let selectedDealerId;
      if (this.isEdit) {
        selectedDealerId = this.formData.dealerId;
      } else {
        // search only if length is greater than 1
        if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
          this.dealerList = [];
          return;
        }
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? 'dealerId' : 'name'
        // isDispatchSuspended: false
      });
    } catch (err) {
      this.dealerList = [];
    } finally {
      this.loading = false;
    }
  }
  async dealerOrUnitChanged() {
    try {
      this.resetFields();
      // show dealer address
      this.dealerAddress = null;
      if (this.formData.dealerId && this.dealerList.length) {
        this.dealerList.map(val => {
          if (val.id === this.formData.dealerId) {
            if (val.isDispatchSuspended) {
              this.$message({
                type: 'warning',
                message: 'Dispatch for this dealer has been temporarily suspended',
                duration: 3000
              });
              this.formData.dealerId = null;
              return false;
            }
            // check if address exist
            if (val.address.length) {
              const address = val.address[0];
              this.dealerAddress = [address.name, address.line1, address.city.name, address.state.name].join(', ');
              // assing private marka of dealer
              this.formData.privateMarka = val.privateMarka;
            }
            // break loop
            return false;
          }
        });
        // fetch mapped transporters
        if (this.formData.dealerId) {
          this.fetchTransporterList(this.formData.dealerId);
        }
      }
      // if not empty & adding new then get orders dispatch products & available qty
      if (!this.isEdit && this.formData.unitId && this.formData.dealerId) {
        const pendingDispatches = await (0, _ordersDispatches.fetchPendingDispatch)({
          dealerId: this.formData.dealerId || 0,
          unitId: this.formData.unitId || 0
        });
        /**
         * ordersProductsDispatch
         * { id, productId, productName, productsVariationId, productsVariantName, quantityOrdered, quantityDispatched, quantityPending, quantityAvailable, looseCartonCount, standardCartonCount, totalCartonCount }
         */
        if (pendingDispatches) {
          // loop over pending dispatches & add to ordersProductsDispatch array
          pendingDispatches.forEach(dispatch => {
            this.ordersProductsDispatch.push({
              id: 0,
              productId: dispatch.product.id,
              product: dispatch.product,
              productsVariationId: dispatch.productsVariationId,
              productsVariation: dispatch.productsVariation,
              quantityOrdered: dispatch.quantityOrdered,
              quantityDispatched: dispatch.quantityDispatched,
              quantityPending: dispatch.quantityOrdered - dispatch.quantityDispatched,
              quantityAvailable: dispatch.quantityAvailable,
              quantity: 0,
              looseCartonCount: 0,
              standardCartonCount: 0,
              totalCartonCount: 0
            });
          });
        }
      }
    } catch (err) {
      console.trace(err);
      console.error(err);
    }
  }
  // reset dispatch item list, quantity to settle and carton count
  resetFields() {
    this.ordersProductsDispatch = [];
    this.quantityToSettle = 0;
    this.totalCartons = 0;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrdersDispatchDetail',
  components: {
    Inr: _index2.default,
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;