"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _units = require("@/api/locations/units");
var _branches = require("@/api/locations/branches");
var _admins = require("@/api/admins");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _units.defaultUnitData);
    this.managers = [];
    this.selectedAdmin = [];
    this.loading = false;
    this.rules = {
      adminId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      name: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      unitTypes: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      code: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      sequence: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
    this.unitTypes = [{
      value: 'Cona',
      label: 'Cona'
    }, {
      value: 'Agent',
      label: 'Agent'
    }, {
      value: 'Godown',
      label: 'Godown'
    }];
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    // get unit managers
    this.fetchUnitManagers();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _units.defaultUnitData);
      this.formData.branchId = parseInt(this.$route.params && this.$route.params.branchId);
      // fetch branch info
      this.fetchBranch(this.formData.branchId);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _units.getUnitById)(id);
      this.formData = data;
      // populate selected admins
      if (data.admin && data.admin.length) {
        this.selectedAdmin = data.admin.map(admin => admin.id);
      }
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchBranch(branchId) {
    try {
      const branch = await (0, _branches.getBranchById)(branchId);
      this.formData.cityId = branch.cityId;
      this.formData.stateId = branch.stateId;
      this.formData.countryId = branch.countryId;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchUnitManagers() {
    this.managers = await (0, _admins.getAdmins)((0, _utils.getQuery)({
      enabled: true,
      adminsRoleId: 3,
      filter: {
        enabled: '$eq',
        adminsRoleId: '$eq'
      }
    }));
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      this.formData.country = {
        id: this.formData.countryId
      };
      this.formData.state = {
        id: this.formData.stateId
      };
      this.formData.city = {
        id: this.formData.cityId
      };
      this.formData.branch = {
        id: this.formData.branchId
      };
      // save multiple admins
      if (this.selectedAdmin.length) {
        this.formData.admin = [];
        this.selectedAdmin.forEach(admin => {
          this.formData.admin.push({
            id: admin
          });
        });
      }
      if (this.isEdit) {
        await (0, _units.updateUnit)(this.formData.id, this.formData);
      } else {
        await (0, _units.createUnit)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Unit saved',
        type: 'success',
        duration: 2000
      });
      _router.default.push({
        path: '/units/list/' + this.formData.branchId,
        query: this.$route.query
      });
    } catch (err) {
      this.loading = false;
      throw err;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'UnitDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;