import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { downloadExcelOS, fetchOutstandingDealers } from "@/api/orders-dispatches";
import Pagination from "@/components/Pagination/index.vue";
import { getQuery } from "@/utils/index";
import Inr from "@/components/Inr/index.vue";
import { getAgents } from "@/api/agents";
import { AdminModule } from "@/store/modules/admin";
import { getDealers } from "@/api/dealers";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.isAgent = false;
    this.agentList = [];
    this.dealerList = [];
    this.dealerListLoading = false;
    this.dateRange = "";
    this.downloadLoading = false;
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      agentId: undefined,
      startDate: undefined,
      endDate: undefined
    };
  }
  activated() {
    // check if logged in user is Agent/Admin
    if (AdminModule.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = AdminModule.agentId;
    }
    this.getList();
    // fetch agents
    this.fetchAgents();
  }
  async getList() {
    try {
      if (this.dateRange) {
        this.listQuery.startDate = this.dateRange.start;
        this.listQuery.endDate = this.dateRange.end;
      }
      this.listLoading = true;
      const data = await fetchOutstandingDealers(this.listQuery);
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      if (query && query.length > 2) {
        this.dealerListLoading = true;
        this.dealerList = await getDealers(getQuery({
          name: query,
          agentId: this.isAgent ? AdminModule.agentId : undefined,
          filter: {
            name: "$contL",
            agentId: "$eq"
          }
        }));
      }
      this.dealerListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      let startDate = "";
      let endDate = "";
      if (this.listQuery.startDate && this.listQuery.endDate) {
        startDate = this.listQuery.startDate;
        endDate = this.listQuery.endDate;
      }
      // let datas: any = await this.getListWithoutPagination();
      // let data = datas.data;
      const blob = await downloadExcelOS({
        query,
        startDate,
        endDate
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Outstanding.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async fetchAgents() {
    this.agentList = await getAgents(getQuery({
      enabled: true,
      sort: "name,ASC",
      filter: {
        enabled: "eq"
      }
    }));
  }
};
default_1 = __decorate([Component({
  name: "DealerList",
  components: {
    Pagination,
    Inr
  }
})], default_1);
export default default_1;