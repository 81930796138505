"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCountry = exports.getCountryById = exports.getCountries = exports.deleteCountry = exports.defaultCountryData = exports.createCountry = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCountryData = exports.defaultCountryData = {
  id: 0,
  enabled: true,
  code: '',
  name: '',
  zipCodeMinLength: 0,
  zipCodeMaxLength: 0
};
const getCountries = params => (0, _request.default)({
  url: '/countries',
  method: 'get',
  params
});
exports.getCountries = getCountries;
const getCountryById = id => (0, _request.default)({
  url: `/countries/${id}`,
  method: 'get'
});
exports.getCountryById = getCountryById;
const updateCountry = (id, data) => (0, _request.default)({
  url: `/countries/${id}`,
  method: 'patch',
  data
});
exports.updateCountry = updateCountry;
const deleteCountry = id => (0, _request.default)({
  url: `/countries/${id}`,
  method: 'delete'
});
exports.deleteCountry = deleteCountry;
const createCountry = data => (0, _request.default)({
  url: '/countries/',
  method: 'post',
  data
});
exports.createCountry = createCountry;