"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.dispatchInfo ? _c("el-descriptions", {
    attrs: {
      border: "",
      size: "mini",
      column: 3
    }
  }, [_c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-s-order"
  }), _vm._v(" ID ")]), _vm._v(" " + _vm._s(_vm.dispatchInfo.id) + " ")], 2), _vm.dispatchInfo.invoiceNumber ? [_c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-document"
  }), _vm._v(" Invoice Number ")]), _vm._v(" " + _vm._s(_vm.dispatchInfo.invoiceNumber) + " ")], 2)] : [_c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-document"
  }), _vm._v(" Lot Number ")]), _vm._v(" " + _vm._s(_vm.dispatchInfo.entryNumber) + " ")], 2)], _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-flag"
  }), _vm._v(" Status ")]), _c("el-tag", {
    attrs: {
      type: _vm.dispatchInfo.status === "Pending" ? "warning" : "success",
      size: "mini"
    }
  }, [_vm._v(" " + _vm._s(_vm.dispatchInfo.status) + " ")])], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" Dealer ")]), _vm._v(" " + _vm._s(_vm.dispatchInfo.dealer.name) + " "), _vm.dispatchInfo.dealer.code ? _c("span", [_vm._v(" (" + _vm._s(_vm.dispatchInfo.dealer.code) + ") ")]) : _vm._e()], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-truck"
  }), _vm._v(" Terms of Delivery ")]), _vm.dispatchInfo.editingTermsOfDelivery || _vm.dispatchInfo.id ? [_c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: "Please Enter",
      type: "textarea",
      autocomplete: "off",
      rows: 3,
      size: "mini"
    },
    model: {
      value: _vm.dispatchInfo.modifiedTermsOfDelivery,
      callback: function ($$v) {
        _vm.$set(_vm.dispatchInfo, "modifiedTermsOfDelivery", $$v);
      },
      expression: "dispatchInfo.modifiedTermsOfDelivery"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "prepend",
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function ($event) {
        _vm.dispatchInfo.editingTermsOfDelivery = false;
        _vm.dispatchInfo.modifiedTermsOfDelivery = _vm.dispatchInfo.termsOfDelivery;
      }
    },
    slot: "prepend"
  }), _c("el-button", {
    attrs: {
      slot: "append",
      size: "mini",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.saveTermsOfDelivery
    },
    slot: "append"
  })], 1)] : [_vm._v(" " + _vm._s(_vm.dispatchInfo.termsOfDelivery) + "  "), _vm.isSuperAdmin() ? _c("el-link", {
    attrs: {
      icon: "el-icon-edit"
    },
    on: {
      click: function ($event) {
        _vm.dispatchInfo.editingTermsOfDelivery = true;
      }
    }
  }) : _vm._e()]], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-label"
  }), _vm._v(" Private Marka ")]), _vm._v(" " + _vm._s(_vm.dispatchInfo.privateMarka) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-ship"
  }), _vm._v(" Transporter ")]), _vm.dispatchInfo.dealersTransporter ? _c("div", [_vm._v(" " + _vm._s(_vm.dispatchInfo.dealersTransporter.transporter.name) + " ")]) : _c("div", [_vm._v("NA")])], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" Unit Manager ")]), _vm.dispatchInfo.admin ? _c("div", [_vm._v(" " + _vm._s(_vm.dispatchInfo.admin.fullName) + " ")]) : _c("div", [_vm._v("NA")])], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-office-building"
  }), _vm._v(" Unit ")]), _vm.dispatchInfo.unit ? _c("div", [_vm._v(" " + _vm._s(_vm.dispatchInfo.unit.name) + " ")]) : _c("div", [_vm._v("NA")])], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-box"
  }), _vm._v(" Total Carton(s) ")]), _vm._v(" " + _vm._s(_vm.dispatchInfo.totalCartonCount) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-paperclip"
  }), _vm._v(" E-way No ")]), _vm.dispatchInfo.ewayBillNo ? _c("div", [_vm._v(" " + _vm._s(_vm.dispatchInfo.ewayBillNo) + " "), _c("el-link", {
    attrs: {
      type: "primary",
      icon: "el-icon-link",
      target: "_blank",
      href: "https://" + _vm.dispatchInfo.ewayBillUrl
    }
  })], 1) : _c("div", [_vm._v("-")])], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-receipt"
  }), _vm._v(" E-Invoice ACK No ")]), _vm.dispatchInfo.irn ? _c("div", [_vm._v(" " + _vm._s(_vm.dispatchInfo.ackNumber) + " "), _c("el-link", {
    attrs: {
      type: "primary",
      icon: "el-icon-link",
      target: "_blank",
      href: _vm.dispatchInfo.eInvoiceUrl
    }
  })], 1) : _c("div", [_vm._v("-")])], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-timer"
  }), _vm._v(" Created Timestamp ")]), _vm._v(" " + _vm._s(_vm._f("moment")(_vm.dispatchInfo.createdTimestamp, "DD-MM-YYYY hh:mm a")) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-files"
  }), _vm._v(" fromTally ")]), _vm._v(" " + _vm._s(_vm.dispatchInfo.fromTally ? "Yes" : "No") + " ")], 2), _vm.dispatchInfo.fromTally ? _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-postcard"
  }), _vm._v(" Dispatch Date ")]), _vm._v(" " + _vm._s(_vm._f("moment")(_vm.dispatchInfo.dispatchDate, "DD-MM-YYYY")) + " ")], 2) : _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-time"
  }), _vm._v(" Approved Timestamp ")]), _vm._v(" " + _vm._s(_vm._f("moment")(_vm.dispatchInfo.approvedTimestamp, "DD-MM-YYYY hh:mm a")) + " ")], 2)], 2) : _vm._e(), _c("el-divider"), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "empty-text": "No data"
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "80",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "160",
      align: "center",
      prop: "orderId",
      label: "Order"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/order/" + scope.row.orderId,
            type: "primary"
          }
        }, [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(scope.row.order.orderReference) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "280",
      prop: "ordersProductId",
      label: "Product"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.ordersProduct.product.name) + " "), _c("br"), scope.row.ordersProduct.productsVariation ? _c("small", [_vm._v(" (" + _vm._s(scope.row.ordersProduct.productsVariation.name) + ") ")]) : _vm._e()];
      }
    }])
  }), _vm.isSuperAdmin() ? _c("el-table-column", {
    attrs: {
      width: "120px",
      align: "center",
      prop: "totalCartonCount",
      label: "Carton Ordered"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      width: "140px",
      align: "center",
      prop: "ordersProduct.quantity",
      label: "Qty Ordered"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "160",
      align: "center",
      prop: "quantity",
      label: "Qty Dispatched"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            min: scope.row.ordersProduct.product.piecesPerBox,
            max: scope.row.maxQuantity,
            step: scope.row.ordersProduct.product.piecesPerBox,
            "step-strictly": "",
            disabled: scope.row.isDisabled
          },
          model: {
            value: scope.row.quantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "quantity", $$v);
            },
            expression: "scope.row.quantity"
          }
        }), _vm.loggedInAdmin.roles.includes("Unit Manager") && _vm.dispatchInfo && _vm.dispatchInfo.status === "Pending" && scope.row.isDisabled ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "primary",
            plain: ""
          },
          on: {
            click: function ($event) {
              return _vm.toggleDisable(scope.$index, scope.row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-edit"
        })]) : _vm._e(), !scope.row.isDisabled ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "danger",
            plain: ""
          },
          on: {
            click: function ($event) {
              return _vm.toggleDisable(scope.$index, scope.row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-close"
        })]) : _vm._e(), !scope.row.isDisabled ? _c("el-popconfirm", {
          attrs: {
            title: "Are you sure to update the quantity?"
          },
          on: {
            confirm: function ($event) {
              return _vm.toggleDisable(scope.$index, scope.row, "update");
            },
            cancel: function ($event) {
              return _vm.toggleDisable(scope.$index, scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            slot: "reference",
            size: "mini",
            type: "success",
            plain: ""
          },
          slot: "reference"
        }, [_c("i", {
          staticClass: "el-icon-check"
        })])], 1) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MM-YYYY hh:mm a")) + " ")];
      }
    }])
  })], 1), _c("br"), _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.showRequestApprovalButton ? _c("el-popconfirm", {
    attrs: {
      title: "Are you sure?"
    },
    on: {
      confirm: function ($event) {
        return _vm.sendForApproval(_vm.ordersDispatchId);
      }
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingForRequestApproval,
      expression: "loadingForRequestApproval"
    }],
    attrs: {
      slot: "reference",
      size: "mini",
      icon: "el-icon-success",
      type: "success"
    },
    slot: "reference"
  }, [_vm._v(" Send for Approval ")])], 1) : _vm._e(), _vm._v("   "), _vm.showPreviewButton ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingForPreview,
      expression: "loadingForPreview"
    }],
    attrs: {
      size: "mini",
      icon: "el-icon-document",
      type: "warning"
    },
    on: {
      click: function ($event) {
        return _vm.downloadInvoice(_vm.ordersDispatchId);
      }
    }
  }, [_vm._v(" Preview Invoice ")]) : _vm._e(), _vm._v("   "), _vm.showApproveButton ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingForApprove,
      expression: "loadingForApprove"
    }],
    attrs: {
      size: "mini",
      icon: "el-icon-success",
      type: "success"
    },
    on: {
      click: function ($event) {
        _vm.approveDialogFormVisible = true;
      }
    }
  }, [_vm._v(" Approve ")]) : _vm._e(), _vm._v("   "), _vm.showRejectButton ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingForReject,
      expression: "loadingForReject"
    }],
    attrs: {
      size: "mini",
      icon: "el-icon-error",
      type: "danger"
    },
    on: {
      click: function ($event) {
        _vm.rejectDialogFormVisible = true;
      }
    }
  }, [_vm._v(" Reject ")]) : _vm._e()], 1), _c("el-dialog", {
    attrs: {
      title: "Approve Dispatch",
      visible: _vm.approveDialogFormVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.approveDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "termsOfDelivery"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autocomplete: "off",
      rows: 5,
      placeholder: "Enter terms of delivery..."
    },
    model: {
      value: _vm.formData.termsOfDelivery,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "termsOfDelivery", $$v);
      },
      expression: "formData.termsOfDelivery"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "TD: ",
      prop: "tdsAmount"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.tdsAmount) + " ")])], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.approveDialogFormVisible = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.validateForm("Approve");
      }
    }
  }, [_vm._v(" Confirm ")])], 1)], 1), _c("el-dialog", {
    attrs: {
      title: "Reject Dispatch",
      visible: _vm.rejectDialogFormVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.rejectDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "rejectReason"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autocomplete: "off",
      rows: 5,
      placeholder: "Enter reason for rejection..."
    },
    model: {
      value: _vm.formData.rejectReason,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "rejectReason", $$v);
      },
      expression: "formData.rejectReason"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.rejectDialogFormVisible = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.validateForm("Reject");
      }
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;