"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Is Gift Item",
      prop: "isGiftItem"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.isGiftItem,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isGiftItem", $$v);
      },
      expression: "formData.isGiftItem"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Is ISI",
      prop: "isIsi"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.isIsi,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isIsi", $$v);
      },
      expression: "formData.isIsi"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 5,
        maxRows: 10
      },
      name: "description",
      placeholder: "Description",
      maxlength: "1000",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tags",
      prop: "tags"
    }
  }, [_vm._l(_vm.formData.tags ? _vm.formData.tags.split(",") : [], function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        closable: "",
        "disable-transitions": false
      },
      on: {
        close: function ($event) {
          return _vm.handleTagClose(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), _vm.inputTagVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      blur: _vm.handleTagInputConfirm
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleTagInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputTagValue,
      callback: function ($$v) {
        _vm.inputTagValue = $$v;
      },
      expression: "inputTagValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.showTagInput
    }
  }, [_vm._v(" + New Tag ")])], 2), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      loading: _vm.loading,
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;