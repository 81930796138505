"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "wscn-http404-container"
  }, [_c("div", {
    staticClass: "wscn-http404"
  }, [_vm._m(0), _c("div", {
    staticClass: "text-404"
  }, [_c("div", {
    staticClass: "text-404__oops"
  }, [_vm._v(" OOPS! ")]), _vm._m(1), _c("div", {
    staticClass: "text-404__headline"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _c("div", {
    staticClass: "text-404__info"
  }, [_vm._v(" You do not have access to this page. Please contact admin for further details. ")]), _c("a", {
    staticClass: "text-404__return-home",
    attrs: {
      href: ""
    }
  }, [_vm._v("Back to home")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "pic-404"
  }, [_c("img", {
    staticClass: "pic-404__parent",
    attrs: {
      src: require("@/assets/404-images/404.png"),
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child left",
    attrs: {
      src: require("@/assets/404-images/404-cloud.png"),
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child mid",
    attrs: {
      src: require("@/assets/404-images/404-cloud.png"),
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child right",
    attrs: {
      src: require("@/assets/404-images/404-cloud.png"),
      alt: "404"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "text-404__info"
  }, [_vm._v(" All rights reserved "), _c("a", {
    staticStyle: {
      color: "#20a0ff"
    },
    attrs: {
      href: "https://wallstreetcn.com",
      target: "_blank"
    }
  }, [_vm._v("wallstreetcn")])]);
}];
render._withStripped = true;