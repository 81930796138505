"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentsSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const agentsSubMenu = exports.agentsSubMenu = [{
  path: '/agents/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/agents/list.vue'))),
  name: 'dealerManagement.agent.title',
  meta: {
    title: 'dealerManagement.agent.title',
    roles: ['Super Admin', 'Read Only'],
    noCache: true,
    icon: 'agent'
  }
}, {
  path: '/agents/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/agents/manage.vue'))),
  name: 'dealerManagement.agent.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'dealerManagement.agent.manage',
    noCache: true,
    activeMenu: '/agents/list',
    hidden: true
  }
}, {
  path: '/agents/add/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/agents/manage.vue'))),
  name: 'dealerManagement.agent.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'dealerManagement.agent.manage',
    noCache: true,
    activeMenu: '/agents/list',
    hidden: true
  }
}];