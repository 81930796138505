import request from '@/utils/request';
export const fileTransferHeaders = {};
export const defaultSchemesCategoryData = {
  id: null,
  enabled: true,
  name: '',
  product: [],
  schemeId: undefined,
  scheme: {
    id: undefined
  },
  dealersRoleId: 0,
  dealersRole: []
};
export const getSchemesCategories = params => request({
  url: '/schemes-categories',
  method: 'get',
  params
});
export const getSchemesCategoryById = id => request({
  url: `/schemes-categories/${id}`,
  method: 'get'
});
export const updateSchemesCategory = (id, data) => request({
  url: `/schemes-categories/${id}`,
  method: 'patch',
  data
});
export const deleteSchemesCategory = id => request({
  url: `/schemes-categories/${id}`,
  method: 'delete'
});
export const createSchemesCategory = data => request({
  url: '/schemes-categories',
  method: 'post',
  data
});