"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current FY Sales ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalFySales,
      duration: 2600
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current FY Payments ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalFyPayment,
      duration: 2600
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Recievable Amount ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.currentRecievableAmount,
      duration: 2600
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Pending Orders ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalPendingOrders,
      duration: 2600
    }
  })], 1)])])], 1)], 1), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Month Sale ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.currentMonthSales,
      duration: 2600
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Month Payments ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.currentMonthPayments,
      duration: 2600
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Previous Month Sales ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.previousMonthSales,
      duration: 2600
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Previous Month Payments ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.previousMonthPayments,
      duration: 2600
    }
  })], 1)])])], 1)], 1), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Daily Order Value ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dailyOrderValue,
      duration: 2600
    }
  })], 1)])])], 1)], 1), _c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v(" Orders ")]), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, _vm._l(_vm.orderStatus, function (status) {
    return _c("el-col", {
      key: status.status,
      staticClass: "card-panel-col",
      attrs: {
        xs: 12,
        sm: 6,
        lg: 4
      }
    }, [_c("router-link", {
      attrs: {
        to: "/order/list?status=" + status.status
      }
    }, [_c("div", {
      staticClass: "card-panel"
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "card-panel-text"
    }, [_vm._v(" " + _vm._s(status.status) + " ")]), _c("count-to", {
      staticClass: "card-panel-num",
      attrs: {
        "start-val": 0,
        "end-val": status.count,
        duration: 2600
      }
    })], 1)])])], 1);
  }), 1), _c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v(" Dispatch ")]), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, _vm._l(_vm.dispatchStatus, function (status) {
    return _c("el-col", {
      key: status.status,
      staticClass: "card-panel-col",
      attrs: {
        xs: 12,
        sm: 6,
        lg: 4
      }
    }, [_c("router-link", {
      attrs: {
        to: "/orders-dispatches/list?status=" + status.status
      }
    }, [_c("div", {
      staticClass: "card-panel"
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "card-panel-text"
    }, [_vm._v(" " + _vm._s(status.status) + " ")]), _c("count-to", {
      staticClass: "card-panel-num",
      attrs: {
        "start-val": 0,
        "end-val": status.count,
        duration: 2600
      }
    })], 1)])])], 1);
  }), 1), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 8,
      sm: 8,
      lg: 6
    }
  }, [[_c("router-link", {
    attrs: {
      to: "/dealers/list"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-people"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "peoples"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Dealers ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dealerCount,
      duration: 2600
    }
  })], 1)])])]], 2)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;