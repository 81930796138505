"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block scheme-discount-form"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dealers Role",
      prop: "dealersRoleId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Role"
    },
    model: {
      value: _vm.formData.dealersRoleId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealersRoleId", $$v);
      },
      expression: "formData.dealersRoleId"
    }
  }, _vm._l(_vm.roleList, function (role) {
    return _c("el-option", {
      key: role.id,
      attrs: {
        label: role.name,
        value: role.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "State",
      prop: "stateId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select State"
    },
    model: {
      value: _vm.formData.stateId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "stateId", $$v);
      },
      expression: "formData.stateId"
    }
  }, _vm._l(_vm.stateList, function (state) {
    return _c("el-option", {
      key: state.id,
      attrs: {
        label: state.name,
        value: state.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Agent",
      prop: "agentId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Agent"
    },
    model: {
      value: _vm.formData.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "agentId", $$v);
      },
      expression: "formData.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Range",
      prop: "rangeId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Range"
    },
    on: {
      change: _vm.fetchCategories
    },
    model: {
      value: _vm.formData.rangeId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "rangeId", $$v);
      },
      expression: "formData.rangeId"
    }
  }, _vm._l(_vm.rangeList, function (range) {
    return _c("el-option", {
      key: range.id,
      attrs: {
        label: range.name,
        value: range.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Category",
      prop: "categoryId"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.formData.rangeId,
      filterable: "",
      clearable: "",
      placeholder: "Select Category"
    },
    on: {
      change: _vm.fetchSubCategories
    },
    model: {
      value: _vm.formData.categoryId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "categoryId", $$v);
      },
      expression: "formData.categoryId"
    }
  }, _vm._l(_vm.categoryList, function (category) {
    return _c("el-option", {
      key: category.id,
      attrs: {
        label: category.name,
        value: category.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Sub Category",
      prop: "subCategoryId"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.formData.categoryId,
      filterable: "",
      clearable: "",
      placeholder: "Select Category"
    },
    on: {
      change: _vm.resetProducts
    },
    model: {
      value: _vm.formData.subCategoryId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "subCategoryId", $$v);
      },
      expression: "formData.subCategoryId"
    }
  }, _vm._l(_vm.subCategoryList, function (category) {
    return _c("el-option", {
      key: category.id,
      attrs: {
        label: category.name,
        value: category.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Product",
      prop: "productId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      remote: "",
      disabled: !_vm.formData.subCategoryId,
      "remote-method": _vm.fetchProducts,
      placeholder: "Search product"
    },
    on: {
      change: _vm.fetchVariantions
    },
    model: {
      value: _vm.formData.productId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "productId", $$v);
      },
      expression: "formData.productId"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.name,
        value: product.id
      }
    }, [_vm._v(" " + _vm._s(product.catalogueCode) + " - " + _vm._s(product.name) + " ")]);
  }), 1)], 1), _vm.formData.productId ? _c("el-table", {
    staticStyle: {
      width: "100%",
      "margin-top": "20px"
    },
    attrs: {
      data: _vm.schemesDiscountList,
      border: "",
      "span-method": _vm.objectSpanMethod
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Variations",
      align: "center",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.productsVariation.name) + " "), [null, "null"].includes(scope.row.productsVariation.id) ? _c("div", [_c("el-switch", {
          model: {
            value: _vm.disableVariation,
            callback: function ($$v) {
              _vm.disableVariation = $$v;
            },
            expression: "disableVariation"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 3086216507)
  }), _c("el-table-column", {
    attrs: {
      label: "Quantity",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Loose Qty",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: _vm.toggleInput(scope.row),
            min: 0,
            size: "mini"
          },
          on: {
            change: function ($event) {
              return _vm.quantityChange(scope.$index, "pieces");
            }
          },
          model: {
            value: scope.row.looseQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "looseQuantity", $$v);
            },
            expression: "scope.row.looseQuantity"
          }
        })];
      }
    }], null, false, 1478696358)
  }), _c("el-table-column", {
    attrs: {
      label: "Carton Qty",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: _vm.toggleInput(scope.row),
            min: 0,
            size: "mini"
          },
          on: {
            change: function ($event) {
              return _vm.quantityChange(scope.$index, "carton");
            }
          },
          model: {
            value: scope.row.cartonQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "cartonQuantity", $$v);
            },
            expression: "scope.row.cartonQuantity"
          }
        })];
      }
    }], null, false, 2575440437)
  }), _c("el-table-column", {
    attrs: {
      label: "Final Qty (PCS)",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: "",
            controls: false,
            min: 0,
            size: "mini"
          },
          model: {
            value: scope.row.minimumQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "minimumQuantity", $$v);
            },
            expression: "scope.row.minimumQuantity"
          }
        })];
      }
    }], null, false, 141538131)
  })], 1), _c("el-table-column", {
    attrs: {
      label: "Discount (%)",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: !scope.row.minimumQuantity || _vm.toggleInput(scope.row),
            size: "mini",
            precision: 2,
            step: 1,
            max: 100,
            min: 0
          },
          on: {
            change: function ($event) {
              return _vm.validatePercent(scope.$index);
            }
          },
          model: {
            value: scope.row.percentDiscount,
            callback: function ($$v) {
              _vm.$set(scope.row, "percentDiscount", $$v);
            },
            expression: "scope.row.percentDiscount"
          }
        })];
      }
    }], null, false, 1641603964)
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-plus",
            disabled: _vm.variationIdRowsCount[scope.row.productsVariation.id] >= 3
          },
          on: {
            click: function ($event) {
              return _vm.addRow(scope.$index, scope.row);
            }
          }
        }), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-remove",
            disabled: _vm.variationIdRowsCount[scope.row.productsVariation.id] < 3
          },
          on: {
            click: function ($event) {
              return _vm.removeRow(scope.$index, scope.row);
            }
          }
        })];
      }
    }], null, false, 3086725671)
  })], 1) : _vm._e(), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;