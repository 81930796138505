"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _inventoryInwardsLogs = require("@/api/inventory-inwards-logs");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = {};
    this.loading = false;
    this.rules = {
      quantity: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      comments: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.formData = {
      unitId: this.data.unitId,
      productId: this.data.productId,
      productsVariationId: this.data.productsVariationId,
      action: 'outward',
      quantity: 0
    };
    this.tempTagView = Object.assign({}, this.$route);
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      await (0, _inventoryInwardsLogs.createInventoryInwardsLog)(this.formData);
      this.$notify({
        title: 'Success',
        message: 'Inventory updated',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      this.$emit('form-submit', true);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "data", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: true
})], default_1.prototype, "dialogVisible", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'InventoryRemove',
  components: {}
})], default_1);
var _default = exports.default = default_1;