"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _banner = require("@/api/banner");
var _products = require("@/api/products");
var _categories = require("@/api/categories");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _utils = require("@/utils");
var _admin = require("@/store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validity = [];
    this.productList = [];
    this.categoryList = [];
    this.pageList = [{
      label: 'Product',
      page: 'Product'
    }, {
      label: 'Category',
      page: 'Category'
    }, {
      label: 'External Link',
      page: 'External Link'
    }];
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _banner.defaultBannerData);
    this.loading = false;
    this.desktopImage = [];
    this.mobileImage = [];
    this.isReadOnly = false;
    this.rules = {
      mobileImage: [{
        required: true,
        message: 'Please upload image',
        trigger: 'blur'
      }],
      name: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      params: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      categoryId: [{
        required: true,
        message: 'Please select category',
        trigger: 'blur'
      }],
      productId: [{
        required: true,
        message: 'Please select product',
        trigger: 'blur'
      }],
      link: [{
        required: true,
        trigger: 'blur'
      }, {
        pattern: /[-a-zA-Z0-9@%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        message: 'Invalid URL format',
        trigger: ['blur', 'change']
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (_admin.AdminModule.roles[0] === 'Read Only') {
      this.isReadOnly = true;
    }
    // get categories
    this.getCategories();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _banner.defaultBannerData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _banner.getBannerById)(id);
      this.formData = data;
      if (this.formData.validFrom && this.formData.validTill) {
        this.validity = [new Date(this.formData.validFrom), new Date(this.formData.validTill)];
      }
      if (data && data.desktopImage) {
        this.desktopImage.push({
          name: data.name,
          url: data.desktopImage
        });
      }
      if (data && data.mobileImage) {
        this.mobileImage.push({
          name: data.name,
          url: data.mobileImage
        });
      }
      if (this.formData.page === 'Product') {
        this.getProductById(this.formData.productId);
      }
      if (this.formData.page === 'Category') {
        this.getCategoryById(this.formData.categoryId);
      }
      // Just for test
      const title = 'Edit Banner';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (error) {
      this.$message.error(error.toString());
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title} - ${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  desktopImageUploaded(file) {
    this.formData.desktopImage = file.response;
  }
  mobileImageUploaded(file) {
    this.formData.mobileImage = file.response;
  }
  resetForm() {
    this.mobileImage = [];
    this.desktopImage = [];
    if (this.formData.id) {
      this.fetchData(this.formData.id);
    }
  }
  async getProducts(query = '') {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.getProducts)((0, _utils.getQuery)({
          limit: 10,
          enabled: true,
          name: query,
          filter: {
            enabled: 'eq',
            name: '$contL'
          }
        }));
      }
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getProductById(productId = 0) {
    try {
      this.productList = await (0, _products.getProducts)((0, _utils.getQuery)({
        limit: 10,
        enabled: true,
        id: productId,
        filter: {
          enabled: '$eq',
          id: '$eq'
        }
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getCategories() {
    try {
      this.categoryList = await (0, _categories.getCategories)((0, _utils.getQuery)({
        enabled: true,
        parentId: true,
        filter: {
          enabled: '$eq',
          parentId: '$isnull'
        }
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getCategoryById(categoryId = 0) {
    try {
      this.categoryList = await (0, _categories.getCategories)((0, _utils.getQuery)({
        enabled: true,
        parentId: true,
        id: categoryId,
        filter: {
          enabled: '$eq',
          parentId: '$isnull',
          id: '$eq'
        }
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async saveForm() {
    try {
      this.loading = true;
      this.formData.validFrom = this.$moment(this.validity[0]).format('YYYY-MM-DD');
      this.formData.validTill = this.$moment(this.validity[1]).format('YYYY-MM-DD');
      if (this.isEdit) {
        this.formData.category = {
          id: this.formData.categoryId
        };
        this.formData.product = {
          id: this.formData.productId
        };
        await (0, _banner.updateBanner)(this.formData.id, this.formData);
      } else {
        delete this.formData.category;
        delete this.formData.product;
        await (0, _banner.createBanner)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Banner saved',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/banner/list');
    } catch (error) {
      this.loading = false;
    }
  }
  isDateAllowed(time) {
    return time.getTime() < Date.now() - 8.64e7;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'BannerDetail',
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;