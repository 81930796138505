"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const adminRouter = {
  path: '/admins',
  component: _index.default,
  redirect: 'noredirect',
  name: 'Admin Management',
  meta: {
    title: 'adminManagement.title',
    roles: ['Super Admin', 'Admin', 'Read Only'],
    icon: 'user'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */'@/views/admin/list.vue'))),
    name: 'Admins',
    meta: {
      title: 'adminManagement.admins.title',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/admin/manage.vue'))),
    name: 'Edit Admin',
    props: {
      isEdit: true
    },
    meta: {
      title: 'adminManagement.admins.manage',
      noCache: true,
      activeMenu: '/admin/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/admin/manage.vue'))),
    name: 'Add Admin',
    props: {
      isEdit: false
    },
    meta: {
      title: 'adminManagement.admins.manage',
      noCache: true,
      activeMenu: '/admin/list',
      hidden: true
    }
  }, {
    path: '/admin-roles/list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-roles" */'@/views/admin/admin-roles/list.vue'))),
    name: 'Admin Roles',
    meta: {
      title: 'adminManagement.roles.title',
      noCache: true,
      icon: 'skill',
      hidden: true
    }
  }, {
    path: '/admin-roles/add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-roles" */'@/views/admin/admin-roles/manage.vue'))),
    name: 'Add Role',
    props: {
      isEdit: false
    },
    meta: {
      title: 'adminManagement.roles.manage',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/admin-roles/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-roles" */'@/views/admin/admin-roles/manage.vue'))),
    name: 'Edit Role',
    props: {
      isEdit: true
    },
    meta: {
      title: 'adminManagement.roles.manage',
      noCache: true,
      hidden: true
    }
  }]
};
var _default = exports.default = adminRouter;