"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadProductImages = exports.updateProductVariations = exports.updateProductInventories = exports.updateProductImages = exports.updateProductCategories = exports.updateProductAttributes = exports.updateProduct = exports.setImageFromS3 = exports.searchProducts = exports.searchDispatchedProducts = exports.productCount = exports.importProduct = exports.getVariations = exports.getVariationById = exports.getProducts = exports.getProductVariationsById = exports.getProductById = exports.downloadExcel = exports.deleteProductVariation = exports.deleteProductImages = exports.deleteProduct = exports.defaultProductData = exports.createProduct = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultProductData = exports.defaultProductData = {
  id: 0,
  enabled: true,
  schemesCategoryId: undefined,
  rangeId: undefined,
  name: null,
  productType: 'variable',
  priority: null,
  multiplier: 1,
  featured: false,
  isGiftItem: false,
  isIsi: false,
  isfreightAllowed: true,
  catalogueCode: undefined,
  boxesPerCarton: 0,
  piecesPerBox: 0,
  masterCarton: 0,
  suppliedBy: '',
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: true,
  taxId: null,
  hsnCode: null,
  videoUrl: null,
  manageStock: false,
  stockStatus: 'In Stock',
  tax: {
    id: undefined
  },
  schemesCategory: {
    id: undefined
  },
  range: {
    id: undefined
  },
  category: [],
  attribute: [],
  productsImage: [],
  productsVariation: []
};
const getProducts = params => (0, _request.default)({
  url: '/products',
  method: 'get',
  params
});
exports.getProducts = getProducts;
const getProductById = (id, params = null) => (0, _request.default)({
  url: `/products/${id}`,
  method: 'get',
  params
});
exports.getProductById = getProductById;
const getProductVariationsById = (id, params = null) => (0, _request.default)({
  url: `/products-variations/${id}`,
  method: 'get',
  params
});
exports.getProductVariationsById = getProductVariationsById;
const updateProduct = (id, data) => (0, _request.default)({
  url: `/products/${id}`,
  method: 'patch',
  data
});
exports.updateProduct = updateProduct;
const downloadExcel = data => (0, _request.default)({
  url: '/products/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;
const deleteProduct = id => (0, _request.default)({
  url: `/products/${id}`,
  method: 'delete'
});
exports.deleteProduct = deleteProduct;
const createProduct = data => (0, _request.default)({
  url: '/products/',
  method: 'post',
  data
});
exports.createProduct = createProduct;
const productCount = params => (0, _request.default)({
  url: '/products/count',
  method: 'get',
  params
});
exports.productCount = productCount;
const updateProductCategories = (id, data) => (0, _request.default)({
  url: `/products/categories/${id}`,
  method: 'patch',
  data
});
exports.updateProductCategories = updateProductCategories;
const updateProductAttributes = (id, data) => (0, _request.default)({
  url: `/products/attributes/${id}`,
  method: 'patch',
  data
});
exports.updateProductAttributes = updateProductAttributes;
const searchProducts = params => (0, _request.default)({
  url: '/products/search',
  method: 'get',
  params
});
exports.searchProducts = searchProducts;
const searchDispatchedProducts = params => (0, _request.default)({
  url: '/orders-dispatches/dispatched-products-list',
  method: 'get',
  params
});
exports.searchDispatchedProducts = searchDispatchedProducts;
const getVariations = params => (0, _request.default)({
  url: '/products-variations',
  method: 'get',
  params
});
exports.getVariations = getVariations;
const getVariationById = id => (0, _request.default)({
  url: `/products-variations/${id}`,
  method: 'get'
});
exports.getVariationById = getVariationById;
const updateProductVariations = data => (0, _request.default)({
  url: '/products-variations/manage',
  method: 'post',
  data
});
exports.updateProductVariations = updateProductVariations;
const deleteProductVariation = id => (0, _request.default)({
  url: `/products-variations/${id}`,
  method: 'delete'
});
exports.deleteProductVariation = deleteProductVariation;
const updateProductImages = (id, data) => (0, _request.default)({
  url: `/products/images/${id}`,
  method: 'patch',
  data
});
exports.updateProductImages = updateProductImages;
const deleteProductImages = id => (0, _request.default)({
  url: `/products-images/${id}`,
  method: 'delete'
});
exports.deleteProductImages = deleteProductImages;
const updateProductInventories = (id, data) => (0, _request.default)({
  url: `/products/inventories/${id}`,
  method: 'patch',
  data
});
exports.updateProductInventories = updateProductInventories;
const uploadProductImages = data => (0, _request.default)({
  url: '/products/images',
  method: 'post',
  data
});
exports.uploadProductImages = uploadProductImages;
const importProduct = data => (0, _request.default)({
  url: '/products/file-import',
  method: 'post',
  data
});
exports.importProduct = importProduct;
const setImageFromS3 = data => (0, _request.default)({
  url: '/products-images/set-from-s3',
  method: 'post',
  data
});
// export const reuploadInvoiceToS3 = (id: number) =>
exports.setImageFromS3 = setImageFromS3;