"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "tinymce-container",
    class: {
      fullscreen: _vm.fullscreen
    },
    style: {
      width: _vm.containerWidth
    }
  }, [_c("tinymce-editor", {
    attrs: {
      id: _vm.id,
      init: _vm.initOptions
    },
    model: {
      value: _vm.tinymceContent,
      callback: function ($$v) {
        _vm.tinymceContent = $$v;
      },
      expression: "tinymceContent"
    }
  }), _c("div", {
    staticClass: "editor-custom-btn-container"
  }, [_c("editor-image-upload", {
    staticClass: "editor-upload-btn",
    attrs: {
      color: _vm.uploadButtonColor
    },
    on: {
      successCBK: _vm.imageSuccessCBK
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;