"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.BASE_URL = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.dateRange = '';
    this.startDate = '';
    this.endDate = '';
    this.listQuery = {
      page: 1,
      limit: 10
    };
  }
  activated() {
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _order.getDispatchList)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      this.listQuery.orderTimestamp = [this.startDate, this.endDate].join(',');
    } else {
      this.listQuery.orderTimestamp = '';
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  async dateval() {
    this.getList();
  }
  async handleDownload() {
    const orderFilter = Object.assign({}, this.listQuery);
    delete orderFilter.limit;
    delete orderFilter.page;
    this.downloadLoading = true;
    const tHeader = ['Product', 'Variation', 'Quantity'];
    const filterVal = ['product_name', 'pv_name', 'quantity'];
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      var exportData = await (0, _order.getDispatchList)({
        startDate: this.startDate,
        endDate: this.endDate
      });
      const data = (0, _index2.formatJson)(filterVal, exportData.data);
      (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.orderManagement.orders.dispatch'));
      this.downloadLoading = false;
    } else {
      this.$message({
        type: 'info',
        message: 'Please select a date range'
      });
      this.downloadLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrderDispatchList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;