"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    console.log(this.ordersProduct);
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "ordersProduct", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "DealerDetails"
})], default_1);
var _default = exports.default = default_1;