"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'globe': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<path pid="0" style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans" d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23S37.69 2 25 2zm-.813 6.219l2.094.625-2.625 2.094-.687 2.687L22.562 11l-3.03 2.125.874 1.375-.531 1.906-2.406 1.375.937 1.344-.968 3-1.22.094 1.126 1.468-.5 2.094-1.688-2.343-1.375-1.032-1.25 1.938.375 1.468-1.406-.593-1.5-2.094-1.625-.25-2.063-.125 2.094 1.281-.531 1.688-.906.156-2.344-2.219.094 1.5 1.75 2.125.906-.187-.625 3.25.406 4.593-.375.47A20.856 20.856 0 014 25c0-6.195 2.68-11.752 6.938-15.594l2.593-.937 2.094.969 1.563-.47.437.532 1.656-.719 2.407-.25.593.281 1.907-.593zm6.844 1.125l1.594.062 1.594 1.125 1.875-.375 2.843.906.876-.937a21.055 21.055 0 013.812 5.219v.031l-1.906.688.843.906 1.844.031c.39.945.72 1.928.969 2.938l-2.438 1.625L43 24l-2.094-2.281-1.656.437 1.469 3.5-1.813-1.437-1.843-3.532L36 19.157l-.156-2.626-2.032-3.687-1.25-.281-2.718.812L31 12.156l-.531-1.25.562-1.562zm13.094 16.718l1.406 2.344.157.188a20.817 20.817 0 01-2.75 7.281l-.782.5-1.531.656-1.469 1.563-.562 1.187-.688-.718.5-1.594 2.907-4.094.53-1.375-.78-1.031-.344-2.813 1.062-.312.438-1.094 1.906-.688zm-20.656.375l1.812 2.094 1.219-1.687 1.594 2.843.875 1.875-1.344 2.75-1.219.282-2.093-1.969-1.22-.313-2.374 1.157-1.032-.844-.28-3.438 4.062-2.75zm9.687 6.47l.75 1.187-.593.812-.157-2z" overflow="visible" font-family="Bitstream Vera Sans"/>'
  }
});