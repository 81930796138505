"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inventoryInwardSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const inventoryInwardSubMenu = exports.inventoryInwardSubMenu = [{
  path: '/inventory-inwards/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/inventory-inwards/list.vue'))),
  name: 'schemeManagement.inventoryInward.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Read Only'],
    title: 'schemeManagement.inventoryInward.title',
    noCache: true,
    icon: 'auction'
  }
}, {
  path: '/inventory-inwards/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/inventory-inwards/manage.vue'))),
  name: 'schemeManagement.inventoryInward.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'schemeManagement.inventoryInward.edit',
    noCache: true,
    activeMenu: '/inventory-inwards/list',
    hidden: true
  }
}, {
  path: '/inventory-inwards/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/inventory-inwards/manage.vue'))),
  name: 'schemeManagement.inventoryInward.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'schemeManagement.inventoryInward.add',
    noCache: true,
    activeMenu: '/inventory-inwards/list',
    hidden: true
  }
}];