"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAttributesValues = exports.getAttributesValuesById = exports.getAttributesValues = exports.fileTransferHeaders = exports.deleteAttributesValues = exports.defaultAttributeValuesData = exports.createAttributesValues = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultAttributeValuesData = exports.defaultAttributeValuesData = {
  id: 0,
  enabled: true,
  name: '',
  quantity: 1,
  attributeId: '',
  attribute: {
    id: null
  }
};
const getAttributesValues = params => (0, _request.default)({
  url: '/attributes-values',
  method: 'get',
  params
});
exports.getAttributesValues = getAttributesValues;
const getAttributesValuesById = id => (0, _request.default)({
  url: `/attributes-values/${id}`,
  method: 'get'
});
exports.getAttributesValuesById = getAttributesValuesById;
const updateAttributesValues = (id, data) => (0, _request.default)({
  url: `/attributes-values/${id}`,
  method: 'patch',
  data
});
exports.updateAttributesValues = updateAttributesValues;
const deleteAttributesValues = id => (0, _request.default)({
  url: `/attributes-values/${id}`,
  method: 'delete'
});
exports.deleteAttributesValues = deleteAttributesValues;
const createAttributesValues = data => (0, _request.default)({
  url: '/attributes-values/',
  method: 'post',
  data
});
exports.createAttributesValues = createAttributesValues;