"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "ewayUpdateFromData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.ewayUpdateFromData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Select Transporter",
      prop: "transporterId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Transporter"
    },
    model: {
      value: _vm.ewayUpdateFromData.transporterId,
      callback: function ($$v) {
        _vm.$set(_vm.ewayUpdateFromData, "transporterId", $$v);
      },
      expression: "ewayUpdateFromData.transporterId"
    }
  }, _vm._l(_vm.transporterList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.transporter.name + "-" + item.transporter.city.name,
        value: item.transporter.id
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" Update E-way ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;