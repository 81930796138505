"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.salesReportSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const salesReportSubMenu = exports.salesReportSubMenu = [{
  path: '/report/salesReport/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/report/sales-report/list.vue'))),
  name: 'report.title',
  meta: {
    title: 'report.salesReport.title',
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Invoice Manager', 'Read Only', 'Account Manager'],
    noCache: true,
    hidden: false,
    icon: 'documentation'
  }
}];