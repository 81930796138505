"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "variation-attribute-container"
  }, [_vm._l(_vm.attributesAndValues, function (attributesAndValue, index) {
    return _c("div", {
      key: attributesAndValue.id,
      staticClass: "variation-attribute"
    }, [_c("el-select", {
      attrs: {
        placeholder: "Select " + attributesAndValue.name,
        value: attributesAndValue.selectedValueId
      },
      on: {
        change: function ($event) {
          return _vm.handleAttribute($event, attributesAndValue.id, index);
        }
      }
    }, _vm._l(attributesAndValue.attributesValues, function (attributesValue) {
      return _c("el-option", {
        key: attributesValue.id,
        attrs: {
          label: attributesValue.name,
          value: attributesValue.id
        }
      });
    }), 1)], 1);
  }), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-circle-plus"
    },
    on: {
      click: function ($event) {
        return _vm.addVariation();
      }
    }
  }, [_vm._v(" Add ")]), _c("el-button", {
    attrs: {
      type: "danger",
      icon: "el-icon-delete"
    },
    on: {
      click: function ($event) {
        _vm.showDelete = !_vm.showDelete;
      }
    }
  }, [_vm._v(" Delete ")])], 2), _c("br"), _vm.productsVariations.length ? _c("el-collapse", {
    attrs: {
      accordion: ""
    }
  }, _vm._l(_vm.productsVariations, function (variation, index) {
    return _c("el-collapse-item", {
      key: index,
      attrs: {
        name: index
      }
    }, [_c("template", {
      slot: "title"
    }, [_vm.showDelete ? _c("el-button", {
      staticClass: "danger",
      attrs: {
        size: "mini",
        circle: "",
        type: "text",
        icon: "el-icon-delete"
      },
      on: {
        click: function ($event) {
          return _vm.handleDelete($event, index);
        }
      }
    }) : _vm._e(), !variation.edit ? [_vm._v(" " + _vm._s((variation.id ? "#" + variation.id + ": " : "") + variation.name) + " "), _c("el-button", {
      attrs: {
        type: "text",
        icon: "el-icon-edit"
      },
      on: {
        click: function ($event) {
          return _vm.editName($event, index);
        }
      }
    })] : _c("el-input", {
      staticStyle: {
        width: "300px"
      },
      attrs: {
        size: "mini",
        placeholder: "enter name"
      },
      model: {
        value: variation.name,
        callback: function ($$v) {
          _vm.$set(variation, "name", $$v);
        },
        expression: "variation.name"
      }
    }, [_c("el-button", {
      attrs: {
        slot: "append",
        icon: "el-icon-check"
      },
      on: {
        click: function ($event) {
          return _vm.saveName($event, index);
        }
      },
      slot: "append"
    })], 1)], 2), _c("div", {
      staticClass: "variation-container"
    }, [_c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "grid-content bg-purple"
    }, [_c("div", {
      staticClass: "sub-title"
    }, [_vm._v(" Enabled ")]), _c("el-switch", {
      attrs: {
        size: "mini"
      },
      model: {
        value: variation.enabled,
        callback: function ($$v) {
          _vm.$set(variation, "enabled", $$v);
        },
        expression: "variation.enabled"
      }
    })], 1)]), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title"
    }, [_vm._v(" Is New ")]), _c("el-switch", {
      attrs: {
        size: "mini"
      },
      model: {
        value: variation.isNew,
        callback: function ($$v) {
          _vm.$set(variation, "isNew", $$v);
        },
        expression: "variation.isNew"
      }
    })], 1)], 1), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "grid-content bg-purple"
    }, [_c("div", {
      staticClass: "sub-title"
    }, [_vm._v(" MRP Reduced ")]), _c("el-switch", {
      attrs: {
        size: "mini"
      },
      model: {
        value: variation.isMrpReduced,
        callback: function ($$v) {
          _vm.$set(variation, "isMrpReduced", $$v);
        },
        expression: "variation.isMrpReduced"
      }
    })], 1)]), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title"
    }, [_vm._v(" MRP Further Reduced ")]), _c("el-switch", {
      attrs: {
        size: "mini"
      },
      model: {
        value: variation.isMrpFurtherReduced,
        callback: function ($$v) {
          _vm.$set(variation, "isMrpFurtherReduced", $$v);
        },
        expression: "variation.isMrpFurtherReduced"
      }
    })], 1)], 1), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title"
    }, [_vm._v(" MRP ")]), _c("el-input-number", {
      attrs: {
        name: "mrp",
        size: "mini",
        min: 0,
        precision: 2
      },
      model: {
        value: variation.mrp,
        callback: function ($$v) {
          _vm.$set(variation, "mrp", $$v);
        },
        expression: "variation.mrp"
      }
    })], 1), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title"
    }, [_vm._v(" Discontinued ")]), _c("el-switch", {
      attrs: {
        size: "mini"
      },
      model: {
        value: variation.isDiscontinued,
        callback: function ($$v) {
          _vm.$set(variation, "isDiscontinued", $$v);
        },
        expression: "variation.isDiscontinued"
      }
    })], 1)], 1), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title"
    }, [_vm._v(" Short Description ")]), _c("el-input", {
      staticClass: "half-width",
      attrs: {
        type: "textarea",
        name: "shortDescription",
        size: "mini"
      },
      model: {
        value: variation.shortDescription,
        callback: function ($$v) {
          _vm.$set(variation, "shortDescription", $$v);
        },
        expression: "variation.shortDescription"
      }
    }), _c("help-text", {
      attrs: {
        content: "add one line description for product"
      }
    })], 1)], 1)], 1)], 2);
  }), 1) : _vm._e(), _c("br"), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;