"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-button", {
    staticClass: "enabled-button",
    attrs: {
      mini: "",
      type: "text",
      disabled: _vm.disabled
    },
    on: {
      click: _vm.toggleEnable
    }
  }, [_c("el-badge", {
    staticClass: "item enable-dot",
    attrs: {
      "is-dot": "",
      type: _vm.value ? "success" : "danger"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;