"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.list && _vm.list.length ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "label",
    attrs: {
      span: 12
    }
  }, [_vm._v(" Grand Total: "), _c("inr", {
    attrs: {
      number: _vm.list[0].order.grandTotal
    }
  })], 1), _c("el-col", {
    staticClass: "label",
    attrs: {
      span: 12,
      align: "right"
    }
  }, [_vm._v(" Amount Received: "), _c("inr", {
    attrs: {
      number: _vm.list[0].order.amountReceived
    }
  })], 1)], 1) : _vm._e(), _vm.list ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "180",
      align: "center",
      prop: "transaction.referenceNumber",
      label: "Transaction Reference"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "160",
      align: "right",
      prop: "amountReceived",
      label: "Amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.amountReceived
          }
        })];
      }
    }], null, false, 2068114268)
  }), _c("el-table-column", {
    attrs: {
      label: "Transaction Date",
      width: "140",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.transaction.createdTimestamp, "DD-MM-YYYY")) + " ")];
      }
    }], null, false, 3408053084)
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;