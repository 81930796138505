"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    staticStyle: {
      width: "280px !important"
    },
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY"
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), !_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.agentListLoading,
      "loading-text": "fetching agents..",
      "remote-method": _vm.getAgentList,
      placeholder: _vm.$t("order.agent")
    },
    model: {
      value: _vm.listQuery.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "agentId", $$v);
      },
      expression: "listQuery.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    }, [_vm._v(" " + _vm._s(agent.name) + " - " + _vm._s(agent.code) + " ")]);
  }), 1) : _vm._e(), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "info",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _vm.loggedInAdmin.adminsRoleId === 1 || _vm.isAccountManager ? _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/ledgers/add/"
    }
  }, [!_vm.isReadOnly ? _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]) : _vm._e()], 1) : _vm._e(), !_vm.isReadOnly ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")]) : _vm._e()], 1), _c("el-row", {
    staticStyle: {
      "margin-right": "110px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "align-right",
    attrs: {
      span: 24
    }
  }, [_vm._v(" Opening Balance: "), _c("inr", {
    attrs: {
      number: _vm.openingBalance
    }
  })], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "160",
      label: "Date",
      prop: "createdTimestamp"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MM-YYYY")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "240",
      label: "Invoice"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row, _scope$row2, _scope$row$transactio;
        return [scope.row.transactionId ? _c("span", [_vm._v(" " + _vm._s(scope.row.transaction.paymentType) + " "), _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "Description: " + ((_scope$row = scope.row) === null || _scope$row === void 0 || (_scope$row = _scope$row.transaction) === null || _scope$row === void 0 ? void 0 : _scope$row.comments) || ((_scope$row2 = scope.row) === null || _scope$row2 === void 0 ? void 0 : _scope$row2.comments) + " \n" + "Transaction Reference: " + ((_scope$row$transactio = scope.row.transaction) !== null && _scope$row$transactio !== void 0 && _scope$row$transactio.referenceNumber ? scope.row.transaction.referenceNumber : ""),
            placement: "top-start"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(`Reference No. :${scope.row.transaction.referenceNumber}`) + " ")])], 1)], 1) : _c("span", [scope.row.ordersDispatch ? _c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank",
            disabled: scope.row.fromTally
          },
          on: {
            click: function ($event) {
              return _vm.downloadInvoice(scope.row.ordersDispatchId);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.ordersDispatch.invoiceNumber) + " "), scope.row.paymentType === "Cash Discount" ? _c("span", [_vm._v(" - " + _vm._s(scope.row.paymentType) + " ")]) : _vm._e()]) : scope.row.paymentType === "Credit Note" ? _c("span", [_vm._v(" " + _vm._s(scope.row.paymentType) + " "), _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: `Description :${scope.row.comments}`
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(`Reference: ${scope.row.transactionReference}`) + " ")])], 1)], 1) : _c("span", [_vm._v(_vm._s(scope.row.paymentType) + " "), _c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: `Description :${scope.row.comments}`
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(`Reference: ${scope.row.transactionReference}`) + " ")])], 1)], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "140",
      label: "Debit",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.action === "debit" ? _c("span", [_c("inr", {
          attrs: {
            number: scope.row.amount
          }
        })], 1) : _c("span", [_vm._v("0")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "140",
      label: "Credit",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.action === "credit" ? _c("span", [_c("inr", {
          attrs: {
            number: scope.row.amount
          }
        })], 1) : _c("span", [_vm._v("0")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "140",
      label: "Balance",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.balance
          }
        })];
      }
    }])
  }), !_vm.isAgent ? _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentType === "Journal Note" || _vm.isAccountManager ? _c("router-link", {
          attrs: {
            to: "/ledgers/edit/" + scope.row.id
          }
        }, [!_vm.isReadOnly || _vm.isAccountManager ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          }
        }) : _vm._e()], 1) : _vm._e()];
      }
    }], null, false, 52492975)
  }) : _vm._e()], 1), _c("el-row", {
    staticStyle: {
      "margin-right": "110px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "align-right",
    attrs: {
      span: 24
    }
  }, [_vm._v(" Closing Balance: "), _c("inr", {
    attrs: {
      number: _vm.closingBalance
    }
  })], 1)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit,
      "page-sizes": [50, 100, 200, 500, 1000]
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;