"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSchemesDiscount = exports.manageSchemesDiscount = exports.getSchemesDiscounts = exports.getSchemesDiscountById = exports.deleteSchemesDiscount = exports.defaultSchemesDiscountData = exports.createSchemesDiscount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSchemesDiscountData = exports.defaultSchemesDiscountData = {
  id: 0,
  enabled: true,
  nettDiscount: 0,
  percentDiscount: 0,
  schemeId: null,
  scheme: {
    id: null
  },
  stateId: null,
  state: {
    id: null
  },
  agentId: null,
  agent: {
    id: null
  },
  rangeId: null,
  range: {
    id: null
  },
  categoryId: null,
  category: {
    id: null
  },
  subCategoryId: null,
  subCategory: {
    id: null
  },
  dealersRoleId: null,
  dealersRole: {
    id: null
  },
  productId: null,
  product: {
    id: null
  },
  productsVariationId: null,
  productsVariation: {
    id: null
  },
  schemesCategoryId: null,
  schemesCategory: {
    id: null
  }
};
const getSchemesDiscounts = params => (0, _request.default)({
  url: '/schemes-discounts',
  method: 'get',
  params
});
exports.getSchemesDiscounts = getSchemesDiscounts;
const getSchemesDiscountById = id => (0, _request.default)({
  url: `/schemes-discounts/${id}`,
  method: 'get'
});
exports.getSchemesDiscountById = getSchemesDiscountById;
const updateSchemesDiscount = (id, data) => (0, _request.default)({
  url: `/schemes-discounts/${id}`,
  method: 'patch',
  data
});
exports.updateSchemesDiscount = updateSchemesDiscount;
const deleteSchemesDiscount = id => (0, _request.default)({
  url: `/schemes-discounts/${id}`,
  method: 'delete'
});
exports.deleteSchemesDiscount = deleteSchemesDiscount;
const createSchemesDiscount = data => (0, _request.default)({
  url: '/schemes-discounts',
  method: 'post',
  data
});
exports.createSchemesDiscount = createSchemesDiscount;
const manageSchemesDiscount = data => (0, _request.default)({
  url: '/schemes-discounts/manage',
  method: 'post',
  data
});
exports.manageSchemesDiscount = manageSchemesDiscount;