import "core-js/modules/es.array.reduce.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { downloadPendingOrdersProducts, getPendingOrdersProducts } from "@/api/orders-dispatches";
import Pagination from "@/components/Pagination/index.vue";
import { getQuery, formatJson } from "@/utils/index";
import { exportJson2Excel } from "@/utils/excel";
import { getDealers } from "@/api/dealers";
import { searchProducts } from "@/api/products";
import { getRanges } from "@/api/ranges";
import { AdminModule } from "@/store/modules/admin";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.dealerList = [];
    this.dealerListLoading = false;
    this.productList = [];
    this.productListLoading = false;
    this.rangeList = [];
    this.dateRange = null;
    this.grandTotalCartons = null;
    this.grandTotal = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      productId: undefined,
      rangeId: undefined,
      startDate: undefined,
      endDate: undefined
    };
  }
  activated() {
    if (AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await getPendingOrdersProducts(this.listQuery);
      // get ranges
      this.rangeList = await getRanges(getQuery({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
      //add cartons to the data
      let updatedData = data.data.map(item => {
        // Convert 'total' to a number and perform the division
        const cartons = (Number(item.total) / item.masterCarton).toFixed(2);
        // Return a new object with the 'cartons' field added
        return {
          ...item,
          cartons: cartons
        };
      });
      this.list = updatedData;
      this.total = data.total;
      this.grandTotalCartons = data.grandTotal.grandTotalCartons;
      this.grandTotal = data.grandTotal.grandTotal;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      if (query && query.length > 2) {
        this.dealerListLoading = true;
        this.dealerList = await getDealers(getQuery({
          name: query,
          filter: {
            name: "$contL"
          }
        }));
      }
      this.dealerListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchProductList(query) {
    try {
      if (query.length > 2) {
        this.productListLoading = true;
        this.productList = await searchProducts({
          keyword: query
        });
      }
      this.productListLoading = false;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  handleFilter() {
    if (this.dateRange) {
      this.listQuery.startDate = this.dateRange.start;
      this.listQuery.endDate = this.dateRange.end;
    } else {
      this.listQuery.startDate = null;
      this.listQuery.endDate = null;
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["id", "fullname", "email", "contact", "role"];
    const filterVal = ["id", "fullName"];
    const data = formatJson(filterVal, this.list);
    exportJson2Excel(tHeader, data, this.$tc("route.userManagement.users.title"));
    this.downloadLoading = false;
  }
  async downloadData() {
    this.listLoading = true;
    try {
      const params = {};
      if (this.listQuery.dealerId) {
        params.dealerId = this.listQuery.dealerId;
      }
      if (this.listQuery.productId) {
        params.productId = this.listQuery.productId;
      }
      if (this.listQuery.rangeId) {
        params.rangeId = this.listQuery.rangeId;
      }
      if (this.dateRange) {
        params.startDate = this.dateRange.start;
        params.endDate = this.dateRange.end;
      }
      const data = await downloadPendingOrdersProducts(getQuery(params));
      window.open(data.url, "_blank");
      // if (Object.keys(params).length) {
      //   const data: any = await downloadPendingOrdersProducts(getQuery(params))
      //   window.open(data.url, '_blank')
      // } else {
      //   this.$message({
      //     message: 'Please select atleast one filter',
      //     type: 'warning'
      //   })
      // }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  getSummaries(param) {
    const {
      columns,
      data
    } = param;
    const sums = [];
    const sumsCarton = [];
    const grandTotal = [];
    const totalCarton = [];
    columns.forEach((column, index) => {
      // if (index === 0) {
      //   sums[index] = "Total:  ";
      //   return;
      // }
      if (index === 2) {
        const values = data.map(item => Number(item[column.property]));
        // loop over & null check
        if (!values.every(value => isNaN(value))) {
          // sum them using reduce
          sums[index] = values.reduce((prev, curr) => {
            // value conversion (do check with Sandesh if toFixed something else required for decimal precision)
            const value = Number(curr);
            // null check
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0); // initial value
        } // loop if ends
      }
      if (index === 3) {
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sumsCarton[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      }
    });
    if (sums && sums.length) {
      sumsCarton[3] = `${sumsCarton[3].toFixed(2)}/${this.grandTotalCartons.toFixed(2)}`;
      sums[2] = `${sums[2].toFixed(2)}/${this.grandTotal.toFixed(2)}`;
    }
    return [,, sums, sumsCarton];
  }
};
default_1 = __decorate([Component({
  name: "PendingOrdersProductList",
  components: {
    Pagination
  }
})], default_1);
export default default_1;