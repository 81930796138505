"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.data ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "eltable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.data,
      border: "",
      "expand-row-keys": _vm.expandedRowKeys,
      "row-key": "id"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "expand"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("dispatch-list", {
          attrs: {
            "orders-product-id": scope.row.id
          }
        })];
      }
    }], null, false, 1689628032)
  }), _c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "ID",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "360",
      prop: "ordersProduct",
      label: "Product"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.product.name) + " - " + _vm._s(scope.row.productsVariation.name) + " ")];
      }
    }], null, false, 3538805055)
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "right",
      prop: "quantity",
      label: "Qty Ordered"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "130",
      align: "right",
      prop: "quantityDispatched",
      label: "Qty Dispatched"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.quantityDispatched ? _c("el-button", {
          attrs: {
            type: "primary",
            round: "",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.toggleExpand(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.quantityDispatched) + " ")]) : _c("span", [_vm._v("0")])];
      }
    }], null, false, 2620692538)
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;