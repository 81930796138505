import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import Inr from "@/components/Inr/index.vue";
let default_1 = class default_1 extends Vue {
  activated() {}
};
__decorate([Prop({
  default: {}
})], default_1.prototype, "data", void 0);
default_1 = __decorate([Component({
  name: "OrderProductTab",
  components: {
    Inr
  }
})], default_1);
export default default_1;