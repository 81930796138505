"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePoliciesDiscount = exports.managePoliciesDiscount = exports.getPoliciesDiscounts = exports.getPoliciesDiscountById = exports.downloadExcel = exports.deletePoliciesDiscount = exports.defaultPoliciesDiscountData = exports.createPoliciesDiscount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultPoliciesDiscountData = exports.defaultPoliciesDiscountData = {
  id: 0,
  enabled: true,
  discountType: 'percent',
  nettDiscount: 0,
  percentDiscount: 0,
  policyId: null,
  policy: {
    id: null
  },
  rangeId: null,
  range: {
    id: null
  },
  categoryId: null,
  category: {
    id: null
  },
  subCategoryId: null,
  subCategory: {
    id: null
  },
  productId: null,
  product: {
    id: null
  },
  productsVariationId: null,
  productsVariation: {
    id: null
  },
  dealersRoleId: null,
  dealersRole: {
    id: null
  }
};
const getPoliciesDiscounts = params => (0, _request.default)({
  url: '/policies-discounts',
  method: 'get',
  params
});
exports.getPoliciesDiscounts = getPoliciesDiscounts;
const getPoliciesDiscountById = id => (0, _request.default)({
  url: `/policies-discounts/${id}`,
  method: 'get'
});
exports.getPoliciesDiscountById = getPoliciesDiscountById;
const updatePoliciesDiscount = (id, data) => (0, _request.default)({
  url: `/policies-discounts/${id}`,
  method: 'patch',
  data
});
exports.updatePoliciesDiscount = updatePoliciesDiscount;
const deletePoliciesDiscount = id => (0, _request.default)({
  url: `/policies-discounts/${id}`,
  method: 'delete'
});
exports.deletePoliciesDiscount = deletePoliciesDiscount;
const createPoliciesDiscount = data => (0, _request.default)({
  url: '/policies-discounts',
  method: 'post',
  data
});
exports.createPoliciesDiscount = createPoliciesDiscount;
const managePoliciesDiscount = data => (0, _request.default)({
  url: '/policies-discounts/manage',
  method: 'post',
  data
});
exports.managePoliciesDiscount = managePoliciesDiscount;
const downloadExcel = data => (0, _request.default)({
  url: '/policies-discounts/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;