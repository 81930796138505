"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _products = require("@/api/products");
var _app = require("@/store/modules/app");
var _vuexModuleDecorators = require("vuex-module-decorators");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {}
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      await (0, _products.updateProductVariations)(this.variations);
      this.$notify({
        title: 'Success',
        message: 'Variation updated successfully',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "variations", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'VariationPrice'
})], default_1);
var _default = exports.default = default_1;