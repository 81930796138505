import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-module-decorators";
import { updateProduct, createProduct } from "@/api/products";
import { getTaxes } from "@/api/master/taxes";
import { getRanges } from "@/api/ranges";
import { getSchemesCategories } from "@/api/schemes-categories";
import { getQuery } from "@/utils";
import HelpText from "@/components/HelpText/index.vue";
let default_1 = class default_1 extends Vue {
  constructor() {
    super(...arguments);
    this.ranges = [];
    this.schemesCategories = [];
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: "This field is required",
        trigger: "blur"
      }, {
        min: 3,
        message: "Minimum length should be 3",
        trigger: "blur"
      }],
      hsnCode: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      rangeId: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      piecesPerBox: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      masterCarton: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      }
    };
    this.savedSimpleVariation = [];
    this.savedVariableVariations = [];
    this.taxes = [];
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (this.formData.masterCarton % this.formData.piecesPerBox !== 0) {
        this.$notify({
          title: "Warning",
          message: "Pieces per box does not satisfy master carton quantity",
          type: "warning",
          duration: 2000
        });
        valid = false;
      }
      if (valid) {
        this.saveForm();
      } else {
        // this.$message.error("Failed to saved!");
        return false;
      }
    });
  }
  activated() {
    this.getSchemesCategories();
    this.getRangeList();
    this.getTaxes();
  }
  toggleProductType() {
    // toggle single product
    if (this.formData.productType === "simple") {
      this.formData.productsVariation = [];
      this.formData.productsVariation.push(Object.assign({
        enabled: true,
        allowBackOrder: false,
        manageStock: this.formData.manageStock,
        productId: this.formData.id || null,
        productsVariationsAttribute: [{
          productId: this.formData.id || null,
          attributeId: 1,
          attributesValueId: 1
        }],
        unitPrice: 0,
        salePrice: 0,
        sku: "",
        stockQuantity: 0,
        stockStatus: "In Stock"
      }, ...this.savedSimpleVariation // override with saved one
      ));
    } else {
      this.formData.productsVariation = this.savedVariableVariations;
    }
  }
  mounted() {
    if (this.formData.productType === "simple") {
      this.savedSimpleVariation = this.formData.productsVariation;
    } else {
      this.savedVariableVariations = this.formData.productsVariation;
    }
    this.toggleProductType();
  }
  emitFormData() {
    this.toggleProductType();
    this.$emit("updated", this.formData);
  }
  async getTaxes() {
    try {
      this.taxes = await getTaxes(getQuery({
        enabled: true,
        sort: "rate,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getSchemesCategories() {
    try {
      const data = await getSchemesCategories(getQuery({
        enabled: true,
        sort: {
          schemeId: "DESC",
          name: "ASC"
        },
        filter: {
          enabled: "$eq"
        }
      }));
      // group by schemeId
      this.schemesCategories = data.reduce((acc, item) => {
        if (!acc.find(x => x.schemeId === item.schemeId)) {
          acc.push({
            schemeId: item.schemeId,
            name: item.scheme.name,
            id: item.schemeId,
            schemesCategories: data.filter(x => x.schemeId === item.schemeId)
          });
        }
        return acc;
      }, []);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getRangeList() {
    try {
      const data = await getRanges(getQuery({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
      this.ranges = data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async saveForm() {
    this.loading = true;
    try {
      if (this.formData.productType === "simple") {
        this.formData.productsVariation[0].name = this.formData.name;
      }
      if (!this.formData.isTaxable) {
        this.formData.taxId = null;
        delete this.formData.tax;
      }
      // add/ update schemesCategory
      if (this.formData.schemesCategoryId) {
        this.formData.schemesCategory = {
          id: this.formData.schemesCategoryId
        };
      } else {
        this.formData.schemesCategory = null;
      }
      // add/ update range
      this.formData.range = {
        id: this.formData.rangeId
      };
      // update boxesPerCarton
      this.formData.boxesPerCarton = this.formData.masterCarton / this.formData.piecesPerBox;
      let response = {};
      if (this.isEdit) {
        response = await updateProduct(this.formData.id, this.formData);
        this.$emit("reload", true);
      } else {
        delete this.formData.tax;
        response = await createProduct(this.formData);
        this.formData = response;
        this.isEdit = true;
        this.emitFormData();
        // to make other tabs available
        this.$emit("edited", this.isEdit);
      }
      this.$notify({
        title: "Success",
        message: "Product saved",
        type: "success",
        duration: 2000
      });
    } catch (err) {
      this.$notify.error(err.toString());
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
};
__decorate([Prop({
  default: {}
})], default_1.prototype, "formData", void 0);
__decorate([Prop({
  default: false
})], default_1.prototype, "isEdit", void 0);
__decorate([Action], default_1.prototype, "saveForm", null);
default_1 = __decorate([Component({
  name: "ProductGeneralTab",
  components: {
    HelpText
  }
})], default_1);
export default default_1;