"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBranch = exports.getBranches = exports.getBranchById = exports.deleteBranch = exports.defaultBranchData = exports.createBranch = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultBranchData = exports.defaultBranchData = {
  id: 0,
  enabled: true,
  name: '',
  code: '',
  gstNumber: null,
  panNumber: null,
  line1: null,
  line2: null,
  pincodeId: null,
  isEditable: true,
  pincode: {
    id: null
  },
  cityId: undefined,
  city: {
    id: undefined
  },
  stateId: undefined,
  state: {
    id: undefined
  },
  countryId: undefined,
  country: {
    id: undefined
  }
};
const getBranches = params => (0, _request.default)({
  url: '/branches',
  method: 'get',
  params
});
exports.getBranches = getBranches;
const getBranchById = id => (0, _request.default)({
  url: `/branches/${id}`,
  method: 'get'
});
exports.getBranchById = getBranchById;
const updateBranch = (id, data) => (0, _request.default)({
  url: `/branches/${id}`,
  method: 'patch',
  data
});
exports.updateBranch = updateBranch;
const deleteBranch = id => (0, _request.default)({
  url: `/branches/${id}`,
  method: 'delete'
});
exports.deleteBranch = deleteBranch;
const createBranch = data => (0, _request.default)({
  url: '/branches',
  method: 'post',
  data
});
exports.createBranch = createBranch;