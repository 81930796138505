"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dealersUserSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const dealersUserSubMenu = exports.dealersUserSubMenu = [{
  path: '/dealers-users/list/:dealerId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-users/list.vue'))),
  name: 'dealerManagement.user.title',
  meta: {
    title: 'dealerManagement.user.title',
    icon: 'user',
    noCache: true,
    hidden: true
  }
}, {
  path: '/dealers-users/add/:dealerId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-users/manage.vue'))),
  name: 'dealerManagement.user.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'dealerManagement.user.add',
    noCache: true,
    activeMenu: '/dealers-users/list/:dealerId(\\d+)',
    hidden: true
  }
}, {
  path: '/dealers-users/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-users/manage.vue'))),
  name: 'dealerManagement.user.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'dealerManagement.user.edit',
    noCache: true,
    activeMenu: '/dealers-users/list/:dealerId(\\d+)',
    hidden: true
  }
}];