"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _dealers = require("@/api/dealers");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _agents = require("@/api/agents");
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.isAgent = false;
    this.agentList = [];
    this.stateList = [];
    this.cityList = [];
    this.dealerList = [];
    this.dealerListLoading = false;
    this.statusColorMap = {
      Pending: 'info',
      Approved: 'success',
      Blocked: 'danger',
      Hold: 'warning'
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      id: undefined,
      enabled: true,
      code: undefined,
      status: undefined,
      agentId: undefined,
      'address.stateId': undefined,
      'address.cityId': undefined,
      sort: 'id,ASC',
      filter: {
        id: '$eq',
        enabled: '$eq',
        code: '$startsL',
        status: '$eq',
        agentId: '$eq',
        'address.stateId': '$eq',
        'address.cityId': '$eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  activated() {
    if (_admin.AdminModule.roles[0] === 'Read Only') {
      this.isReadOnly = true;
    }
    // check if logged in user is Agent/Admin
    if (_admin.AdminModule.userType === 'Agent') {
      this.isAgent = true;
      this.listQuery.agentId = _admin.AdminModule.agentId;
    }
    this.getList();
    // fetch agents
    this.fetchAgents();
    // get state list
    this.fetchStates();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _dealers.getDealers)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    if (this.listQuery['address.stateId']) {
      // fetch city list
      this.fetchCitiesByState(this.listQuery['address.stateId']);
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  async fetchAgents() {
    this.agentList = await (0, _agents.getAgents)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  async fetchStates() {
    this.stateList = await (0, _states.getStates)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  async fetchCitiesByState(stateId = 0) {
    this.cityList = await (0, _cities.getCities)((0, _index2.getQuery)({
      enabled: true,
      stateId,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq',
        stateId: '$eq'
      }
    }));
  }
  async handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['ID', 'Name', 'Code'];
    const filterVal = ['id', 'name', 'code'];
    const agentFilter = Object.assign({}, this.listQuery);
    delete agentFilter.limit;
    delete agentFilter.page;
    const dealers = await (0, _dealers.getDealers)((0, _index2.getQuery)(agentFilter));
    const data = (0, _index2.formatJson)(filterVal, dealers);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.dealerManagement.dealer.title'));
    this.downloadLoading = false;
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? 'dealerId' : 'name'
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      const blob = await (0, _dealers.downloadExcel)(queryWithoutPagination);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'dealers.xlsx');
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel:', error);
      this.$message.error('Error downloading Excel');
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DealerList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;