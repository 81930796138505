"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateDealersAddresses = exports.getDealersAddressesById = exports.getDealersAddresses = exports.deleteDealersAddresses = exports.defaultDealersAddressData = exports.createDealersAddresses = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultDealersAddressData = exports.defaultDealersAddressData = {
  id: 0,
  enabled: true,
  isPrimary: false,
  name: '',
  gstNumber: undefined,
  panNumber: undefined,
  line1: '',
  line2: '',
  cityName: null,
  countryId: null,
  country: {
    id: null
  },
  stateId: null,
  state: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  },
  pincodeId: null,
  pincode: {
    id: null
  },
  dealerId: 0,
  dealer: {
    id: null
  }
};
const getDealersAddresses = params => (0, _request.default)({
  url: '/dealers-addresses',
  method: 'get',
  params
});
exports.getDealersAddresses = getDealersAddresses;
const getDealersAddressesById = id => (0, _request.default)({
  url: `/dealers-addresses/${id}`,
  method: 'get'
});
exports.getDealersAddressesById = getDealersAddressesById;
const updateDealersAddresses = (id, data) => (0, _request.default)({
  url: `/dealers-addresses/${id}`,
  method: 'patch',
  data
});
exports.updateDealersAddresses = updateDealersAddresses;
const deleteDealersAddresses = id => (0, _request.default)({
  url: `/dealers-addresses/${id}`,
  method: 'delete'
});
exports.deleteDealersAddresses = deleteDealersAddresses;
const createDealersAddresses = data => (0, _request.default)({
  url: '/dealers-addresses/',
  method: 'post',
  data
});
exports.createDealersAddresses = createDealersAddresses;