"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.totalPendingOrders = exports.totalOutstanding = exports.totalFySales = exports.totalFyPayment = exports.totalCurrentRecievable = exports.totalCollection = exports.topSellingProducts = exports.topDealers = exports.getPreviousMonthSales = exports.getPreviousMonthPayments = exports.getOverdueByDays = exports.getDailyOrderValue = exports.getCurrentMonthSales = exports.getCurrentMonthPayments = exports.getCurrentMonthOrderValue = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const topSellingProducts = data => (0, _request.default)({
  url: '/orders-products/top-products',
  method: 'post',
  data
});
exports.topSellingProducts = topSellingProducts;
const topDealers = data => (0, _request.default)({
  url: '/orders/top-dealers',
  method: 'post',
  data
});
exports.topDealers = topDealers;
const totalOutstanding = data => (0, _request.default)({
  url: '/orders-dispatches/outstanding',
  method: 'post',
  data
});
exports.totalOutstanding = totalOutstanding;
const totalCollection = data => (0, _request.default)({
  url: '/orders-dispatches/collection',
  method: 'post',
  data
});
exports.totalCollection = totalCollection;
const totalFySales = params => (0, _request.default)({
  url: '/orders-dispatches/fy-sales-value',
  method: 'get',
  params
});
exports.totalFySales = totalFySales;
const totalFyPayment = params => (0, _request.default)({
  url: 'ledgers/fy-payments',
  method: 'get',
  params
});
exports.totalFyPayment = totalFyPayment;
const totalCurrentRecievable = params => (0, _request.default)({
  url: 'ledgers/current-recievable',
  method: 'get',
  params
});
exports.totalCurrentRecievable = totalCurrentRecievable;
const totalPendingOrders = params => (0, _request.default)({
  url: 'orders/total-order-pending',
  method: 'get',
  params
});
exports.totalPendingOrders = totalPendingOrders;
const getCurrentMonthSales = params => (0, _request.default)({
  url: '/orders-dispatches/current-month-sales',
  method: 'get',
  params
});
exports.getCurrentMonthSales = getCurrentMonthSales;
const getCurrentMonthPayments = params => (0, _request.default)({
  url: '/ledgers/current-month-payment',
  method: 'get',
  params
});
exports.getCurrentMonthPayments = getCurrentMonthPayments;
const getPreviousMonthSales = params => (0, _request.default)({
  url: '/orders-dispatches/previous-month-sales',
  method: 'get',
  params
});
exports.getPreviousMonthSales = getPreviousMonthSales;
const getPreviousMonthPayments = params => (0, _request.default)({
  url: '/ledgers/previous-month-payment',
  method: 'get',
  params
});
exports.getPreviousMonthPayments = getPreviousMonthPayments;
const getDailyOrderValue = params => (0, _request.default)({
  url: '/orders/daily-order-value',
  method: 'get',
  params
});
exports.getDailyOrderValue = getDailyOrderValue;
const getCurrentMonthOrderValue = params => (0, _request.default)({
  url: '/orders/current-month-order-value',
  method: 'get',
  params
});
exports.getCurrentMonthOrderValue = getCurrentMonthOrderValue;
const getOverdueByDays = data => (0, _request.default)({
  url: '/orders-dispatches/overdue-by-days',
  method: 'post',
  data
});
exports.getOverdueByDays = getOverdueByDays;