"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dealersRolesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const dealersRolesSubMenu = exports.dealersRolesSubMenu = [{
  path: '/dealers-roles/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-roles/list.vue'))),
  name: 'dealerManagement.dealersRoles.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Read Only'],
    title: 'dealerManagement.dealersRoles.title',
    icon: 'user',
    noCache: true
  }
}, {
  path: '/dealers-roles/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-roles/manage.vue'))),
  name: 'dealerManagement.dealersRoles.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'dealerManagement.dealersRoles.add',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}, {
  path: '/dealers-roles/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-roles/manage.vue'))),
  name: 'dealerManagement.dealersRoles.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'dealerManagement.dealersRoles.edit',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}];