"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Transporter Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      name: "name",
      required: "",
      placeholder: "Transporter Name"
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mobile Number",
      prop: "mobileNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "mobileNumber",
      required: "",
      placeholder: "Enter mobile number"
    },
    model: {
      value: _vm.formData.mobileNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "mobileNumber", $$v);
      },
      expression: "formData.mobileNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "GST Number",
      prop: "gstNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "gstNumber",
      required: "",
      placeholder: "Enter GST"
    },
    model: {
      value: _vm.formData.gstNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "gstNumber", $$v);
      },
      expression: "formData.gstNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "freightPerCarton",
      prop: "freightPerCarton"
    }
  }, [_c("el-input-number", {
    attrs: {
      precision: 2,
      step: 1,
      min: 0
    },
    model: {
      value: _vm.formData.freightPerCarton,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "freightPerCarton", $$v);
      },
      expression: "formData.freightPerCarton"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Vehicle Number",
      prop: "vehicleNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "vehicleNumber",
      required: "",
      placeholder: "Enter Vehicle Number"
    },
    model: {
      value: _vm.formData.vehicleNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "vehicleNumber", $$v);
      },
      expression: "formData.vehicleNumber"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Booking City",
      prop: "cityId"
    }
  }, [_c("el-select", {
    staticStyle: {
      "margin-bottom": "20"
    },
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.getCityList,
      name: "City",
      placeholder: "Enter City Name"
    },
    model: {
      value: _vm.formData.cityId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "cityId", $$v);
      },
      expression: "formData.cityId"
    }
  }, _vm._l(_vm.cityList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.dialogFormVisible = true;
      }
    }
  }, [_vm._v(" Add City ")]), _c("el-dialog", {
    attrs: {
      title: "Add City",
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.form
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      label: "States",
      "label-width": _vm.formLabelWidth,
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "select a state",
      clearable: ""
    },
    model: {
      value: _vm.popOverData.stateId,
      callback: function ($$v) {
        _vm.$set(_vm.popOverData, "stateId", $$v);
      },
      expression: "popOverData.stateId"
    }
  }, _vm._l(_vm.list, function (state) {
    return _c("el-option", {
      key: state.id,
      attrs: {
        label: state.name,
        value: state.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "City",
      "label-width": _vm.formLabelWidth,
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.popOverData.name,
      callback: function ($$v) {
        _vm.$set(_vm.popOverData, "name", $$v);
      },
      expression: "popOverData.name"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogFormVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitPopOver
    }
  }, [_vm._v("Save")])], 1)], 1)], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;