"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _policiesDiscounts = require("@/api/policies-discounts");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _ranges = require("@/api/ranges");
var _dealersRoles = require("@/api/dealers-roles");
var _products = require("@/api/products");
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.policyId = 0;
    this.rangeList = [];
    this.roleList = [];
    this.productList = [];
    this.productIdRowsCount = {};
    this.listQuery = {
      page: 1,
      limit: 10,
      enabled: true,
      policyId: this.policyId,
      dealersRoleId: undefined,
      rangeId: undefined,
      productId: undefined,
      sort: ['productId,ASC', 'productsVariation.name,ASC'],
      filter: {
        enabled: '$eq',
        policyId: '$eq',
        dealersRoleId: '$eq',
        rangeId: '$eq',
        productId: '$eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  activated() {
    if (_admin.AdminModule.roles[0] === 'Read Only') {
      this.isReadOnly = true;
    }
    this.policyId = parseInt(this.$route.params && this.$route.params.policyId);
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    this.getList();
    this.fetchRanges();
    this.fetchDealersRoles();
  }
  async getList() {
    try {
      this.listLoading = true;
      this.listQuery.policyId = this.policyId;
      if (this.listQuery.dealersRoleId) {
        const data = await (0, _policiesDiscounts.getPoliciesDiscounts)((0, _index2.getQuery)(this.listQuery));
        /* find out product id rows count & assign to find product id row */
        this.productIdRowsCount = {};
        data.data.forEach(item => {
          if (this.productIdRowsCount[item.productId]) {
            this.productIdRowsCount[item.productId] += 1;
          } else {
            this.productIdRowsCount[item.productId] = 1;
          }
        });
        this.list = data.data;
        this.total = data.total;
      } else {
        this.list = [];
        this.total = 0;
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  async fetchRanges() {
    this.rangeList = await (0, _ranges.getRanges)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchDealersRoles() {
    this.roleList = await (0, _dealersRoles.getDealersRoles)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchProductList(query) {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.searchProducts)((0, _index2.getQuery)({
          keyword: query
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  doProductRowSpan(args) {
    const {
      row,
      column,
      rowIndex,
      columnIndex
    } = args;
    if ([2, 5].includes(columnIndex) && row.productId) {
      // check row - 1 has same product id
      if (rowIndex > 0) {
        const prevRow = this.list[rowIndex - 1];
        if (prevRow.productId === row.productId) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      return {
        rowspan: this.productIdRowsCount[row.productId],
        colspan: 1
      };
    }
    return 0;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await (0, _policiesDiscounts.downloadExcel)({
        query
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'policies-discount.xlsx');
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel:', error);
      this.$message.error('Error downloading Excel');
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'fullname', 'email', 'contact', 'role'];
    const filterVal = ['id', 'fullName'];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PoliciesDiscountList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;