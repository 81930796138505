"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.taxSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const taxSubMenu = exports.taxSubMenu = [{
  path: '/tax/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */'@/views/master/tax/list.vue'))),
  name: 'tax',
  meta: {
    title: 'master.tax.title',
    noCache: true,
    icon: 'tax'
  }
}, {
  path: '/tax/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/master/tax/manage.vue'))),
  name: 'Add Tax',
  props: {
    isEdit: false
  },
  meta: {
    title: 'master.tax.manage',
    noCache: true,
    activeMenu: '/tax/list',
    hidden: true
  }
}, {
  path: '/tax/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "countries-list" */'@/views/master/tax/manage.vue'))),
  name: 'Edit Tax',
  props: {
    isEdit: true
  },
  meta: {
    title: 'master.tax.manage',
    noCache: true,
    activeMenu: '/tax/list',
    hidden: true
  }
}];