"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("div", {
    staticClass: "app-container"
  }, [_vm.data && _vm.data.dealersUser ? _c("el-descriptions", {
    attrs: {
      size: "mini",
      border: "",
      "label-style": {
        "font-weight": "bold"
      }
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: "Dealer ID",
      span: 2
    }
  }, [_vm._v(" " + _vm._s(_vm.data.dealer.id) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Dealer",
      span: 10
    }
  }, [_c("router-link", {
    attrs: {
      to: "/dealers-addresses/list/" + _vm.data.dealer.id
    }
  }, [_c("el-link", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.dealer.name) + " ")])], 1)], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Dealer City",
      span: 3
    }
  }, [_vm._v(" " + _vm._s(_vm.data.dealer.city) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Fullname"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.dealersUser.fullName) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Mobile"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.dealersUser.mobileNumber) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Email"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.dealersUser.emailAddress) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Billing Address",
      span: 3
    }
  }, [_c("enabled", {
    staticClass: "noPaddingTopBottom",
    attrs: {
      disabled: true
    },
    model: {
      value: _vm.data.billingAddress.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.data.billingAddress, "enabled", $$v);
      },
      expression: "data.billingAddress.enabled"
    }
  }), _c("span", [_vm._v(" " + _vm._s(_vm.billingAddress) + " ")])], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Delivery Address",
      span: 3
    }
  }, [_c("enabled", {
    staticClass: "noPaddingTopBottom",
    attrs: {
      disabled: true
    },
    model: {
      value: _vm.data.deliveryAddress.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.data.deliveryAddress, "enabled", $$v);
      },
      expression: "data.deliveryAddress.enabled"
    }
  }), _c("span", [_vm._v(_vm._s(_vm.deliveryAddress))])], 1)], 1) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;