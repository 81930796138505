"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _moqSubMenu = require("./subMenus/moq-sub-menu");
var _moqsDiscountSubMenu = require("./subMenus/moqs-discount-sub-menu");
var _schemesDiscountSubMenu = require("./subMenus/schemes-discount-sub-menu");
var _policySubMenu = require("./subMenus/policy-sub-menu");
var _policiesDiscountSubMenu = require("./subMenus/policies-discount-sub-menu");
const schemeRouter = {
  path: '/schemes',
  component: _index.default,
  redirect: 'noredirect',
  name: 'schemeManagement.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Product Manager', 'Read Only'],
    title: 'schemeManagement.title',
    icon: 'discount'
  },
  children: [..._policySubMenu.policySubMenu, ..._policiesDiscountSubMenu.policiesDiscountSubMenu, {
    path: '/schemes/list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes/list.vue'))),
    name: 'schemeManagement.schemes.title',
    meta: {
      title: 'schemeManagement.schemes.title',
      roles: ['Super Admin', 'Admin', 'Read Only'],
      noCache: true,
      icon: 'discount'
    }
  }, {
    path: '/schemes/add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes/manage.vue'))),
    name: 'schemeManagement.schemes.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'schemeManagement.schemes.add',
      roles: ['Super Admin', 'Admin'],
      noCache: true,
      hidden: true
    }
  }, {
    path: '/schemes/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes/manage.vue'))),
    name: 'schemeManagement.schemes.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'schemeManagement.schemes.edit',
      roles: ['Super Admin', 'Admin'],
      noCache: true,
      hidden: true
    }
  }, ..._schemesDiscountSubMenu.schemesDiscountSubMenu, ..._moqSubMenu.moqSubMenu, ..._moqsDiscountSubMenu.moqsDiscountSubMenu]
};
var _default = exports.default = schemeRouter;