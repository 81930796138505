"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "priority",
      required: "",
      placeholder: "Priority",
      disabled: _vm.isReadOnly
    },
    model: {
      value: _vm.formData.priority,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "priority", $$v);
      },
      expression: "formData.priority"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Reference Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      name: "name",
      placeholder: "Name",
      maxlength: "25",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Link To",
      prop: "page"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      placeholder: "Select options"
    },
    model: {
      value: _vm.formData.page,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "page", $$v);
      },
      expression: "formData.page"
    }
  }, _vm._l(_vm.pageList, function (item) {
    return _c("el-option", {
      key: item.page,
      attrs: {
        label: item.label,
        value: item.page
      }
    });
  }), 1)], 1), _vm.formData.page === "Product" ? _c("el-form-item", {
    attrs: {
      label: "Select Product",
      prop: "productId"
    }
  }, [_c("el-select", {
    attrs: {
      "remote-method": _vm.getProducts,
      filterable: "",
      clearable: "",
      "default-first-option": "",
      remote: "",
      placeholder: "Type product name"
    },
    model: {
      value: _vm.formData.productId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "productId", $$v);
      },
      expression: "formData.productId"
    }
  }, _vm._l(_vm.productList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.catalogueCode) + " - " + _vm._s(item.name) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.formData.page === "Category" ? _c("el-form-item", {
    attrs: {
      label: "Select Category",
      prop: "categoryId"
    }
  }, [_c("el-select", {
    attrs: {
      name: "categoryId",
      clearable: "",
      filterable: "",
      "auto-complete": "off",
      placeholder: "Select",
      required: ""
    },
    model: {
      value: _vm.formData.categoryId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "categoryId", $$v);
      },
      expression: "formData.categoryId"
    }
  }, _vm._l(_vm.categoryList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.page === "External Link" ? _c("el-form-item", {
    attrs: {
      label: "Link",
      prop: "link"
    }
  }, [_c("el-input", {
    attrs: {
      type: "link",
      name: "link",
      required: ""
    },
    model: {
      value: _vm.formData.link,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "link", $$v);
      },
      expression: "formData.link"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Validity",
      prop: "validity"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      format: "dd-MM-yyyy",
      "default-time": ["00:00:00", "23:59:59"],
      "picker-options": {
        disabledDate: _vm.isDateAllowed
      }
    },
    model: {
      value: _vm.validity,
      callback: function ($$v) {
        _vm.validity = $$v;
      },
      expression: "validity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Image",
      prop: "mobileImage"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/banners/upload",
      field: "image",
      accept: ".jpg, .jpeg, .png",
      description: "(Note: Image should be of resolution 1125 x 500 px)",
      "max-size": "1",
      width: "1125",
      height: "500",
      "file-list": _vm.mobileImage
    },
    on: {
      change: _vm.mobileImageUploaded
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;