var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.data && _vm.data.ordersProduct ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.data.ordersProduct,
      "row-key": "id",
      border: "",
      "default-expand-all": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "40"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "product.name",
      label: "Name",
      width: "240"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.product.name) + " "), _c("br"), scope.row.productsVariation ? _c("small", [_vm._v("(" + _vm._s(scope.row.productsVariation.name) + ")")]) : _vm._e()];
      }
    }], null, false, 1657062216)
  }), _c("el-table-column", {
    attrs: {
      prop: "mrp",
      label: "MRP",
      width: "70",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.mrp ? [_c("inr", {
          attrs: {
            number: scope.row.mrp
          }
        })] : [_vm._v("-")]];
      }
    }], null, false, 2830271422)
  }), _c("el-table-column", {
    attrs: {
      prop: "discountAmount",
      label: "Discount",
      width: "120",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.ordersProductsDiscount, function (discount) {
          return _c("div", {
            key: discount.id
          }, [discount.discountType === "Policy" && discount.nettDiscount > 0 ? _c("div", [_vm._v(" " + _vm._s(discount.discountType) + " : " + _vm._s(discount.nettDiscount) + "/- ")]) : _c("div", [_vm._v(" " + _vm._s(discount.discountType) + " : " + _vm._s(discount.percentDiscount) + " % ")])]);
        });
      }
    }], null, false, 1927386925)
  }), _c("el-table-column", {
    attrs: {
      prop: "nettRate",
      label: "Nett Rate",
      width: "80",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.nettRate ? _c("inr", {
          attrs: {
            number: scope.row.nettRate
          }
        }) : _c("span", [_vm._v("-")])];
      }
    }], null, false, 3465805797)
  }), _c("el-table-column", {
    attrs: {
      prop: "quantity",
      label: "Units (pcs)",
      width: "90",
      align: "right"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "numberOfCartons",
      label: "Cartons",
      width: "60",
      align: "right"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "quantityDispatched",
      label: "Disp (pcs)",
      width: "70",
      align: "right"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "subTotal",
      label: "Sub Total",
      width: "120",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.subTotal
          }
        })];
      }
    }], null, false, 2761740793)
  }), _c("el-table-column", {
    attrs: {
      prop: "taxedAmount",
      label: "Tax",
      width: "70",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.taxedAmount
          }
        }), _vm._v(" "), _c("br"), _vm._v(" (" + _vm._s(scope.row.taxRate) + " %) ")];
      }
    }], null, false, 840253976)
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };