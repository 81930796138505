"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateDealersUser = exports.register = exports.logout = exports.login = exports.getUsersWallets = exports.getUserByEmail = exports.getDealersUsers = exports.getDealersUserById = exports.deleteDealersUser = exports.defaultUserWalletData = exports.defaultDealersUserData = exports.dealersUserCount = exports.addFundsToUserWaller = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultDealersUserData = exports.defaultDealersUserData = {
  id: 0,
  enabled: true,
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  walletBalance: 0,
  password: '',
  avatar: '',
  dealerId: undefined,
  dealer: {
    id: null
  }
};
const defaultUserWalletData = exports.defaultUserWalletData = {
  id: 0,
  enabled: true,
  amount: 0.0,
  action: 'credit',
  userId: null,
  orderId: null,
  user: {
    id: null
  },
  order: {
    id: null
  }
};
const getDealersUsers = params => (0, _request.default)({
  url: '/dealers-users',
  method: 'get',
  params
});
exports.getDealersUsers = getDealersUsers;
const getDealersUserById = id => (0, _request.default)({
  url: `/dealers-users/${id}`,
  method: 'get'
});
exports.getDealersUserById = getDealersUserById;
const getUserByEmail = adminemail => (0, _request.default)({
  url: `/dealers-users/${adminemail}`,
  method: 'get'
});
exports.getUserByEmail = getUserByEmail;
const updateDealersUser = (id, data) => (0, _request.default)({
  url: `/dealers-users/${id}`,
  method: 'patch',
  data
});
exports.updateDealersUser = updateDealersUser;
const deleteDealersUser = adminname => (0, _request.default)({
  url: `/dealers-users/${adminname}`,
  method: 'delete'
});
exports.deleteDealersUser = deleteDealersUser;
const login = data => (0, _request.default)({
  url: '/dealers-users/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: '/dealers-users/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/dealers-users',
  method: 'post',
  data
});
exports.register = register;
const dealersUserCount = params => (0, _request.default)({
  url: '/dealers-users/count',
  method: 'get',
  params
});
exports.dealersUserCount = dealersUserCount;
const getUsersWallets = params => (0, _request.default)({
  url: '/wallet-transactions',
  method: 'get',
  params
});
exports.getUsersWallets = getUsersWallets;
const addFundsToUserWaller = data => (0, _request.default)({
  url: '/wallet-transactions',
  method: 'post',
  data
});
exports.addFundsToUserWaller = addFundsToUserWaller;