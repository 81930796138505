"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _schemesDiscounts = require("@/api/schemes-discounts");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _dealersRoles = require("@/api/dealers-roles");
var _agents = require("@/api/agents");
var _states = require("@/api/locations/states");
var _ranges = require("@/api/ranges");
var _admin = require("@/store/modules/admin");
var _categories = require("@/api/categories");
var _products = require("@/api/products");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.isAgent = false;
    this.schemeId = 0;
    this.subCategoryIdRowsCount = {};
    this.productIdRowsCount = {};
    this.roleList = [];
    this.rangeList = [];
    this.stateList = [];
    this.agentList = [];
    this.categoryList = [];
    this.subCategoryList = [];
    this.productList = [];
    this.listQuery = {
      page: 1,
      limit: 10,
      enabled: true,
      schemeId: this.schemeId,
      dealersRoleId: undefined,
      rangeId: undefined,
      agentId: undefined,
      stateId: undefined,
      categoryId: undefined,
      subCategoryId: undefined,
      productId: undefined,
      sort: ['dealersRole.id,ASC', 'agent.id,ASC', 'state.id,ASC', 'schemesCategory.id,ASC', 'range.id,ASC', 'category.id,ASC', 'subCategory.id,ASC', 'productId,ASC', 'productsVariation.name,ASC', 'minimumQuantity,ASC'],
      filter: {
        enabled: '$eq',
        schemeId: '$eq',
        dealersRoleId: '$eq',
        agentId: '$eq',
        stateId: '$eq',
        rangeId: '$eq',
        categoryId: '$eq',
        subCategoryId: '$eq',
        productId: '$eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
    this.visibleColumns = {
      state: false,
      agent: false,
      action: false
    };
  }
  activated() {
    if (_admin.AdminModule.roles[0] === 'Read Only') {
      this.isReadOnly = true;
    }
    // check if logged in user is Agent/Admin
    if (_admin.AdminModule.userType === 'Agent') {
      this.isAgent = true;
      this.listQuery.agentId = _admin.AdminModule.agentId;
    }
    this.schemeId = parseInt(this.$route.params && this.$route.params.schemeId);
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    this.getList();
    this.fetchDealersRoles();
    this.fetchAgents();
    this.fetchStates();
    this.fetchRanges();
  }
  async getList() {
    try {
      this.listLoading = true;
      this.listQuery.schemeId = this.schemeId;
      if (this.listQuery.dealersRoleId || this.listQuery.agentId || this.listQuery.stateId) {
        const data = await (0, _schemesDiscounts.getSchemesDiscounts)((0, _index2.getQuery)(this.listQuery));
        /* find out product id rows count & assign to find product id row */
        this.productIdRowsCount = {};
        this.subCategoryIdRowsCount = {};
        // find out product id rows count & assign to find product id row
        data.data.forEach(item => {
          if (item.productId) {
            if (this.productIdRowsCount[item.productId]) {
              this.productIdRowsCount[item.productId] += 1;
            } else {
              this.productIdRowsCount[item.productId] = 1;
            }
          } else if (item.subCategoryId) {
            if (this.subCategoryIdRowsCount[item.subCategoryId]) {
              this.subCategoryIdRowsCount[item.subCategoryId] += 1;
            } else {
              this.subCategoryIdRowsCount[item.subCategoryId] = 1;
            }
          }
        });
        // add editingQuantity & editingDiscount flag
        data.data.forEach(item => {
          item.editingQuantity = false;
          item.oldMinimumQuantity = item.minimumQuantity;
          item.editingDiscount = false;
          item.oldPercentDiscount = item.percentDiscount;
        });
        this.list = data.data;
        this.total = data.total;
      } else {
        this.list = [];
        this.total = 0;
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async fetchDealersRoles() {
    this.roleList = await (0, _dealersRoles.getDealersRoles)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchAgents() {
    this.agentList = await (0, _agents.getAgents)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  async fetchStates() {
    this.stateList = await (0, _states.getStates)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  async fetchRanges() {
    this.rangeList = await (0, _ranges.getRanges)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchCategories() {
    if (this.listQuery.rangeId) {
      this.categoryList = await (0, _categories.getCategories)((0, _index2.getQuery)({
        enabled: true,
        parentId: true,
        rangeId: this.listQuery.rangeId,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq',
          parentId: '$isnull',
          rangeId: '$eq'
        }
      }));
      if (this.categoryList.length === 0) {
        this.listQuery.categoryId = undefined;
      }
    } else {
      this.categoryList = [];
    }
  }
  async fetchSubCategories() {
    if (this.listQuery.categoryId) {
      this.subCategoryList = await (0, _categories.getCategories)((0, _index2.getQuery)({
        enabled: true,
        parentId: this.listQuery.categoryId,
        rangeId: this.listQuery.rangeId,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq',
          parentId: '$eq',
          rangeId: '$eq'
        }
      }));
      if (this.subCategoryList.length === 0) {
        this.listQuery.subCategoryId = undefined;
      }
    } else {
      this.subCategoryList = [];
    }
  }
  async handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  handleCategoryFilter() {
    this.listQuery.categoryId = undefined;
    this.listQuery.subCategoryId = undefined;
    this.fetchCategories();
    this.getList();
  }
  handleSubCategoryFilter() {
    this.listQuery.subCategoryId = undefined;
    this.fetchSubCategories();
    this.getList();
  }
  async fetchProductList(query) {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.searchProducts)((0, _index2.getQuery)({
          keyword: query
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  doProductRowSpan(args) {
    const {
      row,
      column,
      rowIndex,
      columnIndex
    } = args;
    return {
      rowspan: 1,
      colspan: 1
    };
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'fullname', 'email', 'contact', 'role'];
    const filterVal = ['id', 'fullName'];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
  getProductDetails(row) {
    const details = [];
    // check for range, category, sub category, product, variation
    if (row.range) {
      details.push(row.range.name);
    }
    if (row.category) {
      details.push(row.category.name);
    }
    if (row.subCategory) {
      details.push(row.subCategory.name);
    }
    if (row.product) {
      details.push(row.product.name);
    }
    if (row.productsVariation) {
      details.push(row.productsVariation.name);
    }
    return details.join(' / ');
  }
  editQuantity(index = -1) {
    if (index === -1) {
      return;
    }
    // check if index is exists in list
    if (index in this.list) {
      this.list[index].editingQuantity = true;
    }
  }
  editDiscount(index = -1) {
    if (index === -1) {
      return;
    }
    // check if index is exists in list
    if (index in this.list) {
      this.list[index].editingDiscount = true;
    }
  }
  async update(index, id, data) {
    try {
      // disable editing flag
      this.list[index].editingQuantity = false;
      this.list[index].editingDiscount = false;
      // update data
      const response = await (0, _schemesDiscounts.updateSchemesDiscount)(id, data);
      // override only minimumQuantity & percentDiscount
      this.list[index].minimumQuantity = response.minimumQuantity;
      this.list[index].percentDiscount = response.percentDiscount;
      this.$message({
        message: 'updated successfully',
        type: 'success'
      });
    } catch (error) {
      this.$alert('Error updating data', 'Error', {
        confirmButtonText: 'OK',
        type: 'error'
      });
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SchemesDiscountList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;