"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _DispatchList = _interopRequireDefault(require("@/views/orders-dispatches/components/DispatchList.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.listLoading = false;
    this.list = [];
    this.tableKey = 0;
    this.expandedRowKeys = [];
  }
  activated() {}
  toggleExpand(row) {
    if (this.expandedRowKeys.indexOf(row.id) === -1) {
      this.expandedRowKeys = [row.id];
    } else {
      this.expandedRowKeys = [];
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: []
})], default_1.prototype, "data", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrderDispatchTab',
  components: {
    Inr: _index.default,
    DispatchList: _DispatchList.default
  }
})], default_1);
var _default = exports.default = default_1;