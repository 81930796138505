"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleClass = exports.removeClass = exports.parseTime = exports.hasClass = exports.getQuery = exports.getDescendantProp = exports.formatJson = exports.addClass = void 0;
require("core-js/modules/es.array.push.js");
// Parse the time to string
const parseTime = (time, cFormat) => {
  if (time === undefined) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      return '0' + value;
    }
    return String(value) || '0';
  });
  return timeStr;
};
exports.parseTime = parseTime;
const formatJson = (filterKeys, jsonData) => {
  var out = [];
  var c = 0;
  for (var data of jsonData) {
    var dataExport = [];
    for (var key of filterKeys) {
      if (['createdTimestamp', 'timestamp', 'orderTimestamp'].indexOf(key) > -1) {
        dataExport.push(parseTime(data[key]));
      } else if (key === 'enabled') {
        dataExport.push(data[key] ? 'enabled' : 'disabled');
      } else if (key.indexOf('.') > 0) {
        dataExport.push(getDescendantProp(data, key));
      } else if (key === 'balance') {
        const diff = data.grandTotal - data.amountReceived;
        if (diff < 0) {
          dataExport.push(0);
        } else {
          dataExport.push(diff);
        }
      } else if (key === 'products' || key === 'quantity' || key === 'totalAmount' || key === 'taxAmount' || key === 'rate') {
        // var products = ''
        // for (var i = 0; i < data.ordersProduct.length; i++) {
        //   if (data.ordersProduct[i].product) {
        //     products += `${data.ordersProduct[i].product.name}`
        //   }
        // }
        dataExport.push('');
      } else if (key === 'address' || key === 'shippingAddress') {
        var addressObj = key === 'address' ? data.address : data.shippingAddress;
        if (addressObj) {
          var address = [addressObj.line1, addressObj.line2];
          if (addressObj.city) {
            address.push(addressObj.city.name);
          }
          if (addressObj.state) {
            address.push(addressObj.state.name);
          }
          if (addressObj.country) {
            address.push(addressObj.country.name);
          }
          if (addressObj.pincode) {
            address.push(addressObj.pincode.pincode);
          }
          dataExport.push(address.filter(Boolean).join(',  '));
        } else {
          dataExport.push('');
        }
      } else {
        dataExport.push(data[key]);
      }
    }
    if (filterKeys.includes('products') && 'ordersProduct' in data) {
      for (var prod of data.ordersProduct) {
        var temp = [...dataExport];
        if (prod.product) {
          temp[11] = prod.product.name;
        }
        temp[12] = prod.quantity;
        temp[13] = +prod.salePrice > 0 ? prod.salePrice : prod.unitPrice;
        temp[14] = prod.subTotal;
        temp[15] = prod.taxedAmount;
        out.push(temp);
      }
    } else {
      out.push(dataExport);
    }
  }
  return out;
};
// Format and filter json data using filterKeys array
// export const formatJson = (filterKeys: any, jsonData: any) =>
//    jsonData.map((data: any) => filterKeys.map((key: string) => {
//     if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
//       return parseTime(data[key])
//     } else if (key === 'enabled') {
//       return data[key] ? 'enabled' : 'disabled'
//     } else if (key.indexOf('.') > 0) {
//       return getDescendantProp(data, key)
//       // return data[key.substr(0, key.indexOf('.'))][key.substr(key.indexOf('.') + 1)]
//     } else if (key === 'balance') {
//       const diff = data.grandTotal - data.amountReceived
//       if (diff < 0) {
//         return 0
//       } else {
//         return diff
//       }
//     } else if (key === 'products') {
//       var products = ''
//       for (var i = 0; i < data.ordersProduct.length; i++) {
//         if (data.ordersProduct[i].product) {
//           products += `${data.ordersProduct[i].product.name}`
//         }
//       }
//       return products
//     }
//     else if (key === 'address') {
//       var addressObj = data.address
//       if(addressObj){
//       var address = [addressObj.line1,addressObj.line2]
//       if(addressObj.pincode){
//         address.push(addressObj.pincode.name)
//       }
//       if(addressObj.city){
//         address.push(addressObj.city.name)
//       }
//       if(addressObj.state){
//         address.push(addressObj.state.name)
//       }
//       if(addressObj.country){
//         address.push(addressObj.country.name)
//       }
//       address.filter(Boolean).join(",  ");
//       return address
//       }
//       else{
//         return ''
//       }
//     }
//     else {
//       return data[key]
//     }
//   })
//   )
// Check if an element has a class
exports.formatJson = formatJson;
const hasClass = (ele, className) => {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};
// Add class to element
exports.hasClass = hasClass;
const addClass = (ele, className) => {
  if (!hasClass(ele, className)) ele.className += ' ' + className;
};
// Remove class from element
exports.addClass = addClass;
const removeClass = (ele, className) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
};
// Toggle class for the selected element
exports.removeClass = removeClass;
const toggleClass = (ele, className) => {
  if (!ele || !className) {
    return;
  }
  let classString = ele.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  ele.className = classString;
};
exports.toggleClass = toggleClass;
const getQuery = query => {
  const processedQuery = Object.assign({}, query);
  // set filter as empty
  processedQuery.filter = [];
  // check for filter
  if ('filter' in query) {
    // loop over fields and check for values
    Object.entries(query.filter).forEach(([field, condition]) => {
      // check if field value has be set
      if (field in query && [undefined, ''].indexOf(query[field]) === -1) {
        processedQuery.filter.push(field + '||' + condition + '||' + processedQuery[field]);
        delete processedQuery[field];
      } else {
        if (['$isnull'].indexOf(condition.toString()) > -1) {
          processedQuery.filter.push(field + '||' + condition);
          delete processedQuery[field];
        }
      }
    });
  }
  return processedQuery;
};
exports.getQuery = getQuery;
const getDescendantProp = (obj, desc) => {
  var arr = desc.split('.');
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};
exports.getDescendantProp = getDescendantProp;