"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _admin = require("@/store/modules/admin");
var _admins = require("@/api/admins");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.userData = {};
  }
  activated() {
    this.fetchData(_admin.AdminModule.id);
  }
  async fetchData(id) {
    try {
      const data = await (0, _admins.getAdminById)(id);
      this.userData = data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "user", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'UserCard',
  components: {
    PanThumb: _index.default
  }
})], default_1);
var _default = exports.default = default_1;