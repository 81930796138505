"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  getFormattedNumber() {
    if (this.number) {
      return this._formatNumber(this.number);
    }
    return this.number;
  }
  _formatNumber(numberToFormat = 0) {
    // Handle null and empty cases
    if (numberToFormat === null || numberToFormat === '') {
      return '0.00';
    }
    // Ensure the number is treated as a string for formatting
    numberToFormat = numberToFormat.toString();
    // Split the number into integer and decimal parts
    let [integerPart, decimalPart] = numberToFormat.split('.');
    // Format the integer part with commas (Indian style)
    let lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    if (otherDigits !== '') {
      lastThreeDigits = ',' + lastThreeDigits;
    }
    integerPart = otherDigits.split('').reverse().join('');
    let formattedIntegerPart = '';
    for (let i = 0; i < integerPart.length; i += 2) {
      formattedIntegerPart += integerPart.substr(i, 2) + ',';
    }
    formattedIntegerPart = formattedIntegerPart.split('').reverse().join('').replace(/^,|,$/g, '') + lastThreeDigits;
    // Handle decimal part, ensuring two digits
    if (decimalPart) {
      decimalPart = parseFloat('0.' + decimalPart).toFixed(2).split('.')[1];
    } else {
      decimalPart = '00';
    }
    // Combine the formatted integer part with decimals only if they exist
    const formattedNumber = formattedIntegerPart + '.' + decimalPart;
    return formattedNumber;
  }
  _numberToWords() {
    const number = this.number + '';
    const numberToFormat = number.split('.');
    let output = this._getNumbersInWords(numberToFormat[0]);
    if (output.trim()) {
      output += ' rupee';
      if (output.trim() !== 'One rupee') {
        // adding s
        output += 's';
      }
    }
    if (numberToFormat[1]) {
      const paise = this._getNumbersInWords(numberToFormat[1]);
      if (paise.trim()) {
        if (output.trim()) {
          output += ' and ';
        }
        output += paise + ' paise';
      }
    }
    return output;
  }
  _getNumbersInWords(numberToFormat) {
    var sglDigit = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    var dblDigit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    var tensPlace = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    var handleTens = function (digits, prevDigits) {
      return digits === 0 ? '' : ' ' + (digits === 1 ? dblDigit[prevDigits] : tensPlace[digits]);
    };
    var handleUtlc = function (digits, nxtdigits, denom) {
      return (digits !== 0 && nxtdigits !== 1 ? ' ' + sglDigit[digits] : '') + (nxtdigits !== 0 || digits > 0 ? ' ' + denom : '');
    };
    var str = '';
    var digitIdx = 0;
    var digit = 0;
    var nxtDigit = 0;
    var words = [];
    if (numberToFormat += '', isNaN(parseInt(numberToFormat))) str = '';else if (parseInt(numberToFormat) > 0 && numberToFormat.length <= 10) {
      for (digitIdx = numberToFormat.length - 1; digitIdx >= 0; digitIdx--) {
        switch (digit = numberToFormat[digitIdx] - 0, nxtDigit = digitIdx > 0 ? numberToFormat[digitIdx - 1] - 0 : 0, numberToFormat.length - digitIdx - 1) {
          case 0:
            words.push(handleUtlc(digit, nxtDigit, ''));
            break;
          case 1:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 2:
            words.push(digit !== 0 ? ' ' + sglDigit[digit] + ' Hundred' + (numberToFormat[digitIdx + 1] !== 0 && numberToFormat[digitIdx + 2] !== 0 ? ' and' : '') : '');
            break;
          case 3:
            words.push(handleUtlc(digit, nxtDigit, 'Thousand'));
            break;
          case 4:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 5:
            words.push(handleUtlc(digit, nxtDigit, 'Lakh'));
            break;
          case 6:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 7:
            words.push(handleUtlc(digit, nxtDigit, 'Crore'));
            break;
          case 8:
            words.push(handleTens(digit, numberToFormat[digitIdx + 1]));
            break;
          case 9:
            words.push(digit !== 0 ? ' ' + sglDigit[digit] + ' Hundred' + (numberToFormat[digitIdx + 1] !== 0 || numberToFormat[digitIdx + 2] !== 0 ? ' and' : ' Crore') : '');
        }
      }
      str = words.reverse().join('').trim().replace(/and$/, '');
    } else str = '';
    return str;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ''
})], default_1.prototype, "size", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "number", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Inr',
  components: {}
})], default_1);
var _default = exports.default = default_1;