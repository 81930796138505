"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locationSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const locationSubMenu = exports.locationSubMenu = [{
  path: '/countries/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "countries-list" */'@/views/locations/countries/list.vue'))),
  name: 'Countries',
  meta: {
    roles: ['Super Admin', 'Read Only'],
    title: 'locations.countries.title',
    noCache: true,
    icon: 'globe',
    hidden: true
  }
}, {
  path: '/countries/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "countries-list" */'@/views/locations/countries/manage.vue'))),
  name: 'Edit Country',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.countries.edit',
    noCache: true,
    activeMenu: '/countries/list',
    hidden: true
  }
}, {
  path: '/countries/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/countries/manage.vue'))),
  name: 'Add Country',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.countries.add',
    noCache: true,
    activeMenu: '/countries/list',
    hidden: true
  }
}, {
  path: '/states/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "states-list" */'@/views/locations/states/list.vue'))),
  name: 'States',
  meta: {
    title: 'locations.states.title',
    noCache: true,
    roles: ['Super Admin', 'Read Only'],
    icon: 'globe'
  }
}, {
  path: '/states/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "states-list" */'@/views/locations/states/manage.vue'))),
  name: 'Edit State',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.states.edit',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/states/add/:countryId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/states/manage.vue'))),
  name: 'Add State',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.states.add',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/cities/list/:stateId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "cities-list" */'@/views/locations/cities/list.vue'))),
  name: 'Cities',
  meta: {
    title: 'locations.cities.title',
    noCache: true,
    hidden: true
  }
}, {
  path: '/cities/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "cities-list" */'@/views/locations/cities/manage.vue'))),
  name: 'Edit City',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.cities.edit',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}, {
  path: '/cities/add/:stateId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/cities/manage.vue'))),
  name: 'Add City',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.cities.add',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
},
// pincode
{
  path: '/pincode/list/:cityId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/pincode/list.vue'))),
  name: 'Pincode',
  meta: {
    title: 'locations.pincode.title',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}, {
  path: '/pincode/add/:cityId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/pincode/manage.vue'))),
  name: 'Add Pincode',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.pincode.add',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}, {
  path: '/pincode/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/pincode/manage.vue'))),
  name: 'Edit Pincode',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.pincode.edit',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
},
// branches
{
  path: '/branches/list/:cityId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/branches/list.vue'))),
  name: 'Branches',
  meta: {
    title: 'locations.branch.title',
    noCache: true,
    activeMenu: '/branches/list',
    hidden: true
  }
}, {
  path: '/branches/add/:cityId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/branches/manage.vue'))),
  name: 'Add Branch',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.branch.add',
    noCache: true,
    activeMenu: '/branch/list',
    hidden: true
  }
}, {
  path: '/branches/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/branches/manage.vue'))),
  name: 'Edit Branch',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.branch.edit',
    noCache: true,
    activeMenu: '/branches/list',
    hidden: true
  }
},
// units
{
  path: '/units/list/:branchId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/units/list.vue'))),
  name: 'Units',
  meta: {
    title: 'locations.unit.title',
    noCache: true,
    activeMenu: '/units/list',
    hidden: true
  }
}, {
  path: '/units/add/:branchId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/units/manage.vue'))),
  name: 'Add Unit',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.unit.add',
    noCache: true,
    activeMenu: '/units/list',
    hidden: true
  }
}, {
  path: '/units/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/locations/units/manage.vue'))),
  name: 'Edit Units',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.unit.edit',
    noCache: true,
    activeMenu: '/units/list',
    hidden: true
  }
}];