"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block moq-discount-form"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dealers Role",
      prop: "dealersRoleId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      name: "dealersRoleId",
      placeholder: "Select"
    },
    on: {
      change: _vm.fetchVariantions
    },
    model: {
      value: _vm.formData.dealersRoleId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealersRoleId", $$v);
      },
      expression: "formData.dealersRoleId"
    }
  }, _vm._l(_vm.roleList, function (role) {
    return _c("el-option", {
      key: role.id,
      attrs: {
        label: role.name,
        value: role.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Product",
      prop: "productId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      remote: "",
      disabled: !_vm.formData.dealersRoleId,
      "remote-method": _vm.fetchProducts,
      placeholder: "Search product"
    },
    on: {
      change: _vm.fetchVariantions
    },
    model: {
      value: _vm.formData.productId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "productId", $$v);
      },
      expression: "formData.productId"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.name,
        value: product.id
      }
    }, [_vm._v(" " + _vm._s(product.catalogueCode) + " - " + _vm._s(product.name) + " ")]);
  }), 1)], 1), _vm.formData.productId ? _c("el-table", {
    staticStyle: {
      width: "100%",
      "margin-top": "20px"
    },
    attrs: {
      data: _vm.moqsDiscountList,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Variations",
      align: "center",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.productsVariation ? [_vm._v(" " + _vm._s(scope.row.productsVariation.name) + " ")] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      label: "Quantity",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Loose Qty",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            min: 0,
            size: "mini"
          },
          on: {
            change: function ($event) {
              return _vm.quantityChange(scope.$index, "pieces");
            }
          },
          model: {
            value: scope.row.looseQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "looseQuantity", $$v);
            },
            expression: "scope.row.looseQuantity"
          }
        })];
      }
    }], null, false, 2159291883)
  }), _c("el-table-column", {
    attrs: {
      label: "Carton Qty",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            min: 0,
            size: "mini"
          },
          on: {
            change: function ($event) {
              return _vm.quantityChange(scope.$index, "carton");
            }
          },
          model: {
            value: scope.row.cartonQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "cartonQuantity", $$v);
            },
            expression: "scope.row.cartonQuantity"
          }
        })];
      }
    }], null, false, 2144941624)
  }), _c("el-table-column", {
    attrs: {
      label: "Final Qty (PCS)",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: "",
            controls: false,
            min: 0,
            size: "mini"
          },
          model: {
            value: scope.row.minimumQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "minimumQuantity", $$v);
            },
            expression: "scope.row.minimumQuantity"
          }
        })];
      }
    }], null, false, 141538131)
  })], 1), _c("el-table-column", {
    attrs: {
      label: "Discount (%)",
      align: "center",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: !scope.row.minimumQuantity,
            size: "mini",
            precision: 2,
            step: 1,
            max: 100,
            min: 0
          },
          on: {
            change: function ($event) {
              return _vm.validatePercent(scope.$index);
            }
          },
          model: {
            value: scope.row.discount,
            callback: function ($$v) {
              _vm.$set(scope.row, "discount", $$v);
            },
            expression: "scope.row.discount"
          }
        })];
      }
    }], null, false, 434052428)
  })], 1) : _vm._e(), _c("br"), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success",
      disabled: !_vm.moqsDiscountList.length
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;