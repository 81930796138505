"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _ordersDispatchesSubMenu = require("./subMenus/orders-dispatches-sub-menu");
var _inventoryInwardsSubMenu = require("./subMenus/inventory-inwards-sub-menu");
const inventoryRouter = {
  path: '/inventory',
  component: _index.default,
  redirect: 'noredirect',
  name: 'inventoryManagement.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Invoice Manager', 'Agent', 'Read Only'],
    title: 'inventoryManagement.title',
    icon: 'truck'
  },
  children: [..._inventoryInwardsSubMenu.inventoryInwardSubMenu, ..._ordersDispatchesSubMenu.ordersDispatchesSubMenu]
};
var _default = exports.default = inventoryRouter;