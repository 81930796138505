var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_vm.superAdmin ? _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "/report/salesReport/list?startDate=2024-04-01 12:00 am&endDate=2025-03-31 11:59 pm"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current FY Sales ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.totalFySales
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "transaction/list?startDate=2024-04-01 12:00 am&endDate=2025-03-31 11:59 pm"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current FY Payments ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.totalFyPayment
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Recievable Amount ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.currentRecievableAmount
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "order/list/?status=Order Confirmed"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Pending Orders ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.totalPendingOrders
    }
  })], 1)])])], 1)], 1) : _vm._e(), _vm.superAdmin ? _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: `/report/salesReport/list?startDate=${_vm.currentMonthDate.start}&endDate=${_vm.currentMonthDate.end}`
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Month Sale ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.currentMonthSales
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Month Payments ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.currentMonthPayments
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: `/report/salesReport/list?startDate=${_vm.previousMonthDate.start}&endDate=${_vm.previousMonthDate.end}`
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Previous Month Sales ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.previousMonthSales
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Previous Month Payments ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.previousMonthPayments
    }
  })], 1)])])], 1)], 1) : _vm._e(), _vm.superAdmin ? _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "/orders-dispatches/overdue-list?days=30&dealerWise=true"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Overdue-30 days ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.overdueAmount30days
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "/orders-dispatches/overdue-list?days=60&dealerWise=true"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Overdue-60 days ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.overdueAmount60days
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "/orders-dispatches/overdue-list?days=90&dealerWise=true"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Overdue-90 days ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.overdueAmount90days
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "/orders-dispatches/overdue-list?days=120&dealerWise=true"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Overdue-120 days ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.overdueAmount120days
    }
  })], 1)])])], 1)], 1) : _vm._e(), _vm.superAdmin ? _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: `/order/list?startDate=${_vm.currentDateRange.start}&endDate=${_vm.currentDateRange.end}`
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Daily Order Value ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.dailyOrderValue
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: `/order/list?startDate=${_vm.currentMonthDate.start}&endDate=${_vm.currentMonthDate.end}`
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Month Order Value ")]), _c("inr", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold"
    },
    attrs: {
      number: _vm.currentMonthOrderValue
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Current Month Order Count ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.currentMonthOrderCount,
      duration: 2600
    }
  })], 1)])])], 1)], 1) : _vm._e(), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 12,
      lg: 8
    }
  }, [_c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY hh:mm a"
    },
    on: {
      change: _vm.dateval
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 12,
      lg: 8
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.dateval
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.filter")) + " ")])], 1)], 1), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "orders-dispatches/outstanding-list"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Outstanding ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalOutstandingAmount,
      duration: 2600
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 6,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Total Amount Received ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalAmountReceived,
      duration: 2600
    }
  })], 1)])])], 1)], 1), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      xs: 24,
      sm: 12,
      lg: 12
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px",
      "font-weight": "bold"
    }
  }, [_vm._v(" Top Selling Products ")]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.topProducts
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "productName",
      label: "Product",
      width: "280"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "productsVariationName",
      label: "Variant",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "quantity",
      label: "Qty (pcs)",
      align: "right"
    }
  })], 1)], 1), _c("el-col", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      xs: 24,
      sm: 12,
      lg: 12
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px",
      "font-weight": "bold"
    }
  }, [_vm._v(" Top Dealers ")]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.topDealers
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "dealerName",
      label: "Dealer",
      width: "280"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "total",
      label: "Amount",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.total
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v(" Orders ")]), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, _vm._l(_vm.orderStatus, function (status) {
    return _c("el-col", {
      key: status.status,
      staticClass: "card-panel-col",
      attrs: {
        xs: 12,
        sm: 6,
        lg: 4
      }
    }, [_c("router-link", {
      attrs: {
        to: "/order/list?status=" + status.status
      }
    }, [_c("div", {
      staticClass: "card-panel"
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "card-panel-text"
    }, [_vm._v(" " + _vm._s(status.status) + " ")]), _c("count-to", {
      staticClass: "card-panel-num",
      attrs: {
        "start-val": 0,
        "end-val": status.count,
        duration: 2600
      }
    })], 1)])])], 1);
  }), 1), _c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v(" Dispatch ")]), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, _vm._l(_vm.dispatchStatus, function (status) {
    return _c("el-col", {
      key: status.status,
      staticClass: "card-panel-col",
      attrs: {
        xs: 12,
        sm: 6,
        lg: 4
      }
    }, [_c("router-link", {
      attrs: {
        to: "/orders-dispatches/list?status=" + status.status
      }
    }, [_c("div", {
      staticClass: "card-panel"
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "card-panel-text"
    }, [_vm._v(" " + _vm._s(status.status) + " ")]), _c("count-to", {
      staticClass: "card-panel-num",
      attrs: {
        "start-val": 0,
        "end-val": status.count,
        duration: 2600
      }
    })], 1)])])], 1);
  }), 1), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 8,
      lg: 6
    }
  }, [[_c("router-link", {
    attrs: {
      to: "/dealers/list"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-people"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "peoples"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Dealers ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dealerCount,
      duration: 2600
    }
  })], 1)])])]], 2), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 8,
      lg: 6
    }
  }, [[_c("router-link", {
    attrs: {
      to: "/agents/list"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-message"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "user"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Agents ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.agentCount,
      duration: 2600
    }
  })], 1)])])]], 2), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 8,
      lg: 6
    }
  }, [[_c("router-link", {
    attrs: {
      to: "/product/list"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-shopping"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "shopping"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Products ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.productscount,
      duration: 3000
    }
  })], 1)])])]], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };