"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$data;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.data ? _c("el-descriptions", {
    attrs: {
      title: "Order Details",
      border: "",
      "label-style": {
        "font-weight": "bold"
      }
    }
  }, [_c("template", {
    slot: "extra"
  }, [["Order Confirmed", "Partial Dispatch", "Order Dispatched", "Order Partial Cancelled"].includes((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.status) && _vm.isSuperAdmin() ? _c("el-button", {
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-s-claim",
      disabled: _vm.reviseOrderFlag
    },
    on: {
      click: function ($event) {
        return _vm._reviseOrder(_vm.data.id);
      }
    }
  }, [_vm._v(" Re-Calculate ")]) : _vm._e()], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Order ID"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.id) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Order Reference"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.orderReference) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Taxed amount"
    }
  }, [_c("inr", {
    attrs: {
      number: _vm.data.taxedAmount
    }
  })], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Status"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.status) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Placed On",
      span: 2
    }
  }, [_vm.data.orderTimestamp ? _c("span", [_vm._v(" " + _vm._s(_vm._f("moment")(_vm.data.orderTimestamp, "DD-MM-YYYY hh:mm a")) + " ")]) : _vm._e()]), _c("el-descriptions-item", {
    attrs: {
      label: "Total Quantity"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalQuantity) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Total Product(s)"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalProducts) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Sub Total"
    }
  }, [_c("inr", {
    attrs: {
      number: _vm.data.total
    }
  })], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Delivery Option"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.deliveryOption) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Payment Gateway"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.paymentGateway) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: "Grand Total"
    }
  }, [_c("inr", {
    attrs: {
      number: _vm.data.grandTotal
    }
  })], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Amount From Wallet"
    }
  }, [_c("inr", {
    attrs: {
      number: _vm.data.amountFromWallet
    }
  })], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Amount From Card"
    }
  }, [_c("inr", {
    attrs: {
      number: _vm.data.amountFromCard
    }
  })], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Amount Received"
    }
  }, [_c("inr", {
    attrs: {
      number: _vm.data.amountReceived
    }
  })], 1), _c("el-descriptions-item", {
    attrs: {
      label: "Comments"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.comments) + " ")]), _vm.data.status === "Not Delivered" ? _c("el-descriptions-item", {
    attrs: {
      label: "Not Delivered Reason"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.notDeliveredReason) + " ")]) : _vm._e()], 2) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;