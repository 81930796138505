"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.deleteForm = {
      fullName: '',
      mobileNumber: '',
      email: '',
      comments: ''
    };
    this.formRules = {
      fullName: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      mobileNumber: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        pattern: /^\d{10}$/,
        message: 'Mobile number should consist of 10 digits',
        trigger: 'blur'
      }],
      email: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        type: 'email',
        message: 'Enter valid email',
        trigger: 'blur'
      }],
      comments: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }]
    };
    this.loading = false;
    this.otherQuery = {};
  }
  onRouteChange(route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }
  mounted() {
    if (this.deleteForm.fullName === '') {
      this.$refs.fullName.focus();
    }
  }
  handleLogin() {
    this.$refs.deleteForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          setTimeout(() => {
            this.$confirm('We have received your request for account deletion.', 'Request Confirmed', {
              showCancelButton: false,
              type: 'success',
              center: true,
              showClose: false
            });
            this.loading = false;
          }, 1000);
        } catch (error) {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
  getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {});
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('$route', {
  immediate: true
})], default_1.prototype, "onRouteChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Login',
  components: {
    LangSelect: _index.default
  }
})], default_1);
var _default = exports.default = default_1;