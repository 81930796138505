"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_vm.formData.length ? _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.formData,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "ID",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Product",
      prop: "productId"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.product.name) + " "), _c("br"), _c("small", [_vm._v("(" + _vm._s(scope.row.productsVariation.name) + ")")])];
      }
    }], null, false, 120073726)
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      align: "center",
      label: "Rating",
      prop: "rating"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-rate", {
          attrs: {
            disabled: ""
          },
          model: {
            value: scope.row.rating,
            callback: function ($$v) {
              _vm.$set(scope.row, "rating", $$v);
            },
            expression: "scope.row.rating"
          }
        })];
      }
    }], null, false, 2794335904)
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      align: "center",
      label: "Approve",
      prop: "isRatingApproved"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-switch", {
          model: {
            value: scope.row.isRatingApproved,
            callback: function ($$v) {
              _vm.$set(scope.row, "isRatingApproved", $$v);
            },
            expression: "scope.row.isRatingApproved"
          }
        })];
      }
    }], null, false, 3870492827)
  })], 1), _c("br"), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;