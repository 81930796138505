"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _path = _interopRequireDefault(require("path"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _validate = require("@/utils/validate");
var _SidebarItemLink = _interopRequireDefault(require("./SidebarItemLink.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  get alwaysShowRootMenu() {
    if (this.item.meta && this.item.meta.alwaysShow) {
      return true;
    }
    return false;
  }
  get showingChildNumber() {
    if (this.item.children) {
      const showingChildren = this.item.children.filter(item => {
        if (item.meta && item.meta.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }
  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return {
      ...this.item,
      path: ''
    };
  }
  resolvePath(routePath) {
    if ((0, _validate.isExternal)(routePath)) {
      return routePath;
    }
    if ((0, _validate.isExternal)(this.basePath)) {
      return this.basePath;
    }
    return _path.default.resolve(this.basePath, routePath);
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "item", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isCollapse", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: true
})], default_1.prototype, "isFirstLevel", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ''
})], default_1.prototype, "basePath", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'SidebarItem',
  components: {
    SidebarItemLink: _SidebarItemLink.default
  }
})], default_1);
var _default = exports.default = default_1;