"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "image", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '150px'
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '150px'
})], default_1.prototype, "height", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 1
})], default_1.prototype, "zIndex", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PanThumb'
})], default_1);
var _default = exports.default = default_1;