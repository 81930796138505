"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("order-info-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  }), _c("el-divider"), _vm.formData.status !== "Delivery Confirmed" ? _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Status",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      name: "status",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.status,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, _vm._l(_vm.statusOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        required: "",
        label: item.label,
        value: item.key
      }
    });
  }), 1)], 1), _vm.formData.status === "Order Cancelled" ? _c("el-form-item", {
    attrs: {
      label: "Comments",
      prop: "comments"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      name: "comments",
      placeholder: "Comments"
    },
    model: {
      value: _vm.formData.comments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1) : _vm._e(), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;