"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateDealer = exports.searchDealers = exports.getDealers = exports.getDealerHoldInfo = exports.getDealerCities = exports.getDealerById = exports.getAgentDealers = exports.downloadExcel = exports.deleteDealer = exports.defaultDealerData = exports.dealersCount = exports.createDealer = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultDealerData = exports.defaultDealerData = {
  id: 0,
  enabled: true,
  tcsApplicable: false,
  name: '',
  code: '',
  status: 'Pending',
  logo: undefined,
  holdBy: null,
  holdReason: null,
  agentId: null,
  agent: {
    id: 0
  },
  dealersRoleId: null,
  dealersRole: {
    id: 0
  },
  branchId: null,
  branch: {
    id: 0
  },
  scheme: []
};
const getDealers = params => (0, _request.default)({
  url: '/dealers',
  method: 'get',
  params
});
exports.getDealers = getDealers;
const getDealerById = id => (0, _request.default)({
  url: `/dealers/${id}`,
  method: 'get'
});
exports.getDealerById = getDealerById;
const updateDealer = (id, data) => (0, _request.default)({
  url: `/dealers/${id}`,
  method: 'patch',
  data
});
exports.updateDealer = updateDealer;
const deleteDealer = id => (0, _request.default)({
  url: `/dealers/${id}`,
  method: 'delete'
});
exports.deleteDealer = deleteDealer;
const createDealer = data => (0, _request.default)({
  url: '/dealers',
  method: 'post',
  data
});
exports.createDealer = createDealer;
const getDealerCities = data => (0, _request.default)({
  url: `/dealers/dealerCities`,
  method: 'post',
  data
});
exports.getDealerCities = getDealerCities;
const getAgentDealers = data => (0, _request.default)({
  url: `/dealers/agent-dealers`,
  method: 'post',
  data
});
exports.getAgentDealers = getAgentDealers;
const downloadExcel = data => (0, _request.default)({
  url: '/dealers/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;
const dealersCount = params => (0, _request.default)({
  url: '/dealers/count',
  method: 'get',
  params
});
exports.dealersCount = dealersCount;
const searchDealers = params => (0, _request.default)({
  url: '/dealers/search',
  method: 'get',
  params
});
exports.searchDealers = searchDealers;
const getDealerHoldInfo = id => (0, _request.default)({
  url: `/dealers/hold-info/${id}`,
  method: 'get'
});
exports.getDealerHoldInfo = getDealerHoldInfo;