"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "login-container"
  }, [_vm._m(0), _c("el-form", {
    ref: "deleteForm",
    staticClass: "login-form",
    attrs: {
      model: _vm.deleteForm,
      rules: _vm.formRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "title-container"
  }, [_c("h2", {
    staticClass: "title"
  }, [_vm._v(" Account Delete ")])]), _c("el-form-item", {
    attrs: {
      prop: "fullName"
    }
  }, [_c("i", {
    staticClass: "el-icon-user svg-container"
  }), _c("el-input", {
    ref: "fullName",
    attrs: {
      placeholder: "Enter full name"
    },
    model: {
      value: _vm.deleteForm.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.deleteForm, "fullName", $$v);
      },
      expression: "deleteForm.fullName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "mobileNumber"
    }
  }, [_c("i", {
    staticClass: "el-icon-mobile-phone svg-container"
  }), _c("el-input", {
    ref: "mobileNumber",
    attrs: {
      placeholder: "Enter your mobile number"
    },
    model: {
      value: _vm.deleteForm.mobileNumber,
      callback: function ($$v) {
        _vm.$set(_vm.deleteForm, "mobileNumber", $$v);
      },
      expression: "deleteForm.mobileNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "email"
    }
  }, [_c("i", {
    staticClass: "el-icon-message svg-container"
  }), _c("el-input", {
    ref: "email",
    attrs: {
      placeholder: "Enter your email"
    },
    model: {
      value: _vm.deleteForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.deleteForm, "email", $$v);
      },
      expression: "deleteForm.email"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "comments"
    }
  }, [_c("el-input", {
    ref: "comments",
    staticStyle: {
      "background-color": "grey !important"
    },
    attrs: {
      placeholder: "Enter reason",
      type: "textarea"
    },
    model: {
      value: _vm.deleteForm.comments,
      callback: function ($$v) {
        _vm.$set(_vm.deleteForm, "comments", $$v);
      },
      expression: "deleteForm.comments"
    }
  })], 1), _c("el-button", {
    staticStyle: {
      width: "40%",
      "margin-left": "150px",
      "margin-bottom": "30px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.confirm")) + " ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    staticClass: "login-logo",
    attrs: {
      src: require("@/assets/img/logo.png")
    }
  })]);
}];
render._withStripped = true;