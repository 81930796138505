"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _ordersProductsDispatches = require("@/api/orders-products-dispatches");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _dealers = require("@/api/dealers");
var _admin = require("@/store/modules/admin");
var _cities = require("@/api/locations/cities");
var _index4 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _products = require("@/api/products");
var _agents = require("@/api/agents");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = false;
    this.downloadLoading = false;
    this.dateRange = {
      start: "",
      end: ""
    };
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.unitManagers = [];
    this.unitList = [];
    this.dealerList = [];
    this.agentList = [];
    this.stateList = [];
    this.cityList = [];
    this.products = [];
    this.selectedVariationId = null;
    this.dealerListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.productListLoading = false;
    this.selectedProduct = null;
    this.grandTotalCartons = "";
    this.totalCartonArray = [];
    this.statusOptions = ["Pending", "Request Approval", "Approved", "Rejected"];
    this.statusColorMap = {
      Pending: "warning",
      "Request Approval": "primary",
      Approved: "success",
      Rejected: "danger"
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      selectedVariationId: undefined,
      adminId: undefined,
      agentId: undefined,
      productId: undefined,
      productVariationId: undefined,
      cityName: undefined,
      status: "Approved",
      startDate: undefined,
      endDate: undefined,
      sort: "id,ASC"
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.loggedInAdmin = null;
    // getSummaries(param: any) {
    //   const { columns, data } = param;
    //   const sums: any = [];
    //   columns.forEach((column: any, index: any) => {
    //     if (index === 15) {
    //       let totalCartons: any = [];
    //       this.totalCartonArray.forEach((item) => {
    //         totalCartons.push(item.totalCarton);
    //       });
    //       const values = totalCartons;
    //       // loop over & null check
    //       if (!values.every((value: any) => isNaN(value))) {
    //         // sum them using reduce
    //         sums[index] = values.reduce((prev: any, curr: any) => {
    //           // value conversion (do check with Sandesh if toFixed something else required for decimal precision)
    //           const value = Number(curr);
    //           // null check
    //           if (!isNaN(value)) {
    //             return prev + curr;
    //           } else {
    //             return prev;
    //           }
    //         }, 0); // initial value
    //       } // loop if ends
    //     }
    //     if (index === 15) {
    //       sums[15] = `${sums[15].toFixed(2)}/${this.grandTotalCartons.toFixed(
    //         2
    //       )}`;
    //     }
    //   });
    //   return [, , , , , , , , , , , , , , , sums];
    // }
  }
  activated() {
    if (_admin.AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    this.loggedInAdmin = _admin.AdminModule;
    // check if logged in user is Agent/Admin
    if (this.loggedInAdmin.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = this.loggedInAdmin.agentId;
    }
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    // get dispatch list
    this.getList();
    this.fetchAgentList();
    // this.getStateList();
  }
  async getList() {
    try {
      if (this.dateRange) {
        this.listQuery.startDate = this.dateRange.start;
        this.listQuery.endDate = this.dateRange.end;
      }
      const data = await (0, _ordersProductsDispatches.getItemWiseReports)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getListWithoutPagination() {
    const queryWithoutPagination = {
      ...this.listQuery
    };
    delete queryWithoutPagination.page;
    delete queryWithoutPagination.limit;
    const data = await (0, _ordersProductsDispatches.getAllData)((0, _index2.getQuery)(queryWithoutPagination));
    return data;
  }
  formatDiscount(value) {
    return value === null ? 0 : value;
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchAgentList() {
    try {
      this.agentList = await (0, _agents.getAgents)((0, _index2.getQuery)({
        enable: true,
        sort: "name,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  // private async getStateList(query: string = null) {
  //   try {
  //     this.stateList = await getStates(
  //       getQuery({
  //         enabled: true,
  //         sort: "name,ASC",
  //         filter: {
  //           enabled: "eq",
  //         },
  //       })
  //     );
  //   } catch (error) {} // eslint-disable-line  no-empty
  // }
  async getCityList(query = null) {
    try {
      if (query && query.length > 2) {
        this.cityListLoading = true;
        this.cityList = await (0, _cities.getCities)((0, _index2.getQuery)({
          name: query,
          filter: {
            name: "$contL"
          }
        }));
      }
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getProductList(query) {
    try {
      this.productListLoading = true;
      if (query && query.length > 2) {
        this.products = await (0, _products.searchProducts)((0, _index2.getQuery)({
          keyword: query
        }));
      }
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  handleProductSelection(value) {
    if (value) {
      if (value.startsWith("product-")) {
        this.listQuery.productId = +value.replace("product-", "");
        this.listQuery.productVariationId = undefined;
      } else if (value.startsWith("variation-")) {
        this.listQuery.productId = undefined;
        this.listQuery.productVariationId = +value.replace("variation-", "");
      }
    } else {
      // Clear the product filters when value is null or empty
      this.listQuery.productId = undefined;
      this.listQuery.productVariationId = undefined;
    }
    this.handleFilter();
  }
  handleFilter() {
    if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      this.listQuery.startDate = this.dateRange.start;
      this.listQuery.endDate = this.dateRange.end;
    } else {
      this.listQuery.startDate = undefined;
      this.listQuery.endDate = undefined;
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async downloadInvoice(id = 0) {
    // get invoice url
    this.listLoading = true;
    const url = await (0, _ordersDispatches.generateInvoice)(id);
    if (url) {
      window.open(url, "_blank");
      // const filename = "invoice_" + id + ".pdf";
      // this.downloadFile(data.url, filename);
    } else {
      this.$message({
        message: "Failed to generate invoice",
        type: "error"
      });
    }
    this.listLoading = false;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      let startDate = "";
      let endDate = "";
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      if (this.listQuery.startDate && this.listQuery.endDate) {
        startDate = this.listQuery.startDate;
        endDate = this.listQuery.endDate;
      }
      // let datas: any = await this.getListWithoutPagination();
      // let data = datas.data;
      const blob = await (0, _ordersProductsDispatches.downloadExcel)({
        query,
        startDate,
        endDate
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "item-wise-report.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  getVisibleColumns() {
    const table = this.$refs.table;
    const visibleColumns = table.columns.filter(column => !column.hidden);
    return visibleColumns;
  }
  async handleCSVDownload() {
    this.downloadLoading = true;
    try {
      // Call the downloadCSV function to get the Blob object
      const blob = await (0, _ordersProductsDispatches.downloadCSV)(this.list);
      // Create URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Create download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "item-wise-report.csv");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      this.$message.error("Error downloading CSV");
    } finally {
      this.downloadLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "SalesReportList",
  components: {
    Pagination: _index.default,
    Inr: _index3.default,
    Enabled: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;