"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocale = exports.default = exports.SetLanguageDirection = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _cookies = require("@/utils/cookies");
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _en2 = _interopRequireDefault(require("./en"));
// element-ui built-in lang

// User defined lang

_vue.default.use(_vueI18n.default);
const messages = {
  en: {
    ..._en2.default,
    ..._en.default
  }
};
const getLocale = () => {
  const cookieLanguage = (0, _cookies.getLanguage)();
  if (cookieLanguage) {
    SetLanguageDirection(cookieLanguage);
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  // Default language is english
  return 'en';
};
exports.getLocale = getLocale;
const SetLanguageDirection = (language = '') => {
  const body = document.querySelector('body');
  if (body) {
    if (language === 'ar') {
      if (!body.classList.contains('rtl')) {
        body.classList.add('rtl');
      }
    } else {
      body.classList.remove('rtl');
    }
  }
};
exports.SetLanguageDirection = SetLanguageDirection;
const i18n = new _vueI18n.default({
  locale: getLocale(),
  messages
});
var _default = exports.default = i18n;