"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _common = require("@/api/common");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.progressPercent = 0;
    this.progressColors = [{
      color: '#f56c6c',
      percentage: 20
    }, {
      color: '#e6a23c',
      percentage: 40
    }, {
      color: '#6f7ad3',
      percentage: 60
    }, {
      color: '#1989fa',
      percentage: 80
    }, {
      color: '#5cb87a',
      percentage: 90
    }, {
      color: '#13ce66',
      percentage: 100
    }];
  }
  mounted() {}
  afterRemove(file, fileList) {
    this.$emit('remove', file);
  }
  beforeRemove(file, fileList) {
    if (this.multiple) {
      return true;
    }
    return fileList.length > 1; // for single file
  }
  async beforeFileUpload(file) {
    const extenstion = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
    const acceptable = this.accept.split(',').map(function (accept) {
      return accept.trim();
    });
    const isAllowedType = acceptable.indexOf(extenstion) > -1;
    const isLessThanMaxSize = file.size / 1024 / 1024 < this.maxSize;
    if (!isAllowedType) {
      this.$message.error('file type not permitted!');
    }
    if (!isLessThanMaxSize) {
      this.$message.error('Icon size can not exceed ' + this.maxSize + 'MB!');
    }
    if (isAllowedType && isLessThanMaxSize) {
      // checking for height and width of images
      if (file.type.indexOf('image') === 0 && (this.width || this.height)) {
        this.checkImageDimension(file);
      } else {
        return true;
      }
    }
    return false;
  }
  checkImageDimension(file) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      // Set the Base64 string return from FileReader as source.
      const _URL = window.URL || window.webkitURL;
      const imageUrl = _URL.createObjectURL(file);
      const widthToBeChecked = this.width;
      const heightToBeChecked = this.height;
      // Validate the File Height and Width.
      image.onload = function () {
        const width = image.naturalWidth || image.width;
        const height = image.naturalHeight || image.height;
        if (widthToBeChecked && width === widthToBeChecked && heightToBeChecked && height === heightToBeChecked) {
          resolve(true);
        } else {
          reject(new Error('Image should be of resolution ' + widthToBeChecked + ' x ' + heightToBeChecked + ' px'));
        }
      };
      image.src = imageUrl;
    });
  }
  onFileTransferError(err, file, fileList) {
    this.progressPercent = 0;
    this.$message.error(err);
  }
  onFileTransferSuccess(response, file, fileList) {
    this.fileList.push({
      url: file.response,
      name: file.name
    });
    // if single file then we remove other file
    if (!this.multiple && this.fileList.length > 1) {
      this.fileList.shift();
    }
    // send change
    this.$emit('change', file);
  }
  uploadFile(file) {
    const data = new FormData();
    data.append(this.field, file.file);
    return (0, _common.uploadFile)(data, this.url, event => {
      this.doProgressEvent(event);
    });
  }
  onProgress(event, file, fileList) {
    this.doProgressEvent(event);
  }
  doProgressEvent(event) {
    if (event.lengthComputable) {
      const loaded = 100 * event.loaded / event.total;
      this.progressPercent = Math.round(loaded * 100) / 100;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '/common/upload'
})], default_1.prototype, "url", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'file'
})], default_1.prototype, "field", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '.jpg, .jpeg, .png, .pdf'
})], default_1.prototype, "accept", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "multiple", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "disabled", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "drag", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 5
})], default_1.prototype, "maxSize", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'picture'
})], default_1.prototype, "listType", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "description", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "height", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: []
})], default_1.prototype, "fileList", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'FileUpload'
})], default_1);
var _default = exports.default = default_1;