"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.fileExtensionMapping = {
      pdf: 'pdf',
      xlsx: 'xls',
      xsx: 'xls',
      doc: 'document',
      docx: 'document',
      txt: 'txt',
      csv: 'csv',
      mp4: 'mp4'
    };
  }
  getFileIcon() {
    if (this.src) {
      const extenstion = this.src.split('.').pop();
      if (extenstion && extenstion in this.fileExtensionMapping) {
        return this.fileExtensionMapping[extenstion];
      }
    }
    return 'error';
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)()], default_1.prototype, "src", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "shape", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "fit", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: null
})], default_1.prototype, "size", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Thumb'
})], default_1);
var _default = exports.default = default_1;