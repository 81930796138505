"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SettingsModule = void 0;
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _store = _interopRequireDefault(require("@/store"));
var _elementVariables = _interopRequireDefault(require("@/styles/element-variables.scss"));
var _settings = _interopRequireDefault(require("@/settings"));
let Settings = class Settings extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.theme = _elementVariables.default.theme;
    this.title = _settings.default.title;
    this.fixedHeader = _settings.default.fixedHeader;
    this.showSettings = _settings.default.showSettings;
    this.showTagsView = _settings.default.showTagsView;
    this.showSidebarLogo = _settings.default.showSidebarLogo;
    this.sidebarTextTheme = _settings.default.sidebarTextTheme;
  }
  CHANGE_SETTING(payload) {
    const {
      key,
      value
    } = payload;
    if (Object.prototype.hasOwnProperty.call(this, key)) {
      this[key] = value;
    }
  }
  ChangeSetting(payload) {
    this.CHANGE_SETTING(payload);
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Settings.prototype, "CHANGE_SETTING", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Settings.prototype, "ChangeSetting", null);
Settings = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'settings'
})], Settings);
const SettingsModule = exports.SettingsModule = (0, _vuexModuleDecorators.getModule)(Settings);