"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schemesCategoriesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const schemesCategoriesSubMenu = exports.schemesCategoriesSubMenu = [{
  path: '/schemes-categories/list/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes-categories/list.vue'))),
  name: 'productManagement.schemesCategories.title',
  meta: {
    title: 'productManagement.schemesCategories.title',
    roles: ['Super Admin', 'Admin', 'Read Only'],
    noCache: true,
    icon: 'tree'
  }
}, {
  path: '/schemes-categories/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes-categories/manage.vue'))),
  name: 'productManagement.schemesCategories.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.schemesCategories.manage',
    noCache: true,
    activeMenu: '/schemes-categories/list',
    hidden: true
  }
}, {
  path: '/schemes-categories/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes-categories/manage.vue'))),
  name: 'productManagement.schemesCategories.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.schemesCategories.manage',
    noCache: true,
    activeMenu: '/schemes-categories/list',
    hidden: true
  }
}];