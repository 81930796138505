"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tooltip", {
    staticClass: "sub-title",
    attrs: {
      effect: "dark",
      content: _vm.content,
      placement: _vm.placement
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      icon: "el-icon-question"
    }
  }, [_vm._v("   ")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;