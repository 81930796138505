"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-card", [_c("el-row", {
    staticClass: "row-spacing"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Name:")])]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" " + _vm._s(_vm.isAgent ? _vm.agentData.name : _vm.adminData.fullName) + " ")])], 1), _c("el-row", {
    staticClass: "row-spacing"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Email:")])]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" " + _vm._s(_vm.isAgent ? _vm.agentData.agentUser[0].emailAddress : _vm.adminData.emailAddress) + " ")])], 1), _c("el-row", {
    staticClass: "row-spacing"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Mobile Number:")])]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" " + _vm._s(_vm.isAgent ? _vm.agentData.agentUser[0].mobileNumber : _vm.adminData.mobileNumber) + " ")])], 1), !_vm.isAgent ? _c("el-row", {
    staticClass: "row-spacing"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Admin Role:")])]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" " + _vm._s(_vm.adminData.adminsRole.name) + " ")])], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;