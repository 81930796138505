"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-container"
  }, [_c("el-button", {
    style: {
      background: _vm.color,
      borderColor: _vm.color
    },
    attrs: {
      icon: "el-icon-upload",
      size: "mini",
      type: "primary"
    },
    on: {
      click: true
    }
  }, [_vm._v(" upload ")]), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-upload", {
    staticClass: "editor-slide-upload",
    attrs: {
      multiple: true,
      "file-list": _vm.defaultFileList,
      "show-file-list": true,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.handleSuccess,
      "before-upload": _vm.beforeUpload,
      action: "https://httpbin.org/post",
      "list-type": "picture-card"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(" Click upload ")])], 1), _c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" Confirm ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;