"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _utils = require("@/utils");
var _attributes = require("@/api/attributes");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
var _admin = require("@/store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
    this.attributes = [];
    this.selectedAttributes = []; // remove this with formData
    this.isReadOnly = false;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  activated() {
    if (_admin.AdminModule.roles[0] === 'Read Only') {
      this.isReadOnly = true;
    }
    this.getAttributes();
    // set tree nodes
    if (this.formData.attribute && this.formData.attribute.length) {
      this.selectedAttributes = this.formData.attribute.map(attribute => attribute.id);
      this.$refs.tree.setCheckedKeys(this.selectedAttributes);
    }
  }
  emitFormData() {
    this.selectedAttributes = this.$refs.tree.getCheckedKeys();
    this.$emit('updated', this.formData);
  }
  async getAttributes() {
    try {
      this.attributes = await (0, _attributes.getAttributes)((0, _utils.getQuery)({
        enabled: true,
        id: 1,
        filter: {
          enabled: 'eq',
          id: 'gt'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async saveForm() {
    this.loading = true;
    try {
      const attributesToSave = {
        attribute: []
      };
      this.selectedAttributes.forEach(id => {
        attributesToSave.attribute.push({
          id: id
        });
      });
      await (0, _products.updateProductAttributes)(this.formData.id, attributesToSave);
      this.$notify({
        title: 'Success',
        message: 'Attributes saved',
        type: 'success',
        duration: 2000
      });
      this.$emit('reload', {});
    } catch (err) {
      this.$notify.error(err.toString());
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductAttributeTab',
  components: {
    HelpText: _index.default
  }
})], default_1);
var _default = exports.default = default_1;