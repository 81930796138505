"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _settings = require("@/store/modules/settings");
var _index = _interopRequireDefault(require("@/components/ThemePicker/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  get fixedHeader() {
    return _settings.SettingsModule.fixedHeader;
  }
  set fixedHeader(value) {
    _settings.SettingsModule.ChangeSetting({
      key: 'fixedHeader',
      value
    });
  }
  get showTagsView() {
    return _settings.SettingsModule.showTagsView;
  }
  set showTagsView(value) {
    _settings.SettingsModule.ChangeSetting({
      key: 'showTagsView',
      value
    });
  }
  get showSidebarLogo() {
    return _settings.SettingsModule.showSidebarLogo;
  }
  set showSidebarLogo(value) {
    _settings.SettingsModule.ChangeSetting({
      key: 'showSidebarLogo',
      value
    });
  }
  get showSidebarTitle() {
    return _settings.SettingsModule.title;
  }
  set showSidebarTitle(value) {
    _settings.SettingsModule.ChangeSetting({
      key: 'title',
      value
    });
  }
  get sidebarTextTheme() {
    return _settings.SettingsModule.sidebarTextTheme;
  }
  set sidebarTextTheme(value) {
    _settings.SettingsModule.ChangeSetting({
      key: 'sidebarTextTheme',
      value
    });
  }
  themeChange(value) {
    _settings.SettingsModule.ChangeSetting({
      key: 'theme',
      value
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Settings',
  components: {
    ThemePicker: _index.default
  }
})], default_1);
var _default = exports.default = default_1;