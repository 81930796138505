"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dealer",
      prop: "dealerId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: _vm.isEdit,
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: "Search Dealer"
    },
    model: {
      value: _vm.formData.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealerId", $$v);
      },
      expression: "formData.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1)], 1), !_vm.isEdit ? _c("el-form-item", {
    attrs: {
      label: "Payment Type",
      prop: "paymentType"
    }
  }, [_c("el-select", {
    attrs: {
      name: "paymentType",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.paymentType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "paymentType", $$v);
      },
      expression: "formData.paymentType"
    }
  }, _vm._l(_vm.paymentTypeOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Invoice Number"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: _vm.isEdit || !this.formData.dealerId,
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.entryNumberListLoading,
      "loading-text": "fetching invoices..",
      "remote-method": _vm.getEntryNumberList,
      placeholder: "Search Invoice Number"
    },
    on: {
      change: _vm.handleEntryNumber
    },
    model: {
      value: _vm.formData.ordersDispatchId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "ordersDispatchId", $$v);
      },
      expression: "formData.ordersDispatchId"
    }
  }, _vm._l(_vm.entryNumbers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.invoiceNumber,
        value: item.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(item.invoiceNumber))])]);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Select Product",
      prop: "product"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      loading: _vm.productListLoading,
      placeholder: "Select Product",
      disabled: !this.formData.ordersDispatchId
    },
    on: {
      change: _vm.handleProductSelection
    },
    model: {
      value: _vm.selectedProduct,
      callback: function ($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  }, _vm._l(_vm.products, function (item) {
    return _c("el-option", {
      key: item.productsVariationId,
      attrs: {
        label: item.productName,
        value: item.productsVariationId
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(item.productName))])]);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Quantity"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !this.formData.productsVariationId
    },
    on: {
      change: _vm.handleQuantity
    },
    model: {
      value: _vm.formData.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "quantity", $$v);
      },
      expression: "formData.quantity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Amount",
      prop: "amount"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.formData.amount,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Payment Date"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "Select date"
    },
    model: {
      value: _vm.formData.createdTimestamp,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "createdTimestamp", $$v);
      },
      expression: "formData.createdTimestamp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "comments"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3
    },
    model: {
      value: _vm.formData.comments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;