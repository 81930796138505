"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/products-images/upload",
      drag: true,
      "file-list": [],
      accept: ".jpg, .jpeg, .png",
      description: "(Image should be of resolution 1080 x 1080 px)",
      width: "1080",
      height: "1080"
    },
    on: {
      change: _vm.fileUploaded
    }
  }), _c("el-divider"), _c("el-table", {
    ref: "dragTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.formData.productsImage,
      "row-key": "id",
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "Drag"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("i", {
          staticClass: "el-icon-rank drag-icon",
          attrs: {
            "data-index": scope.$index
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "Priority",
      prop: "priority"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Cover",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-switch", {
          attrs: {
            disabled: scope.row.isCover ? true : false,
            value: scope.row.isCover,
            "validate-event": false
          },
          on: {
            change: function ($event) {
              return _vm.handleCoverChange(scope.$index);
            }
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Image",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-image", {
          staticStyle: {
            width: "100px",
            height: "100px"
          },
          attrs: {
            src: scope.row.image.concat("-sm"),
            "preview-src-list": [scope.row.image],
            fit: "scale-down",
            lazy: ""
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.actions"),
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "danger",
            icon: "el-icon-delete"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row, scope.$index);
            }
          }
        })];
      }
    }])
  })], 1), _c("br"), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;