"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _products = require("@/api/products");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _VariationPrice = _interopRequireDefault(require("./components/VariationPrice.vue"));
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _ranges = require("@/api/ranges");
var _categories = require("@/api/categories");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.category = "";
    this.rangeList = [];
    this.isReadOnly = false;
    this.categoryList = [];
    this.subCategoryList = [];
    this.categoryId = null;
    this.subCategoryId = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      enabled: true,
      name: undefined,
      catalogueCode: undefined,
      featured: undefined,
      rangeId: undefined,
      "category.id": undefined,
      sort: "id,DESC",
      filter: {
        name: "$contL",
        catalogueCode: "$contL",
        enabled: "$eq",
        featured: "$eq",
        rangeId: "$eq",
        "category.id": "$eq"
      }
    };
    this.settingImageFromS3 = false;
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
  }
  activated() {
    if (_admin.AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    this.getList();
    // fetch range
    this.fetchRanges();
  }
  async getList() {
    try {
      this.listLoading = true;
      this.listQuery["category.id"] = undefined;
      if (this.categoryId) {
        this.listQuery["category.id"] = this.categoryId;
      }
      if (this.subCategoryId) {
        this.listQuery["category.id"] = this.subCategoryId;
      }
      const data = await (0, _products.getProducts)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  handleCategoryFilter() {
    this.categoryId = undefined;
    this.subCategoryId = undefined;
    this.fetchCategories();
    this.getList();
  }
  handleSubCategoryFilter() {
    this.subCategoryId = undefined;
    if (this.categoryId) {
      this.fetchSubCategories();
    } else {
      this.categoryId = undefined;
    }
    this.getList();
  }
  async fetchRanges() {
    this.rangeList = await (0, _ranges.getRanges)((0, _index2.getQuery)({
      enabled: true,
      sort: "name,ASC",
      filter: {
        enabled: "$eq"
      }
    }));
  }
  async fetchCategories() {
    if (this.listQuery.rangeId) {
      this.categoryList = await (0, _categories.getCategories)((0, _index2.getQuery)({
        enabled: true,
        parentId: true,
        rangeId: this.listQuery.rangeId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          parentId: "$isnull",
          rangeId: "$eq"
        }
      }));
      if (this.categoryList.length === 0) {
        this.categoryId = undefined;
      }
    } else {
      this.categoryList = [];
    }
  }
  async fetchSubCategories() {
    if (this.categoryId) {
      this.subCategoryList = await (0, _categories.getCategories)((0, _index2.getQuery)({
        enabled: true,
        parentId: this.categoryId,
        rangeId: this.listQuery.rangeId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          parentId: "$eq",
          rangeId: "$eq"
        }
      }));
      if (this.subCategoryList.length === 0) {
        this.subCategoryId = undefined;
      }
    } else {
      this.subCategoryList = [];
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  async handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Product Name", "Type", "Description", "HSN code"];
    const filterVal = ["id", "name", "productType", "description", "hsnCode"];
    const productFilter = Object.assign({}, this.listQuery);
    delete productFilter.limit;
    delete productFilter.page;
    const data2 = await (0, _products.getProducts)((0, _index2.getQuery)(productFilter));
    const data = (0, _index2.formatJson)(filterVal, data2);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.productManagement.product.title"));
    this.downloadLoading = false;
  }
  isSuperAdmin() {
    // @Todo: Need to change this to id basis.
    return ["Super Admin"].includes(_admin.AdminModule.roles.toString());
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      const blob = await (0, _products.downloadExcel)(queryWithoutPagination);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "products.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async setImageFromS3(id) {
    try {
      this.settingImageFromS3 = true;
      await (0, _products.setImageFromS3)({
        id
      });
      // refresh the list
      await this.getList();
    } catch (error) {
      console.error("Error setting image from S3:", error);
      this.$message.error("Error setting image from S3");
    } finally {
      // Reset loading state
      this.settingImageFromS3 = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "ProductList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default,
    VariationPrice: _VariationPrice.default
  }
})], default_1);
var _default = exports.default = default_1;