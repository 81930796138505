"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-container"
  }, [_c("el-upload", {
    staticClass: "upload-box",
    attrs: {
      accept: _vm.accept,
      action: "",
      "http-request": _vm.uploadFile,
      "before-remove": _vm.beforeRemove,
      "before-upload": _vm.beforeFileUpload,
      "on-remove": _vm.afterRemove,
      "on-error": _vm.onFileTransferError,
      "on-success": _vm.onFileTransferSuccess,
      "on-progress": _vm.onProgress,
      "file-list": _vm.fileList,
      "list-type": _vm.listType,
      multiple: _vm.multiple,
      disabled: _vm.disabled,
      drag: _vm.drag
    }
  }, [_vm.drag ? [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v(" Drop file here or "), _c("em", [_vm._v("click to upload")])])] : _vm.listType === "picture-card" ? _c("i", {
    staticClass: "el-icon-plus"
  }) : _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(" Click to Upload ")]), _vm.description ? _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")]) : _vm._e(), _vm.progressPercent > 0 && _vm.progressPercent < 100 ? _c("el-progress", {
    attrs: {
      percentage: _vm.progressPercent,
      color: _vm.progressColors
    }
  }) : _vm._e()], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;