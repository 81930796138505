"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateDealersTransporter = exports.getDealersTransporters = exports.getDealersTransporterById = exports.deleteDealersTransporter = exports.defaultDealersTransporterData = exports.createDealersTransporter = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultDealersTransporterData = exports.defaultDealersTransporterData = {
  id: 0,
  enabled: true,
  isPrimary: false,
  freightPerCarton: 0,
  transporterId: null,
  transporter: {
    id: null
  },
  dealerId: 0,
  dealer: {
    id: null
  }
};
const getDealersTransporters = params => (0, _request.default)({
  url: '/dealers-transporters',
  method: 'get',
  params
});
exports.getDealersTransporters = getDealersTransporters;
const getDealersTransporterById = id => (0, _request.default)({
  url: `/dealers-transporters/${id}`,
  method: 'get'
});
exports.getDealersTransporterById = getDealersTransporterById;
const updateDealersTransporter = (id, data) => (0, _request.default)({
  url: `/dealers-transporters/${id}`,
  method: 'patch',
  data
});
exports.updateDealersTransporter = updateDealersTransporter;
const deleteDealersTransporter = id => (0, _request.default)({
  url: `/dealers-transporters/${id}`,
  method: 'delete'
});
exports.deleteDealersTransporter = deleteDealersTransporter;
const createDealersTransporter = data => (0, _request.default)({
  url: '/dealers-transporters/',
  method: 'post',
  data
});
exports.createDealersTransporter = createDealersTransporter;