// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.full-mode .el-menu-item.is-active {\n  background-color: #906a35 !important;\n  font-weight: 700 !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title .el-menu-item,\n.full-mode .el-submenu .el-menu-item {\n  min-width: 250px !important;\n  background-color: #c69f67 !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title .el-menu-item.is-active,\n.full-mode .el-submenu .el-menu-item.is-active {\n  background-color: #9b7849 !important;\n  font-weight: 700 !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title .el-menu-item:hover,\n.full-mode .el-submenu .el-menu-item:hover {\n  background-color: #b5833d !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title .el-submenu__title .el-submenu__icon-arrow,\n.full-mode .el-submenu .el-submenu__title .el-submenu__icon-arrow {\n  color: white !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title.is-active .el-submenu__title,\n.full-mode .el-submenu.is-active .el-submenu__title {\n  background-color: #906a35 !important;\n  font-weight: 700 !important;\n  color: #FFE164 !important;\n}\n.simple-mode.first-level .submenu-title-noDropdown {\n  padding: 0 !important;\n  position: relative;\n}\n.simple-mode.first-level .submenu-title-noDropdown .el-tooltip {\n  padding: 0 !important;\n}\n.simple-mode.first-level .el-submenu {\n  overflow: hidden;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title {\n  padding: 0px !important;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title .el-submenu__icon-arrow {\n  display: none;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title > span {\n  visibility: hidden;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#B58643",
	"menuText": "#e0e0e0",
	"menuActiveText": "#FFE164",
	"menuActiveBg": "#906a35"
};
module.exports = exports;
