"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block",
    staticStyle: {
      width: "80%"
    }
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Unit"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.data.unit.name,
      callback: function ($$v) {
        _vm.$set(_vm.data.unit, "name", $$v);
      },
      expression: "data.unit.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Product"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.data.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.data.product, "name", $$v);
      },
      expression: "data.product.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Variant"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.data.productsVariation.name,
      callback: function ($$v) {
        _vm.$set(_vm.data.productsVariation, "name", $$v);
      },
      expression: "data.productsVariation.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Quantity To Remove",
      prop: "quantity"
    }
  }, [_c("el-input-number", {
    attrs: {
      size: "mini",
      min: 1
    },
    model: {
      value: _vm.formData.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "quantity", $$v);
      },
      expression: "formData.quantity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Comments",
      prop: "comments"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autocomplete: "off",
      rows: 5,
      placeholder: "reason for removing quantity.."
    },
    model: {
      value: _vm.formData.comments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;