"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentsUserSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const agentsUserSubMenu = exports.agentsUserSubMenu = [{
  path: '/agents-users/list/:agentId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/agents-users/list.vue'))),
  name: 'dealerManagement.agentsUser.title',
  meta: {
    title: 'dealerManagement.agentsUser.title',
    icon: 'user',
    noCache: true,
    hidden: true
  }
}, {
  path: '/agents-users/add/:agentId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/agents-users/manage.vue'))),
  name: 'dealerManagement.agentsUsers.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'dealerManagement.agentsUser.add',
    noCache: true,
    activeMenu: '/agents-users/list/:agentId(\\d+)',
    hidden: true
  }
}, {
  path: '/agents-users/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/agents-users/manage.vue'))),
  name: 'dealerManagement.agentsUser.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'dealerManagement.agentsUser.edit',
    noCache: true,
    activeMenu: '/agents-users/list/:agentId(\\d+)',
    hidden: true
  }
}];