import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CountTo from 'vue-count-to';
import { dealersCount } from '@/api/dealers';
import { agentsCount } from '@/api/agents';
import { productCount } from '@/api/products';
import { getStatusCount } from '@/api/order';
import { ordersTicketsCount } from '@/api/orders-tickets';
import { getQuery } from '@/utils/index';
import { AdminModule } from '@/store/modules/admin';
import { getDispatchCount } from '@/api/orders-dispatches';
import Inr from '@/components/Inr/index.vue';
import { topSellingProducts, topDealers, totalOutstanding, totalCollection, totalFySales, totalFyPayment, totalCurrentRecievable, totalPendingOrders, getCurrentMonthSales, getCurrentMonthPayments, getPreviousMonthPayments, getPreviousMonthSales, getDailyOrderValue, getOverdueByDays, getCurrentMonthOrderValue } from '@/api/dashboard';
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.supportTicketCount = [];
    this.orderStatus = [{
      status: 'Order Confirmed',
      color: 'blue',
      count: 0
    }, {
      status: 'Partial Dispatch',
      color: 'info',
      count: 0
    }, {
      status: 'Order Dispatched',
      color: 'info',
      count: 0
    }, {
      status: 'Order Delivered',
      color: 'success',
      count: 0
    }, {
      status: 'Order Cancelled',
      color: '',
      count: 0
    }, {
      status: 'Order Partial Cancelled',
      color: '',
      count: 0
    }];
    this.dispatchStatus = [{
      status: 'Pending',
      color: 'warning',
      count: 0
    }, {
      status: 'Request Approval',
      color: 'warning',
      count: 0
    }, {
      status: 'Approved',
      color: 'success',
      count: 0
    }, {
      status: 'Rejected',
      color: 'danger',
      count: 0
    }];
    this.dealerCount = 0;
    this.agentCount = 0;
    this.productscount = 0;
    this.orderscount = 0;
    this.totalOutstandingAmount = 0;
    this.totalAmountReceived = 0;
    this.totalFySales = 0;
    this.totalFyPayment = 0;
    this.currentRecievableAmount = 0;
    this.totalPendingOrders = 0;
    this.currentMonthSales = 0;
    this.currentMonthPayments = 0;
    this.previousMonthSales = 0;
    this.previousMonthPayments = 0;
    this.dailyOrderValue = 0;
    this.topProducts = [];
    this.topDealers = [];
    this.dateRange = '';
    this.superAdmin = false;
    this.isAgent = false;
    this.agentId = undefined;
    this.currentDateRange = '';
    this.overdueAmount30days = 0;
    this.overdueAmount60days = 0;
    this.overdueAmount90days = 0;
    this.overdueAmount120days = 0;
    this.currentMonthOrderValue = 0;
    this.currentMonthOrderCount = 0;
    this.currentMonthDate = '';
    this.previousMonthDate = '';
  }
  activated() {
    if (AdminModule.roles[0] === 'Super Admin') {
      this.superAdmin = true;
    }
    if (AdminModule.userType === 'Agent') {
      this.isAgent = true;
      this.agentId = AdminModule.agentId;
    }
    this.currentMonthDate = {
      start: this.$moment().startOf('month').format('YYYY-MM-DD 00:00') + ' am',
      end: this.$moment().endOf('month').format('YYYY-MM-DD 11:59') + ' pm'
    };
    this.previousMonthDate = {
      start: this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD 00:00') + ' am',
      end: this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 11:59') + ' pm'
    };
    this.currentDateRange = {
      start: this.$moment().startOf('day').format('YYYY-MM-DD hh:mm') + ' am',
      end: this.$moment().endOf('day').format('YYYY-MM-DD hh:mm') + ' pm'
    };
    this.getDealerCount();
    this.getProductCount();
    this.getAgentCount();
    this.getOrderStatusCount();
    this.getDispatchStatusCount();
    this.fetchTotalOutstanding();
    this.fetchTotalCollection();
    this.fetchTotalFySales();
    this.fetchTotalFyPayment();
    this.fetchCurrentRecievable();
    this.fetchTotalPendingOrders();
    this.fetchCurrentMonthSales();
    this.fetchCurrentMonthPayments();
    this.fetchPreviousMonthPayments();
    this.fetchPreviousMonthSales();
    this.fetchDailyOrderValue();
    this.fetchSellingTopProducts();
    this.fetchTopDealers();
    this.fetchOverdueByDays();
    this.fetchCurrentMonthOrderValue();
    // this.getSupportTicketCount();
  }
  async getOrderStatusCount() {
    const data = await getStatusCount({
      enabled: true
    });
    this.orderStatus.forEach(item => {
      item.count = data[item.status] * 1;
    });
  }
  async getDispatchStatusCount() {
    const data = await getDispatchCount({
      enabled: true
    });
    this.dispatchStatus.forEach(item => {
      item.count = data[item.status] * 1;
    });
  }
  async fetchTotalOutstanding() {
    const params = {};
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await totalOutstanding(params);
    this.totalOutstandingAmount = +data.outstanding;
  }
  async fetchTotalCollection() {
    const params = {
      limit: 5
    };
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await totalCollection(params);
    this.totalAmountReceived = +data.amountReceived;
  }
  async fetchTotalFySales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await totalFySales(query);
    this.totalFySales = +data.sum;
  }
  async fetchTotalFyPayment() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await totalFyPayment(query);
    this.totalFyPayment = +data;
  }
  async fetchCurrentRecievable() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await totalCurrentRecievable(query);
    this.currentRecievableAmount = data;
  }
  async fetchTotalPendingOrders() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await totalPendingOrders(query);
    this.totalPendingOrders = +data;
  }
  async fetchCurrentMonthSales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await getCurrentMonthSales(query);
    this.currentMonthSales = +data.sum;
  }
  async fetchCurrentMonthPayments() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await getCurrentMonthPayments(query);
    this.currentMonthPayments = +data;
  }
  async fetchPreviousMonthSales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await getPreviousMonthSales(query);
    this.previousMonthSales = +data.previousMonthSales;
  }
  async fetchPreviousMonthPayments() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await getPreviousMonthPayments(query);
    this.previousMonthPayments = +data;
  }
  async fetchDailyOrderValue() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await getDailyOrderValue(query);
    this.dailyOrderValue = +data.total;
  }
  async fetchCurrentMonthOrderValue() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await getCurrentMonthOrderValue(query);
    if (data) {
      this.currentMonthOrderValue = +data.total.currentMonthOrderValue;
      this.currentMonthOrderCount = +data.count;
    }
  }
  async fetchOverdueByDays() {
    let params = {
      days: 30,
      dealerWise: false
    };
    const data30 = await getOverdueByDays(params);
    this.overdueAmount30days = +data30.overdueDealers[0].overdueAmount.toFixed(2);
    params = {
      days: 60,
      dealerWise: false
    };
    const data60 = await getOverdueByDays(params);
    this.overdueAmount60days = +data60.overdueDealers[0].overdueAmount.toFixed(2);
    params = {
      days: 90,
      dealerWise: false
    };
    const data90 = await getOverdueByDays(params);
    this.overdueAmount90days = +data90.overdueDealers[0].overdueAmount.toFixed(2);
    params = {
      days: 120,
      dealerWise: false
    };
    const data120 = await getOverdueByDays(params);
    this.overdueAmount120days = +data120.overdueDealers[0].overdueAmount.toFixed(2);
  }
  async fetchSellingTopProducts() {
    const params = {
      limit: 5
    };
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await topSellingProducts(params);
    this.topProducts = data.data;
  }
  async fetchTopDealers() {
    const params = {
      limit: 5
    };
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await topDealers(params);
    this.topDealers = data.data;
  }
  async getSupportTicketCount() {
    this.supportTicketCount = await ordersTicketsCount({});
  }
  async getDealerCount() {
    try {
      this.dealerCount = await dealersCount(getQuery({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getProductCount() {
    try {
      this.productscount = await productCount(getQuery({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getAgentCount() {
    this.agentCount = await agentsCount(getQuery({
      enabled: true,
      filter: {
        enabled: 'eq'
      }
    }));
  }
  getCurrentMonthDateRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    // Start date: first day of the current month at 12:00 AM
    const startDate = new Date(year, month, 1, 0, 0, 0);
    // End date: last day of the current month at 11:59 PM
    const endDate = new Date(year, month + 1, 0, 23, 59, 59);
    // Formatting the dates as "YYYY-MM-DD hh:mm AM/PM"
    const formatDate = date => {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      const dateStr = date.toLocaleString('en-US', options).replace(',', '');
      // Replace "/" with "-" in the date part
      return dateStr.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2');
    };
    return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    };
  }
  generateReportLink() {
    const currentDateRange = this.getCurrentMonthDateRange();
    const startDate = currentDateRange.startDate;
    const endDate = currentDateRange.endDate;
    return `/report/salesReport/list?startDate=${startDate}&endDate=${endDate}`;
  }
  async dateval() {
    await this.fetchSellingTopProducts();
    await this.fetchTopDealers();
    await this.fetchTotalOutstanding();
    await this.fetchTotalCollection();
  }
  isSuperAdmin() {
    return AdminModule.roles.indexOf('Super Admin') > -1;
  }
};
default_1 = __decorate([Component({
  name: 'PanelGroup',
  components: {
    CountTo,
    Inr
  }
})], default_1);
export default default_1;