"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ledgersSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const ledgersSubMenu = exports.ledgersSubMenu = [{
  path: '/ledgers/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/ledgers/list.vue'))),
  name: 'Ledger',
  meta: {
    roles: ['Super Admin', 'Admin', 'Agent', 'Read Only', 'Account Manager'],
    title: 'orderManagement.ledger.title',
    noCache: true,
    icon: 'chart'
  }
}, {
  path: '/ledgers/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/ledgers/manage.vue'))),
  name: 'orderManagement.ledger.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.ledger.edit',
    noCache: true,
    activeMenu: '/ledgers/list',
    hidden: true
  }
}, {
  path: '/ledgers/add/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/ledgers/manage.vue'))),
  name: 'orderManagement.ledger.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.ledger.add',
    noCache: true,
    activeMenu: '/ledgers/list',
    hidden: true
  }
}, {
  path: '/ledgers/addCnDn/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/ledgers/components/AddCnDn.vue'))),
  name: 'orderManagement.ledger.addCnDn',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.ledger.addCnDn',
    noCache: true,
    activeMenu: '/ledgers/list',
    hidden: true
  }
}];