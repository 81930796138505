"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _taxSubMenu = require("./subMenus/tax-sub-menu");
var _locationSubMenu = require("./subMenus/location-sub-menu");
var _bannerSubMenu = _interopRequireDefault(require("./subMenus/banner-sub-menu"));
var _companiesSubMenu = require("./subMenus/companies-sub-menu");
const masterRouter = {
  path: '/masters',
  component: _index.default,
  redirect: 'noredirect',
  name: 'Masters',
  meta: {
    roles: ['Super Admin', 'Admin', 'Product Manager', 'Read Only'],
    title: 'master.title',
    icon: 'star'
  },
  children: [..._bannerSubMenu.default, ..._taxSubMenu.taxSubMenu, ..._companiesSubMenu.companiesSubMenu, ..._locationSubMenu.locationSubMenu]
};
var _default = exports.default = masterRouter;