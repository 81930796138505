"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMoq = exports.getMoqs = exports.getMoqById = exports.deleteMoq = exports.defaultMoqData = exports.createMoq = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultMoqData = exports.defaultMoqData = {
  id: 0,
  enabled: true,
  name: '',
  startDate: '',
  endDate: '',
  image: null
};
const getMoqs = params => (0, _request.default)({
  url: '/moqs',
  method: 'get',
  params
});
exports.getMoqs = getMoqs;
const getMoqById = id => (0, _request.default)({
  url: `/moqs/${id}`,
  method: 'get'
});
exports.getMoqById = getMoqById;
const updateMoq = (id, data) => (0, _request.default)({
  url: `/moqs/${id}`,
  method: 'patch',
  data
});
exports.updateMoq = updateMoq;
const deleteMoq = id => (0, _request.default)({
  url: `/moqs/${id}`,
  method: 'delete'
});
exports.deleteMoq = deleteMoq;
const createMoq = data => (0, _request.default)({
  url: '/moqs',
  method: 'post',
  data
});
exports.createMoq = createMoq;