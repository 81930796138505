"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMoqsDiscount = exports.manageMoqsDiscount = exports.getMoqsDiscounts = exports.getMoqsDiscountById = exports.downloadExcel = exports.deleteMoqsDiscount = exports.defaultIMoqsDiscountData = exports.createMoqsDiscount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultIMoqsDiscountData = exports.defaultIMoqsDiscountData = {
  id: 0,
  enabled: true,
  discount: 0,
  minimumQuantity: 1,
  moqId: null,
  moq: {
    id: null
  },
  dealersRoleId: null,
  dealersRole: {
    id: null
  },
  categoryId: null,
  category: {
    id: null
  },
  subCategoryId: null,
  subCategory: {
    id: null
  },
  productId: null,
  product: {
    id: null
  },
  productsVariationId: null,
  productsVariation: {
    id: null
  }
};
const getMoqsDiscounts = params => (0, _request.default)({
  url: '/moqs-discounts',
  method: 'get',
  params
});
exports.getMoqsDiscounts = getMoqsDiscounts;
const getMoqsDiscountById = id => (0, _request.default)({
  url: `/moqs-discounts/${id}`,
  method: 'get'
});
exports.getMoqsDiscountById = getMoqsDiscountById;
const updateMoqsDiscount = (id, data) => (0, _request.default)({
  url: `/moqs-discounts/${id}`,
  method: 'patch',
  data
});
exports.updateMoqsDiscount = updateMoqsDiscount;
const deleteMoqsDiscount = id => (0, _request.default)({
  url: `/moqs-discounts/${id}`,
  method: 'delete'
});
exports.deleteMoqsDiscount = deleteMoqsDiscount;
const createMoqsDiscount = data => (0, _request.default)({
  url: '/moqs-discounts',
  method: 'post',
  data
});
exports.createMoqsDiscount = createMoqsDiscount;
const manageMoqsDiscount = data => (0, _request.default)({
  url: '/moqs-discounts/manage',
  method: 'post',
  data
});
exports.manageMoqsDiscount = manageMoqsDiscount;
const downloadExcel = data => (0, _request.default)({
  url: '/moqs-discounts/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;