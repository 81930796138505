"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateInventoryInward = exports.getInventoryInwards = exports.getInventoryInwardById = exports.downloadInventoryInwardPdf = exports.deleteInventoryInward = exports.defaultIInventoryInwardData = exports.createInventoryInward = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultIInventoryInwardData = exports.defaultIInventoryInwardData = {
  id: 0,
  currentStock: 0,
  unitId: undefined,
  unit: {
    id: undefined
  },
  productId: undefined,
  product: {
    id: undefined
  },
  productsVariationId: undefined,
  productsVariation: {
    id: undefined
  }
};
const getInventoryInwards = params => (0, _request.default)({
  url: '/inventory-inwards',
  method: 'get',
  params
});
exports.getInventoryInwards = getInventoryInwards;
const getInventoryInwardById = id => (0, _request.default)({
  url: `/inventory-inwards/${id}`,
  method: 'get'
});
exports.getInventoryInwardById = getInventoryInwardById;
const updateInventoryInward = (id, data) => (0, _request.default)({
  url: `/inventory-inwards/${id}`,
  method: 'patch',
  data
});
exports.updateInventoryInward = updateInventoryInward;
const deleteInventoryInward = id => (0, _request.default)({
  url: `/inventory-inwards/${id}`,
  method: 'delete'
});
exports.deleteInventoryInward = deleteInventoryInward;
const createInventoryInward = data => (0, _request.default)({
  url: '/inventory-inwards',
  method: 'post',
  data
});
exports.createInventoryInward = createInventoryInward;
const downloadInventoryInwardPdf = params => (0, _request.default)({
  url: '/inventory-inwards/download-inventory-pdf',
  method: 'get',
  params
});
exports.downloadInventoryInwardPdf = downloadInventoryInwardPdf;