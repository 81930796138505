"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _reportSubMenu = require("./subMenus/report-sub-menu");
var _itemWiseReportSubMenu = require("./subMenus/item-wise-report-sub-menu");
var _newItemWiseReportSubMenu = require("./subMenus/new-item-wise-report-sub-menu");
var _dealerWiseOsOdReport = require("./subMenus/dealer-wise-os-od-report");
const reportRouter = {
  path: '/report',
  component: _index.default,
  redirect: 'noredirect',
  name: 'Reports',
  meta: {
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Invoice Manager', 'Read Only', 'Account Manager'],
    title: 'report.title',
    icon: 'list'
  },
  children: [..._reportSubMenu.salesReportSubMenu, ..._itemWiseReportSubMenu.itemWiseReportSubMenu, ..._newItemWiseReportSubMenu.newItemWiseReportSubMenu, ..._dealerWiseOsOdReport.dealerWiseOsOdReportSubMenu] // ...hsnSummaryReportSubMenu]
};
var _default = exports.default = reportRouter;