import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { getTransactions, updateTransaction } from '@/api/transactions';
import Pagination from '@/components/Pagination/index.vue';
import { getQuery } from '@/utils/index';
import Enabled from '@/components/Enabled/index.vue';
import Inr from '@/components/Inr/index.vue';
import { AdminModule } from '@/store/modules/admin';
import { searchDealers } from '@/api/dealers';
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.loggedInAdmin = null;
    this.rejectFormVisible = false;
    this.rowIndex = 0;
    this.dealerList = [];
    this.dealerListLoading = false;
    this.isAgent = false;
    this.dateRange = '';
    this.startDate = '';
    this.endDate = '';
    this.formData = {
      id: null,
      status: 'failure',
      errorMessage: null,
      adminId: null
    };
    this.listQuery = {
      dealerId: undefined,
      page: 1,
      limit: 10,
      createdTimestamp: undefined,
      sort: 'id,DESC',
      filter: {
        dealerId: '$eq',
        createdTimestamp: 'between'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.statusColorMap = {
      pending: 'primary',
      success: 'success',
      failure: 'danger'
    };
    this.rules = {
      errorMessage: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
  }
  activated() {
    this.loggedInAdmin = AdminModule;
    // check if logged in user is Agent/Admin
    if (this.loggedInAdmin.userType === 'Agent') {
      this.isAgent = true;
    }
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateRange = {
        start: this.$route.query.startDate,
        end: this.$route.query.endDate
      };
    }
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await getTransactions(getQuery(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await searchDealers({
        keyword: query,
        searchBy: isNumeric ? 'dealerId' : 'name'
      });
    } catch (error) {
      this.dealerListLoading = false;
    }
  }
  toggleRejectFormDialog(row, index = 0) {
    this.rejectFormVisible = true;
    this.formData.id = row.id;
    this.rowIndex = index;
  }
  validateForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.listLoading = true;
      this.rejectFormVisible = false;
      this.formData.adminId = this.loggedInAdmin.id;
      await updateTransaction(this.formData.id, this.formData);
      this.list[this.rowIndex].status = 'failure';
      this.$notify({
        title: 'Success',
        type: 'success',
        message: 'Transaction updated',
        duration: 2000
      });
      this.listLoading = false;
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error',
        showClose: true
      });
      this.listLoading = false;
    }
  }
  async approveForm(row, index = 0) {
    try {
      this.listLoading = true;
      await updateTransaction(row.id, {
        status: 'success'
      });
      this.list[index].status = 'success';
      this.$notify({
        title: 'Success',
        type: 'success',
        message: 'Transaction updated',
        duration: 2000
      });
      this.listLoading = false;
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: 'error',
        showClose: true
      });
      this.listLoading = false;
    }
  }
  handleFilter() {
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      this.listQuery.createdTimestamp = [this.startDate, this.endDate].join(',');
    } else {
      this.listQuery.createdTimestamp = '';
      this.listQuery.page = 1;
    }
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
};
default_1 = __decorate([Component({
  name: 'TransactionList',
  components: {
    Pagination,
    Enabled,
    Inr
  }
})], default_1);
export default default_1;