"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block",
    staticStyle: {
      width: "80%"
    }
  }, [_c("div", {
    staticClass: "sourcez"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.ordersProduct,
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "Dealer Name",
      width: "100px",
      prop: "dealerName"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Agent Name",
      prop: "agentName"
    }
  })], 1), _c("div", {
    staticStyle: {
      "text-align": "center",
      margin: "5px"
    }
  }, [_vm.ordersProduct.length ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success",
      align: "center",
      size: "mini"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;