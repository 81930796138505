"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.policySubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const policySubMenu = exports.policySubMenu = [{
  path: '/policies/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/policies/list.vue'))),
  name: 'schemeManagement.policy.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Read Only'],
    title: 'schemeManagement.policy.title',
    noCache: true,
    icon: 'auction'
  }
}, {
  path: '/policies/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/policies/manage.vue'))),
  name: 'schemeManagement.policy.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'schemeManagement.policy.edit',
    noCache: true,
    activeMenu: '/policies/list',
    hidden: true
  }
}, {
  path: '/policies/add/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/policies/manage.vue'))),
  name: 'schemeManagement.policy.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'schemeManagement.policy.add',
    noCache: true,
    activeMenu: '/policies/list',
    hidden: true
  }
}];