"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = [];
    this.loading = false;
    this.listLoading = false;
    this.rules = {};
    this.tableKey = 0;
    this.orderId = null;
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    this.orderId = this.$route.params && this.$route.params.id;
    this.fetchData(this.orderId);
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(orderId) {
    try {
      this.listLoading = true;
      this.formData = await (0, _order.getOrdersProducts)((0, _utils.getQuery)({
        orderId,
        filter: {
          orderId: 'eq'
        }
      }));
      // Just for test
      const title = 'Manage Rating';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
      this.listLoading = false;
    } catch (err) {
      this.listLoading = false;
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      await (0, _order.manageRating)(this.orderId, this.formData);
      this.$notify({
        title: 'Success',
        message: 'Ratings updated successfully',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      _router.default.push({
        path: '/order/list',
        query: this.$route.query
      });
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrdersRatingDetail'
})], default_1);
var _default = exports.default = default_1;