"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _policiesDiscounts = require("@/api/policies-discounts");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _dealersRoles = require("@/api/dealers-roles");
var _ranges = require("@/api/ranges");
var _products = require("@/api/products");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _policiesDiscounts.defaultPoliciesDiscountData);
    this.loading = false;
    this.productList = [];
    this.roleList = [];
    this.rangeList = [];
    this.policyDiscountList = [];
    this.selectedProduct = null;
    this.policyId = 0;
    this.rules = {
      dealersRoleId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      rangeId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _policiesDiscounts.defaultPoliciesDiscountData);
      // assign policy ID
      this.policyId = parseInt(this.$route.params && this.$route.params.policyId);
      this.formData.policyId = this.policyId;
    }
    // get dealers roles
    this.fetchDealersRoles();
    // get ranges
    this.fetchRanges();
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _policiesDiscounts.getPoliciesDiscountById)(id);
      this.formData = data;
      this.policyId = data.policyId;
      // fetch product if mapped
      if (this.formData.productId) {
        this.selectedProduct = await (0, _products.getProductById)(this.formData.productId);
        this.productList = [this.selectedProduct];
        // fetch variations
        this.fetchVariantions();
      }
      // Just for test
      const title = 'Policy Discount';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchDealersRoles() {
    this.roleList = await (0, _dealersRoles.getDealersRoles)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchRanges() {
    this.rangeList = await (0, _ranges.getRanges)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchVariantions() {
    this.policyDiscountList = [];
    if (this.formData.productId) {
      const productId = this.formData.productId;
      const dealersRoleId = this.formData.dealersRoleId;
      // get selected product
      this.selectedProduct = await (0, _products.getProductById)(this.formData.productId);
      // get variations for selected product
      const variations = await (0, _products.getVariations)((0, _utils.getQuery)({
        productId,
        sort: 'name,ASC',
        filter: {
          productId: '$eq'
        }
      }));
      // map data
      if (variations.length) {
        // check if variation already saved to policy discounts
        const savedVariations = await (0, _policiesDiscounts.getPoliciesDiscounts)((0, _utils.getQuery)({
          dealersRoleId,
          productId,
          filter: {
            productId: '$eq',
            dealersRoleId: '$eq'
          }
        }));
        // map variation ID to each object
        const policyObj = savedVariations.reduce((acc, obj) => {
          acc[obj.productsVariationId] = obj;
          return acc;
        }, {});
        for (const variant of variations) {
          const objToSave = {
            enabled: true,
            policyId: this.policyId,
            dealersRoleId: this.formData.dealersRoleId,
            // rangeId: this.formData.rangeId,
            productId,
            productsVariation: {
              id: variant.id,
              name: variant.name
            },
            discountType: 'nett',
            percentDiscount: 0,
            nettDiscount: 0
          };
          if (Object.prototype.hasOwnProperty.call(policyObj, variant.id)) {
            objToSave.id = policyObj[variant.id].id;
            objToSave.nettDiscount = policyObj[variant.id].nettDiscount;
          }
          this.policyDiscountList.push(objToSave);
        }
      }
    }
  }
  async fetchProducts(query) {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.searchProducts)((0, _utils.getQuery)({
          keyword: query,
          rangeId: this.formData.rangeId
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      switch (this.formData.discountType) {
        case 'percent':
          // update policy ID
          if (this.formData.policyId) {
            this.formData.policy = {
              id: this.formData.policyId
            };
          }
          // update dealers role ID
          if (this.formData.dealersRoleId) {
            this.formData.dealersRole = {
              id: this.formData.dealersRoleId
            };
          }
          // update range ID
          if (this.formData.rangeId) {
            this.formData.range = {
              id: this.formData.rangeId
            };
          }
          if (this.isEdit) {
            await (0, _policiesDiscounts.updatePoliciesDiscount)(this.formData.id, this.formData);
          } else {
            await (0, _policiesDiscounts.createPoliciesDiscount)(this.formData);
          }
          break;
        case 'nett':
          for (const key in this.policyDiscountList) {
            // set range ID
            this.policyDiscountList[key].rangeId = this.selectedProduct.rangeId;
            // set percent discount to 0 if any
            this.policyDiscountList[key].percentDiscount = 0;
            // remove rows with nett discount: 0
            if (this.policyDiscountList[key].nettDiscount === 0) {
              if (this.policyDiscountList[key].id) {
                this.policyDiscountList[key].enabled = false;
              } else {
                this.policyDiscountList.splice(+key, 1);
              }
            }
          }
          if (this.policyDiscountList.length) {
            await (0, _policiesDiscounts.managePoliciesDiscount)(this.policyDiscountList);
          }
          break;
        default:
          break;
      }
      this.$notify({
        title: 'Success',
        message: 'Policy discount saved',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      // router.push("/policies-discounts/list/" + this.formData.policyId);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PoliciesDiscountDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;