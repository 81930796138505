"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _transporters = require("@/api/transporters");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _cities = require("@/api/locations/cities");
var _states = require("@/api/locations/states");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _transporters.defaultTransporterData);
    this.popOverData = Object.assign({});
    this.loading = false;
    this.listLoading = true;
    this.list = [];
    this.cityList = [];
    this.countryId = 0;
    this.total = 0;
    this.tableKey = 0;
    this.listQuery = {
      page: 1,
      limit: 40,
      code: undefined,
      name: undefined,
      enabled: true,
      countryId: 0,
      sort: 'name,ASC',
      filter: {
        code: '$contL',
        name: '$contL',
        enabled: '$eq',
        countryId: '$eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.rules = {
      name: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      gstNumber: [{
        pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        message: 'Enter a valid GST number',
        trigger: 'blur'
      }],
      cityId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
  }
  data() {
    return {
      dialogTableVisible: false,
      dialogFormVisible: false
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    // this.countryId = parseInt(
    //   this.$route.params && this.$route.params.countryId
    // )
    this.countryId = 1;
    this.getList();
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _transporters.defaultTransporterData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async getList() {
    try {
      this.listLoading = true;
      this.listQuery.countryId = this.countryId;
      const data = await (0, _states.getStates)((0, _utils.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async fetchData(id) {
    try {
      const data = await (0, _transporters.getTransporterById)(id);
      // fetch selected city
      await this.getCityById(data.cityId);
      this.formData = data;
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getCityList(query) {
    try {
      if (query && query.length > 2) {
        this.cityList = await (0, _cities.getCities)((0, _utils.getQuery)({
          name: query,
          filter: {
            name: '$startsL'
          }
        }));
      }
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getCityById(cityId) {
    try {
      if (cityId) {
        const data = await (0, _cities.getCityById)(cityId);
        this.cityList = [data];
      }
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async updateLocation(cityId) {
    if (cityId) {
      const city = await (0, _cities.getCityById)(cityId);
      this.formData.city = {
        id: city.id
      };
      this.formData.state = {
        id: city.stateId
      };
      this.formData.country = {
        id: city.countryId
      };
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitPopOver() {
    if (!this.popOverData.name && !this.popOverData.stateId) {
      this.$message({
        message: 'Either State or City name is required',
        type: 'warning'
      });
      return false;
    }
    this.savePopOver();
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        // either gst or vehicle number must be filled
        if (!this.formData.gstNumber && !this.formData.vehicleNumber) {
          this.$message({
            message: 'Either GST or vehicle number is required',
            type: 'warning'
          });
          return false;
        }
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async handleTableLoad() {
    try {
      if (this.shouldLoadMoreData()) {
        this.listLoading = true;
        this.listQuery.page++;
        const data = await (0, _states.getStates)((0, _utils.getQuery)(this.listQuery));
        this.list = [...this.list, ...data.data];
        this.total = data.total;
        this.listLoading = false;
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  shouldLoadMoreData() {
    const tableBody = document.querySelector('.el-table__body-wrapper');
    const tableBottom = tableBody.scrollHeight - tableBody.clientHeight;
    const scrollPosition = tableBody.scrollTop;
    return scrollPosition > tableBottom * 0.9;
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async savePopOver() {
    try {
      this.loading = true;
      this.popOverData.countryId = 1;
      await (0, _cities.createCity)(this.popOverData);
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'City saved',
        type: 'success',
        duration: 2000
      });
    } catch (error) {
      this.loading = false;
    }
  }
  async saveForm() {
    try {
      this.loading = true;
      await this.updateLocation(this.formData.cityId);
      if (this.isEdit) {
        await (0, _transporters.updateTransporter)(this.formData.id, this.formData);
      } else {
        await (0, _transporters.createTransporter)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Transporter saved',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/transporters/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "savePopOver", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'TransporterDetail',
  components: {
    Pagination: _index.default
  }
})], default_1);
var _default = exports.default = default_1;