// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.upload-container .el-upload {\n  text-align: left !important;\n}\n.el-upload__tip {\n  color: gray;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#B58643",
	"menuText": "#e0e0e0",
	"menuActiveText": "#FFE164",
	"menuActiveBg": "#906a35"
};
module.exports = exports;
