"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersTicket = exports.ordersTicketsCount = exports.getOrdersTickets = exports.getOrdersTicketById = exports.fileTransferHeaders = exports.deleteOrdersTicket = exports.defaultOrdersTicketData = exports.createOrdersTicket = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultOrdersTicketData = exports.defaultOrdersTicketData = {
  id: 0,
  enabled: true,
  userId: 0,
  orderId: null,
  status: 'Open',
  closureComments: '',
  comments: '',
  attachment1: '',
  attachment2: '',
  attachment3: '',
  user: {
    id: null
  },
  order: {
    id: null
  }
};
const getOrdersTickets = params => (0, _request.default)({
  url: '/orders-tickets',
  method: 'get',
  params
});
exports.getOrdersTickets = getOrdersTickets;
const getOrdersTicketById = id => (0, _request.default)({
  url: `/orders-tickets/${id}`,
  method: 'get'
});
exports.getOrdersTicketById = getOrdersTicketById;
const updateOrdersTicket = (id, data) => (0, _request.default)({
  url: `/orders-tickets/${id}`,
  method: 'patch',
  data
});
exports.updateOrdersTicket = updateOrdersTicket;
const deleteOrdersTicket = id => (0, _request.default)({
  url: `/orders-tickets/${id}`,
  method: 'delete'
});
exports.deleteOrdersTicket = deleteOrdersTicket;
const createOrdersTicket = data => (0, _request.default)({
  url: '/orders-tickets/',
  method: 'post',
  data
});
exports.createOrdersTicket = createOrdersTicket;
const ordersTicketsCount = params => (0, _request.default)({
  url: '/orders-tickets/count',
  method: 'get',
  params
});
exports.ordersTicketsCount = ordersTicketsCount;