"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _common = require("@/api/common");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  async toggleEnable(value) {
    if (this.disabled) {
      this.$message({
        message: "Operation not permitted!",
        type: "warning"
      });
      return;
    }
    const newValue = !this.value;
    try {
      this.url = null; // remove to enable value change
      if (this.url) {
        const data = await (0, _common.toggleEnable)(this.url, {
          enabled: newValue
        });
        if (data) {
          this.$emit("input", data.enabled);
        }
      }
    } catch (error) {
      if (error) {
        this.$message(error.toString());
      }
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "value", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ""
})], default_1.prototype, "url", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "disabled", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "Enabled"
})], default_1);
var _default = exports.default = default_1;