"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Thumb/index.vue"));
var _admin = require("@/store/modules/admin");
var _order = require("@/api/order");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.reviseOrderFlag = false;
  }
  isSuperAdmin() {
    return ["Super Admin"].includes(_admin.AdminModule.roles.toString());
  }
  async _reviseOrder(orderId = 0) {
    try {
      this.reviseOrderFlag = true;
      const confirm = await this.$confirm("Are you sure to recalculate this order?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "el-button--success",
        cancelButtonClass: "el-button--danger",
        type: "warning"
      });
      // check if confirm
      if (confirm === "confirm") {
        const data = await (0, _order.reviseOrder)({
          id: orderId,
          saveDiscounts: true,
          skipReturn: true
        });
        this.$message({
          type: "success",
          message: "Order recalculated successfully"
        });
        // refresh the order
        this.$emit("refresh");
      }
    } catch (error) {
      this.$message({
        type: "error",
        message: error.toString()
      });
    } finally {
      this.reviseOrderFlag = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "data", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "OrderInfoTab",
  components: {
    Inr: _index.default,
    Thumb: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;