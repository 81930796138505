"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Is Primary",
      prop: "isPrimary"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.isPrimary,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isPrimary", $$v);
      },
      expression: "formData.isPrimary"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Transporter",
      prop: "transporterId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Select Transporter"
    },
    model: {
      value: _vm.formData.transporterId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "transporterId", $$v);
      },
      expression: "formData.transporterId"
    }
  }, _vm._l(_vm.transporterList, function (transporter) {
    return _c("el-option", {
      key: transporter.id,
      attrs: {
        label: transporter.name + "-" + transporter.city.name,
        value: transporter.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Freight Per Carton",
      prop: "freightPerCarton"
    }
  }, [_c("el-input-number", {
    attrs: {
      required: "",
      precision: 2,
      step: 1,
      min: 0
    },
    model: {
      value: _vm.formData.freightPerCarton,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "freightPerCarton", $$v);
      },
      expression: "formData.freightPerCarton"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1)], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;