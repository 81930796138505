import { render, staticRenderFns } from "./DealerDetails.vue?vue&type=template&id=39dc4fee&scoped=true"
import script from "./DealerDetails.vue?vue&type=script&lang=ts"
export * from "./DealerDetails.vue?vue&type=script&lang=ts"
import style0 from "./DealerDetails.vue?vue&type=style&index=0&id=39dc4fee&lang=scss"
import style1 from "./DealerDetails.vue?vue&type=style&index=1&id=39dc4fee&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39dc4fee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\sandy\\Desktop\\CONA\\Cona-Electrical-Admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39dc4fee')) {
      api.createRecord('39dc4fee', component.options)
    } else {
      api.reload('39dc4fee', component.options)
    }
    module.hot.accept("./DealerDetails.vue?vue&type=template&id=39dc4fee&scoped=true", function () {
      api.rerender('39dc4fee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/report/new-item-wise-report/components/DealerDetails.vue"
export default component.exports