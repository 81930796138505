"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.viewInvoice = exports.uploadEwayPdf = exports.updateOrdersDispath = exports.updateEway = exports.searchEntryNumber = exports.regenerateInvoice = exports.getPendingOrdersProducts = exports.getOrdersDispathById = exports.getOrdersDispatches = exports.getOnlyOrdersProducts = exports.getHsnReport = exports.getGrandTotal = exports.getFinancialYear = exports.getDispatchCount = exports.getDealerReports = exports.generateInvoice = exports.generateFax = exports.generateEway = exports.generateEInvoice = exports.fileTransferHeaders = exports.fetchPendingDispatch = exports.fetchOverdueByDays = exports.fetchOutstandingDealers = exports.downloadPendingOrdersProducts = exports.downloadPdfOverdue = exports.downloadOsOdExcel = exports.downloadMergedInvoiceEwayPdf = exports.downloadHsnExcel = exports.downloadExcelOverdue = exports.downloadExcelOS = exports.downloadExcel = exports.downloadCSV = exports.defaultOrdersDispatchData = exports.dealerWiseOsOdReport = exports.dealerSalesReport = exports.createOrdersDispatch = exports.cancelEway = exports.cancelEInvoice = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultOrdersDispatchData = exports.defaultOrdersDispatchData = {
  id: 0,
  enabled: true,
  orderId: undefined,
  dealersTransporterId: null,
  adminId: undefined,
  unitId: undefined,
  agentId: undefined,
  dealerId: undefined,
  quantity: 0,
  dispatchDate: null,
  entryNumber: null,
  markaNumber: null,
  markaDate: null,
  privateMarka: null,
  accountType: undefined,
  looseCartonCount: 0,
  standardCartonCount: 0,
  totalCartonCount: 0,
  comments: null,
  invoiceAttachment: null,
  otherAttachment: null,
  ordersProductsDispatch: [],
  status: undefined,
  dealersTransporter: {
    id: null
  },
  admin: {
    id: null
  },
  unit: {
    id: null
  },
  agent: {
    id: null
  },
  dealer: {
    id: null
  },
  lrNumber: null,
  lrDate: null
};
const getOrdersDispatches = params => (0, _request.default)({
  url: '/orders-dispatches',
  method: 'get',
  params
});
exports.getOrdersDispatches = getOrdersDispatches;
const getDealerReports = params => (0, _request.default)({
  url: '/orders-dispatches/report',
  method: 'get',
  params
});
exports.getDealerReports = getDealerReports;
const downloadCSV = data => (0, _request.default)({
  url: '/orders-dispatches/download-csv',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadCSV = downloadCSV;
const downloadExcel = data => (0, _request.default)({
  url: '/reports/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;
const downloadHsnExcel = data => (0, _request.default)({
  url: '/reports/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadHsnExcel = downloadHsnExcel;
const getGrandTotal = params => (0, _request.default)({
  url: '/reports/sales-report-grandTotal',
  method: 'GET',
  params
});
exports.getGrandTotal = getGrandTotal;
const createOrdersDispatch = data => (0, _request.default)({
  url: '/orders-dispatches/add',
  method: 'post',
  data
});
exports.createOrdersDispatch = createOrdersDispatch;
const getOrdersDispathById = id => (0, _request.default)({
  url: `/orders-dispatches/${id}`,
  method: 'get'
});
exports.getOrdersDispathById = getOrdersDispathById;
const updateOrdersDispath = (id, data) => (0, _request.default)({
  url: `/orders-dispatches/${id}`,
  method: 'patch',
  data
});
exports.updateOrdersDispath = updateOrdersDispath;
const fetchPendingDispatch = data => (0, _request.default)({
  url: '/orders-products/pending-dispatch',
  method: 'post',
  data
});
exports.fetchPendingDispatch = fetchPendingDispatch;
const generateFax = params => (0, _request.default)({
  url: '/orders-dispatches/fax',
  method: 'get',
  params
});
/**
 * it will create new invoice & override existing data in dbd
 * do not use always
 */
exports.generateFax = generateFax;
const viewInvoice = id => (0, _request.default)({
  url: `/orders-dispatches/invoice-preview/${id}`,
  method: 'get'
});
/**
 * it will create invoice once & override existing data in db once
 * rest generated invoice will be returned
 */
exports.viewInvoice = viewInvoice;
const generateInvoice = id => (0, _request.default)({
  url: `/orders-dispatches/get-invoice/${id}`,
  method: 'get'
});
exports.generateInvoice = generateInvoice;
const getPendingOrdersProducts = params => (0, _request.default)({
  url: '/orders-products/pending-dispatch',
  method: 'get',
  params
});
exports.getPendingOrdersProducts = getPendingOrdersProducts;
const getDispatchCount = params => (0, _request.default)({
  url: '/orders-dispatches/count/status',
  method: 'get',
  params
});
exports.getDispatchCount = getDispatchCount;
const generateEway = id => (0, _request.default)({
  url: `/orders-dispatches/eway/${id}`,
  method: 'get'
});
exports.generateEway = generateEway;
const cancelEway = id => (0, _request.default)({
  url: `/orders-dispatches/cancel-eway/${id}`,
  method: 'get'
});
exports.cancelEway = cancelEway;
const generateEInvoice = id => (0, _request.default)({
  url: `/orders-dispatches/einvoice/${id}`,
  method: 'get'
});
exports.generateEInvoice = generateEInvoice;
const cancelEInvoice = id => (0, _request.default)({
  url: `/orders-dispatches/cancel-einvoice/${id}`,
  method: 'get'
});
exports.cancelEInvoice = cancelEInvoice;
const fetchOutstandingDealers = data => (0, _request.default)({
  url: '/orders-dispatches/outstanding-list',
  method: 'post',
  data
});
exports.fetchOutstandingDealers = fetchOutstandingDealers;
const downloadExcelOS = data => (0, _request.default)({
  url: '/orders-dispatches/download-excel-out-standing',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcelOS = downloadExcelOS;
const fetchOverdueByDays = data => (0, _request.default)({
  url: '/orders-dispatches/overdue-by-days',
  method: 'post',
  data
});
exports.fetchOverdueByDays = fetchOverdueByDays;
const downloadPendingOrdersProducts = params => (0, _request.default)({
  url: '/orders-products/download-pending-dispatch',
  method: 'get',
  params
});
exports.downloadPendingOrdersProducts = downloadPendingOrdersProducts;
const downloadMergedInvoiceEwayPdf = id => (0, _request.default)({
  url: `/orders-dispatches/mergePdf/${id}`,
  method: 'get'
});
exports.downloadMergedInvoiceEwayPdf = downloadMergedInvoiceEwayPdf;
const updateEway = data => (0, _request.default)({
  url: '/orders-dispatches/update-eway',
  method: 'post',
  data
});
exports.updateEway = updateEway;
const uploadEwayPdf = data => (0, _request.default)({
  url: '/orders-dispatches/upload-eway-pdf',
  method: 'post',
  data
});
exports.uploadEwayPdf = uploadEwayPdf;
const dealerSalesReport = params => (0, _request.default)({
  url: '/reports/sales-report',
  method: 'get',
  params
});
exports.dealerSalesReport = dealerSalesReport;
const getHsnReport = params => (0, _request.default)({
  url: '/reports/hsn',
  method: 'get',
  params
});
exports.getHsnReport = getHsnReport;
const regenerateInvoice = id => (0, _request.default)({
  url: `/orders-dispatches/generate-invoice-and-upload-to-s3/${id}`,
  method: 'post',
  data: {
    idsToProcess: [id]
  }
});
exports.regenerateInvoice = regenerateInvoice;
const getFinancialYear = () => (0, _request.default)({
  url: 'reports/financial-year',
  method: 'get'
});
exports.getFinancialYear = getFinancialYear;
const downloadExcelOverdue = data => (0, _request.default)({
  url: '/orders-dispatches/download-excel-overdue',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcelOverdue = downloadExcelOverdue;
const downloadPdfOverdue = data => (0, _request.default)({
  url: '/orders-dispatches/download-pdf-overdue',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadPdfOverdue = downloadPdfOverdue;
const dealerWiseOsOdReport = data => (0, _request.default)({
  url: '/reports/dealer-wise-os-od',
  method: 'post',
  data
});
exports.dealerWiseOsOdReport = dealerWiseOsOdReport;
const downloadOsOdExcel = data => (0, _request.default)({
  url: '/reports/download-excel-os-od',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadOsOdExcel = downloadOsOdExcel;
const getOnlyOrdersProducts = data => (0, _request.default)({
  url: '/orders-products/get-only-orders-products',
  method: 'post',
  data
});
exports.getOnlyOrdersProducts = getOnlyOrdersProducts;
const searchEntryNumber = params => (0, _request.default)({
  url: '/orders-dispatches/entry-number/search',
  method: 'get',
  params
});
exports.searchEntryNumber = searchEntryNumber;