"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _Account = _interopRequireDefault(require("./components/Account.vue"));
var _Activity = _interopRequireDefault(require("./components/Activity.vue"));
var _Timeline = _interopRequireDefault(require("./components/Timeline.vue"));
var _UserCard = _interopRequireDefault(require("./components/UserCard.vue"));
const defaultProfile = {
  name: 'Loading...',
  email: 'Loading...',
  avatar: 'Loading...',
  roles: 'Loading...'
};
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.user = defaultProfile;
    this.activeTab = 'account';
  }
  get name() {
    return _admin.AdminModule.name;
  }
  get email() {
    return _admin.AdminModule.email;
  }
  get avatar() {
    return _admin.AdminModule.avatar;
  }
  get roles() {
    return _admin.AdminModule.roles;
  }
  activated() {
    this.getUser();
  }
  getUser() {
    this.user = {
      name: this.name,
      email: this.email,
      avatar: this.avatar,
      roles: this.roles.join(' | ')
    };
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Profile',
  components: {
    Account: _Account.default,
    Activity: _Activity.default,
    Timeline: _Timeline.default,
    UserCard: _UserCard.default
  }
})], default_1);
var _default = exports.default = default_1;