"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersProductsDispatches = require("@/api/orders-products-dispatches");
var _index = _interopRequireDefault(require("@/components/Inr/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.list = [];
    this.listLoading = true;
    this.rules = {
      rejectReason: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
  }
  created() {
    this.getList(this.ordersProductId);
  }
  activated() {
    this.getList(this.ordersProductId);
  }
  async getList(ordersDispatchId) {
    try {
      this.listLoading = true;
      const data = await (0, _ordersProductsDispatches.listByOrdersProductId)(ordersDispatchId);
      this.list = data;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 0
})], default_1.prototype, "ordersProductId", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DispatchList',
  components: {
    Inr: _index.default
  }
})], default_1);
var _default = exports.default = default_1;