"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ledgers = require("@/api/ledgers");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _dealers = require("@/api/dealers");
var _utils = require("@/utils");
var _products = require("@/api/products");
var _ordersDispatches = require("@/api/orders-dispatches");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _ledgers.defaultLedgerData);
    this.loading = false;
    this.dealerListLoading = false;
    this.dealerList = [];
    this.amountInWords = "";
    this.selectedProduct = null;
    this.productListLoading = false;
    this.entryNumberListLoading = false;
    this.products = [];
    this.entryNumbers = [];
    this.productData = undefined;
    this.nettRate = undefined;
    this.paymentTypeOptions = [{
      label: "Credit Note",
      key: "Credit Note"
    }, {
      label: "Debit Note",
      key: "Debit Note"
    }];
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _ledgers.defaultLedgerData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _ledgers.getLedgerById)(id);
      this.formData = data;
      // mark as journal entry
      this.formData.paymentType = "Journal Note";
      // fetch mapped dealer
      const dealer = await (0, _dealers.getDealerById)(data.dealerId);
      this.dealerList = [dealer];
      // Just for test
      const title = "Ledger";
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getProductList(ordersDispatchId) {
    try {
      this.productListLoading = true;
      this.products = await (0, _products.searchDispatchedProducts)((0, _utils.getQuery)({
        ordersDispatchId
      }));
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  async getEntryNumberList(query) {
    try {
      this.entryNumberListLoading = true;
      if (query && query.length > 2) {
        this.entryNumbers = await (0, _ordersDispatches.searchEntryNumber)((0, _utils.getQuery)({
          keyword: query,
          dealerId: this.formData.dealerId
        }));
      }
      this.entryNumberListLoading = false;
    } catch (err) {
      this.entryNumberListLoading = false;
      throw err;
    }
  }
  async handleEntryNumber(value) {
    if (value) {
      await this.getProductList(value);
    }
  }
  async handleProductSelection(value) {
    if (value) {
      this.formData.productsVariationId = value;
      this.productData = await (0, _products.searchDispatchedProducts)({
        productsVariationId: value,
        ordersDispatchId: this.formData.ordersDispatchId
      });
      console.log("pv", this.productData);
    }
  }
  handleQuantity(value) {
    if (value) {
      console.log(value);
      if (this.productData) {
        console.log(this.productData[0].nettRate);
        console.log(value);
        this.formData.amount = parseFloat(value) * parseFloat(this.productData[0].nettRate);
      }
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        // delete id to create new journal entry
        delete this.formData.id;
      } else {
        // set ledger action as per payment type
        if (this.formData.paymentType === "Credit Note") {
          this.formData.action = "credit"; // reversed
          this.formData.status = "Pending";
        } else if (this.formData.paymentType === "Debit Note") {
          this.formData.action = "debit"; // reversed
        }
      }
      this.formData.dealer = {
        id: this.formData.dealerId
      };
      if (this.formData.ordersDispatchId) {
        this.formData.ordersDispatch.id = this.formData.ordersDispatchId;
      }
      await (0, _ledgers.addLedgerTransaction)(this.formData);
      this.loading = false;
      this.$notify({
        title: "Success",
        message: "Ledger saved",
        type: "success",
        duration: 2000
      });
      Object.assign(this.formData, {});
      Object.assign(this.products, {});
      this.selectedProduct = null;
      _router.default.push("/ledgers/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "AddCnDn",
  components: {}
})], default_1);
var _default = exports.default = default_1;