"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
require("tinymce/tinymce");
require("tinymce/themes/silver");
require("tinymce/themes/mobile");
require("tinymce/plugins/advlist");
require("tinymce/plugins/anchor");
require("tinymce/plugins/autolink");
require("tinymce/plugins/autosave");
require("tinymce/plugins/code");
require("tinymce/plugins/codesample");
require("tinymce/plugins/directionality");
require("tinymce/plugins/emoticons");
require("tinymce/plugins/fullscreen");
require("tinymce/plugins/hr");
require("tinymce/plugins/image");
require("tinymce/plugins/imagetools");
require("tinymce/plugins/insertdatetime");
require("tinymce/plugins/link");
require("tinymce/plugins/lists");
require("tinymce/plugins/media");
require("tinymce/plugins/nonbreaking");
require("tinymce/plugins/noneditable");
require("tinymce/plugins/pagebreak");
require("tinymce/plugins/paste");
require("tinymce/plugins/preview");
require("tinymce/plugins/print");
require("tinymce/plugins/save");
require("tinymce/plugins/searchreplace");
require("tinymce/plugins/spellchecker");
require("tinymce/plugins/tabfocus");
require("tinymce/plugins/table");
require("tinymce/plugins/template");
require("tinymce/plugins/textpattern");
require("tinymce/plugins/visualblocks");
require("tinymce/plugins/visualchars");
require("tinymce/plugins/wordcount");
var _tinymceVue = _interopRequireDefault(require("@tinymce/tinymce-vue"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _settings = require("@/store/modules/settings");
var _EditorImage = _interopRequireDefault(require("./components/EditorImage.vue"));
var _config = require("./config");
// Docs: https://armour.github.io/vue-typescript-admin-docs/features/components/rich-editor.html#tinymce

// Import themes

// Any plugins you want to use has to be imported

// TinyMCE vue wrapper

const defaultId = () => 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.hasChange = false;
    this.hasInit = false;
    this.fullscreen = false;
    // https://www.tiny.cloud/docs/configure/localization/#language
    // and also see langs files under public/tinymce/langs folder
    this.languageTypeList = {
      en: 'en'
    };
  }
  get language() {
    return this.languageTypeList[_app.AppModule.language];
  }
  get uploadButtonColor() {
    return _settings.SettingsModule.theme;
  }
  get tinymceContent() {
    return this.value;
  }
  set tinymceContent(value) {
    this.$emit('input', value);
  }
  get containerWidth() {
    const width = this.width;
    // Test matches `100`, `'100'`
    if (/^[\d]+(\.[\d]+)?$/.test(width.toString())) {
      return `${width}px`;
    }
    return width;
  }
  get initOptions() {
    return {
      selector: `#${this.id}`,
      height: this.height,
      body_class: 'panel-body ',
      object_resizing: false,
      toolbar: this.toolbar.length > 0 ? this.toolbar : _config.toolbar,
      menubar: this.menubar,
      plugins: _config.plugins,
      language: this.language,
      language_url: this.language === 'en' ? '' : `${process.env.BASE_URL}tinymce/langs/${this.language}.js`,
      skin_url: `${process.env.BASE_URL}tinymce/skins/`,
      emoticons_database_url: `${process.env.BASE_URL}tinymce/emojis.min.js`,
      end_container_on_empty_block: true,
      powerpaste_word_import: 'clean',
      code_dialog_height: 450,
      code_dialog_width: 1000,
      advlist_bullet_styles: 'square',
      advlist_number_styles: 'default',
      imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
      default_link_target: '_blank',
      link_title: false,
      nonbreaking_force_tab: true,
      init_instance_callback: editor => {
        if (this.value) {
          editor.setContent(this.value);
        }
        this.hasInit = true;
        editor.on('NodeChange Change KeyUp SetContent', () => {
          this.hasChange = true;
          this.$emit('input', editor.getContent());
        });
      },
      setup: editor => {
        editor.on('FullscreenStateChanged', e => {
          this.fullscreen = e.state;
        });
      }
    };
  }
  onLanguageChange() {
    const tinymceManager = window.tinymce;
    const tinymceInstance = tinymceManager.get(this.id);
    if (this.fullscreen) {
      tinymceInstance.execCommand('mceFullScreen');
    }
    if (tinymceInstance) {
      tinymceInstance.destroy();
    }
    this.$nextTick(() => tinymceManager.init(this.initOptions));
  }
  imageSuccessCBK(arr) {
    const tinymce = window.tinymce.get(this.id);
    arr.forEach(v => {
      tinymce.insertContent(`<img class="wscnph" src="${v.url}" >`);
    });
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "value", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: defaultId
})], default_1.prototype, "id", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: []
})], default_1.prototype, "toolbar", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'file edit insert view format table'
})], default_1.prototype, "menubar", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '360px'
})], default_1.prototype, "height", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'auto'
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('language')], default_1.prototype, "onLanguageChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Tinymce',
  components: {
    EditorImageUpload: _EditorImage.default,
    TinymceEditor: _tinymceVue.default
  }
})], default_1);
var _default = exports.default = default_1;