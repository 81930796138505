"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'back-top': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M85.516 108.161a6.773 6.93 0 01-6.753 6.896H38.078a6.746 6.903 0 01-6.752-6.903V59.606H10.973c-7.45 0-9.211-4.387-3.915-9.814L53.643 2.124a6.793 6.951 0 019.563 0l46.584 47.682c5.297 5.406 3.543 9.807-3.928 9.807H85.516V108.161z"/>'
  }
});