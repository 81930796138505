"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'tab': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M79 0H49c-1.7 0-3 1.8-3 3.6v6.7c0 1.8 1.6 3.4 3.2 3.4H79c1.9 0 3.2-1.6 3.2-3.4V3.5C82.4 1.6 80.8.1 79 .1zm45.5 0H94.6a3.5 3.5 0 00-3.4 3.5v6.7c0 1.8 1.6 3.4 3.4 3.4h29.9c1.8-.2 3.4-1.6 3.4-3.4V3.5c0-1.8-1.6-3.4-3.4-3.4zm0 22.4H40a3.5 3.5 0 01-3.4-3.4V3.5C36.6 1.7 35 .1 33 .1H3.5A3.5 3.5 0 00.1 3.5v121.3c0 1.5 1.6 3.1 3.4 3.1h121c1.8 0 3.4-1.6 3.4-3.4V25.9c0-1.9-1.6-3.5-3.4-3.5z"/>'
  }
});