var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY"
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "14px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "14px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), !_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Agent"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "agentId", $$v);
      },
      expression: "listQuery.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    });
  }), 1) : _vm._e(), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "info",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "280px",
      label: "Dealer",
      prop: "dealerName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: {
              path: "/ledgers/list",
              query: {
                dealerId: scope.row.dealerId
              }
            }
          }
        }, [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(scope.row.dealerName) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      align: "right",
      label: "Outstanding",
      prop: "balance"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.balance
          }
        })];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };