"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'discount': {
    width: 800,
    height: 800,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3c.4.598 1.145 1 2 1s1.6-.402 2-1c.3-.598.859-1 1.5-1s1.2.402 1.5 1c.4.598 1.145 1 2 1s1.6-.402 2-1c.3-.598.859-1 1.5-1s1.2.402 1.5 1c.4.598 1.145 1 2 1s1.6-.402 2-1v18c-.4-.598-1.145-1-2-1s-1.6.402-2 1c-.3.598-.859 1-1.5 1s-1.2-.402-1.5-1c-.4-.598-1.145-1-2-1s-1.6.402-2 1c-.3.598-.859 1-1.5 1s-1.2-.402-1.5-1c-.4-.598-1.145-1-2-1s-1.6.402-2 1V3z" _stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle pid="1" cx="8.5" cy="9.5" r="1.5" _stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle pid="2" cx="15.5" cy="15.5" r="1.5" _stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M17 8l-9 8" _stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
});