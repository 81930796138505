import request from '@/utils/request';
export const defaultOrdersProductsDispatchData = {
  id: 0,
  enabled: true,
  orderId: undefined,
  ordersDispatchId: undefined,
  quantity: 0,
  comments: null,
  isDisabled: undefined
};
export const getOrdersProductsDispatches = params => request({
  url: '/orders-products-dispatches',
  method: 'get',
  params
});
export const createOrdersProductsDispatch = data => request({
  url: '/orders-products-dispatches',
  method: 'post',
  data
});
export const getOrdersProductsDispatchById = id => request({
  url: `/orders-products-dispatches/${id}`,
  method: 'get'
});
export const updateOrdersProductsDispatch = (id, data) => request({
  url: `/orders-products-dispatches/${id}`,
  method: 'patch',
  data
});
export const modifyOrdersProductsDispatch = (id, data) => request({
  url: `/orders-products-dispatches/modify/${id}`,
  method: 'patch',
  data
});
export const listOrdersProductsDispatches = ordersDispatchId => request({
  url: `/orders-products-dispatches/list/${ordersDispatchId}`,
  method: 'get'
});
export const getOrdersProductsDispatchByOrderId = orderId => request({
  url: `/orders-products-dispatches/list-by-order/${orderId}`,
  method: 'get'
});
export const listByOrdersProductId = ordersProductId => request({
  url: `/orders-products-dispatches/list-by-product/${ordersProductId}`,
  method: 'get'
});
export const getItemWiseReports = params => request({
  url: '/orders-products-dispatches/item-wise-report',
  method: 'get',
  params
});
export const getAllData = params => request({
  url: '/orders-products-dispatches/item-wise-report/all',
  method: 'get',
  params
});
export const getAllCartonData = params => request({
  url: '/orders-products-dispatches/item-wise-report/carton-total',
  method: 'get',
  params
});
export const downloadCSV = data => request({
  url: '/orders-products-dispatches/download-csv',
  method: 'post',
  data,
  responseType: 'blob'
});
export const getNewItemWiseReport = params => request({
  url: '/reports/item-wise-report',
  method: 'get',
  params
});
export const getNewItemWiseReportRow = params => request({
  url: '/reports/item-wise-report-row',
  method: 'get',
  params
});
export const downloadExcel = data => request({
  url: '/orders-products-dispatches/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
export const downloadExcelNewItemWise = data => request({
  url: '/reports/download-excel-item-wise',
  method: 'post',
  data,
  responseType: 'blob'
});