"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dealersTransporterSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const dealersTransporterSubMenu = exports.dealersTransporterSubMenu = [{
  path: '/dealers-transporters/list/:dealerId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-transporters/list.vue'))),
  name: 'orderManagement.transporter.title',
  meta: {
    title: 'orderManagement.transporter.title',
    icon: 'user',
    noCache: true,
    hidden: true
  }
}, {
  path: '/dealers-transporters/add/:dealerId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-transporters/manage.vue'))),
  name: 'orderManagement.transporter.add',
  props: {
    isEdit: false
  },
  meta: {
    roles: ['Super Admin', 'Admin'],
    title: 'orderManagement.transporter.add',
    noCache: true,
    activeMenu: '/dealers-transporters/list/:dealerId(\\d+)',
    hidden: true
  }
}, {
  path: '/dealers-transporters/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-transporters/manage.vue'))),
  name: 'orderManagement.transporter.edit',
  props: {
    isEdit: true
  },
  meta: {
    roles: ['Super Admin', 'Admin'],
    title: 'orderManagement.transporter.edit',
    noCache: true,
    activeMenu: '/dealers-transporters/list/:dealerId(\\d+)',
    hidden: true
  }
}];