"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _transactions = require("@/api/transactions");
var _order = require("@/api/order");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _transactions.defaultTransactionData);
    this.loading = false;
    this.order = null;
    this.payableAmount = 0;
    this.rules = {
      utrNumber: {
        required: true,
        trigger: 'blur',
        message: 'Please enter utr number'
      },
      amount: {
        required: true,
        trigger: 'blur',
        message: 'Please enter transaction amount'
      },
      transactionDate: {
        required: true,
        trigger: 'blur',
        message: 'Please enter transaction date'
      },
      bank: {
        required: true,
        trigger: 'blur',
        message: 'Please enter bank name'
      }
    };
    this.statusOptions = [{
      label: 'Success',
      key: 'success'
    }, {
      label: 'Failure',
      key: 'failure'
    }];
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    const id = this.$route.params && this.$route.params.id;
    this.fetchData(parseInt(id));
    this.formData = Object.assign({}, _transactions.defaultTransactionData);
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _transactions.getTransactionById)(id);
      this.formData = data;
      await this.getOrderInfo(data.orderId);
      // Just for test
      const title = 'Approve RTGS';
      // // Set tagsview title
      this.setTagsViewTitle(title);
      // // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getOrderInfo(orderId) {
    try {
      this.order = await (0, _order.getOrderById)(orderId);
      if (this.order) {
        this.payableAmount = +this.order.grandTotal - +this.order.amountReceived;
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  isDateAllowed(time) {
    return time.getTime() > Date.now();
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      await (0, _transactions.updateTransaction)(this.formData.id, this.formData);
      this.$notify({
        title: 'Success',
        message: 'RTGS approved ',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      _router.default.push(`/transaction/list/${this.formData.orderId}`);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'RTGSDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;