"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      placeholder: _vm.$t("attribute.enabled"),
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "enabled", $$v);
      },
      expression: "listQuery.enabled"
    }
  }, _vm._l(_vm.enableTypeOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.value,
        value: item.key
      }
    });
  }), 1), _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/ranges/add"
    }
  }, [!_vm.isReadOnly ? _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]) : _vm._e()], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "50",
      align: "center",
      label: "ID",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "180px",
      align: "center",
      prop: "name",
      label: _vm.$t("Name")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          attrs: {
            url: "/ranges/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        }), _c("router-link", {
          attrs: {
            to: {
              path: "/category/list",
              query: {
                rangeId: scope.row.id
              }
            }
          }
        }, [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(scope.row.name) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "180px",
      align: "center",
      prop: "icon",
      label: "Icon"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-image", {
          staticStyle: {
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: scope.row.icon,
            fit: "fill",
            "preview-src-list": [scope.row.icon]
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      align: "center",
      label: "Priority",
      prop: "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: !_vm.isReadonly,
            name: "priority",
            min: 1,
            size: "mini",
            controls: false
          },
          on: {
            change: function ($event) {
              return _vm.updatePriority(scope.row);
            }
          },
          model: {
            value: scope.row.priority,
            callback: function ($$v) {
              _vm.$set(scope.row, "priority", $$v);
            },
            expression: "scope.row.priority"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MM-YYYY hh:mm a")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/ranges/edit/" + scope.row.id
          }
        }, [!_vm.isReadOnly ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          }
        }) : _vm._e()], 1)];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;