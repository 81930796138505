"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _taxes = require("@/api/master/taxes");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _countries = require("@/api/locations/countries");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.countryList = [];
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _taxes.defaultTaxData);
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      rate: [{
        required: true,
        message: 'Please choose a rate ',
        trigger: 'blur'
      }, {
        required: true,
        // message: 'Please enter valid email',
        trigger: 'change'
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    this.getCountryList();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _taxes.defaultTaxData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _taxes.getTaxById)(id);
      this.formData = data;
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getCountryList() {
    try {
      this.countryList = await (0, _countries.getCountries)((0, _utils.getQuery)({
        enabled: true,
        name: 'India',
        filter: {
          enabled: 'eq',
          name: 'eq'
        }
      }));
      if (this.countryList) {
        this.formData.countryId = this.countryList[0].id;
      }
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _taxes.updateTax)(this.formData.id, this.formData);
      } else {
        await (0, _taxes.createTax)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Tax saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/tax/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'TaxDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;