"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _schemesDiscounts = require("@/api/schemes-discounts");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _dealersRoles = require("@/api/dealers-roles");
var _states = require("@/api/locations/states");
var _agents = require("@/api/agents");
var _ranges = require("@/api/ranges");
var _categories = require("@/api/categories");
var _products = require("@/api/products");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _schemesDiscounts.defaultSchemesDiscountData);
    this.loading = false;
    this.schemeId = 0;
    this.productList = [];
    this.roleList = [];
    this.stateList = [];
    this.agentList = [];
    this.rangeList = [];
    this.categoryList = [];
    this.subCategoryList = [];
    this.schemesDiscountList = [];
    this.variationIdRowsCount = {};
    this.selectedProduct = null;
    this.disableVariation = true;
    this.rules = {};
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _schemesDiscounts.defaultSchemesDiscountData);
      // assign scheme ID
      this.schemeId = parseInt(this.$route.params && this.$route.params.schemeId);
      this.formData.schemeId = this.schemeId;
    }
    // get dealers roles
    this.fetchDealersRoles();
    // get states
    this.fetchStates();
    // get agents
    this.fetchAgents();
    // get ranges
    this.fetchRanges();
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      this.loading = true;
      const data = await (0, _schemesDiscounts.getSchemesDiscountById)(id);
      this.formData = data;
      this.schemeId = data.schemeId;
      // fetch category if mapped
      if (this.formData.categoryId) {
        const category = await (0, _categories.getCategoryById)(this.formData.categoryId);
        this.categoryList = [category];
      }
      // fetch sub category if mapped
      if (this.formData.subCategoryId) {
        const subCategory = await (0, _categories.getCategoryById)(this.formData.subCategoryId);
        this.subCategoryList = [subCategory];
      }
      // fetch product if mapped
      if (this.formData.productId) {
        this.selectedProduct = await (0, _products.getProductById)(this.formData.productId);
        this.productList = [this.selectedProduct];
        // fetch variations
        this.fetchVariantions();
      }
      // Just for test
      const title = '#' + this.formData.id;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  }
  async fetchDealersRoles() {
    this.roleList = await (0, _dealersRoles.getDealersRoles)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchStates() {
    this.stateList = await (0, _states.getStates)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchAgents() {
    this.agentList = await (0, _agents.getAgents)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchRanges() {
    this.rangeList = await (0, _ranges.getRanges)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchCategories() {
    // reset category and subcategory
    this.formData.categoryId = null;
    this.formData.subCategoryId = null;
    this.formData.productId = null;
    this.productList = [];
    this.categoryList = await (0, _categories.getCategories)((0, _utils.getQuery)({
      enabled: true,
      rangeId: this.formData.rangeId,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq',
        parentId: '$isnull',
        rangeId: '$eq'
      }
    }));
  }
  async fetchSubCategories() {
    // reset subcategory
    this.formData.subCategoryId = null;
    this.formData.productId = null;
    this.productList = [];
    this.subCategoryList = await (0, _categories.getCategories)((0, _utils.getQuery)({
      enabled: true,
      parentId: this.formData.categoryId,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq',
        parentId: '$eq'
      }
    }));
  }
  resetProducts() {
    this.formData.productId = null;
    this.productList = [];
  }
  async fetchProducts(query) {
    try {
      let selectedCategoryId = 0;
      if (this.formData.categoryId) {
        selectedCategoryId = this.formData.categoryId;
      }
      if (this.formData.subCategoryId) {
        selectedCategoryId = this.formData.subCategoryId;
      }
      if (query.length > 2) {
        const productParams = {
          keyword: query,
          rangeId: this.formData.rangeId || undefined
        };
        if (selectedCategoryId) {
          productParams.categoryId = selectedCategoryId;
        }
        this.productList = await (0, _products.searchProducts)((0, _utils.getQuery)(productParams));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchVariantions() {
    this.schemesDiscountList = [];
    if (this.formData.productId && this.formData.dealersRoleId) {
      // get selected product
      this.selectedProduct = await (0, _products.getProductById)(this.formData.productId);
      const productId = this.formData.productId;
      const dealersRoleId = this.formData.dealersRoleId;
      const variations = await (0, _products.getVariations)((0, _utils.getQuery)({
        productId,
        sort: 'name,ASC',
        filter: {
          productId: '$eq'
        }
      }));
      // map data
      if (variations.length) {
        this.variationIdRowsCount = {};
        // default add product with null variation
        if (this.selectedProduct.productType === 'variable') {
          const defaultProduct = JSON.parse(JSON.stringify(variations[0]));
          defaultProduct.id = 'null';
          defaultProduct.name = this.selectedProduct.name;
          variations.unshift(defaultProduct);
        } else {
          this.disableVariation = false;
        }
        await this.mapToSchemeDiscounts(dealersRoleId, productId, variations);
      }
    }
  }
  async mapToSchemeDiscounts(dealersRoleId = 0, productId = 0, variations = []) {
    // check if variation already saved to scheme discounts
    const savedVariations = await (0, _schemesDiscounts.getSchemesDiscounts)((0, _utils.getQuery)({
      dealersRoleId,
      productId,
      sort: ['productsVariationId,ASC', 'percentDiscount,ASC'],
      filter: {
        productId: '$eq',
        dealersRoleId: '$eq'
      }
    }));
    // map variation ID to each object
    const schemeObj = savedVariations.reduce((acc, obj) => {
      this.disableVariation = false;
      if (obj.enabled && !obj.productsVariationId) {
        this.disableVariation = true;
      }
      if (!acc[obj.productsVariationId]) {
        acc[obj.productsVariationId] = [obj];
      } else {
        acc[obj.productsVariationId].push(obj);
      }
      return acc;
    }, {});
    for (const variant of variations) {
      if (this.variationIdRowsCount[variant.id]) {
        this.variationIdRowsCount[variant.id] += 1;
      } else {
        this.variationIdRowsCount[variant.id] = 1;
      }
      const objToSave = {
        enabled: true,
        schemeId: this.schemeId,
        dealersRoleId: this.formData.dealersRoleId,
        stateId: this.formData.stateId,
        agentId: this.formData.agentId,
        rangeId: this.formData.rangeId,
        categoryId: this.formData.categoryId,
        subCategoryId: this.formData.subCategoryId,
        productId,
        productsVariation: {
          id: variant.id,
          name: variant.name
        },
        looseQuantity: 0,
        cartonQuantity: 0,
        minimumQuantity: 0,
        percentDiscount: 0
      };
      if (Object.prototype.hasOwnProperty.call(schemeObj, variant.id)) {
        let rowIndex = 0;
        for (const item of schemeObj[variant.id]) {
          const subObj = {
            ...objToSave,
            ...{}
          };
          subObj.id = item.id;
          subObj.looseQuantity = item.minimumQuantity;
          subObj.minimumQuantity = item.minimumQuantity;
          subObj.percentDiscount = item.percentDiscount;
          this.schemesDiscountList.push(subObj);
          if (rowIndex > 0) {
            this.variationIdRowsCount[variant.id] += 1;
          }
          rowIndex++;
        }
      } else {
        this.schemesDiscountList.push(objToSave);
      }
    }
  }
  addRow(index, row) {
    const newRow = {
      enabled: true,
      schemeId: this.schemeId,
      dealersRoleId: this.formData.dealersRoleId,
      stateId: this.formData.stateId,
      agentId: this.formData.agentId,
      rangeId: this.formData.rangeId,
      categoryId: this.formData.categoryId,
      subCategoryId: this.formData.subCategoryId,
      productId: row.productId,
      productsVariation: {
        id: row.productsVariation.id,
        name: row.productsVariation.name
      },
      looseQuantity: 0,
      cartonQuantity: 0,
      minimumQuantity: 0,
      percentDiscount: 0
    };
    this.schemesDiscountList.splice(index + 1, 0, newRow);
    this.variationIdRowsCount[row.productsVariation.id] += 1;
  }
  removeRow(index, row) {
    // revise index as per rowspan
    index += this.variationIdRowsCount[row.productsVariation.id] - 1;
    if (!this.schemesDiscountList[index].id) {
      this.schemesDiscountList.splice(index, 1);
      this.variationIdRowsCount[row.productsVariation.id] -= 1;
    } else {
      this.$notify({
        title: 'Warning',
        message: 'Unable to remove, but you can set quantity to 0 to disable it',
        type: 'warning',
        duration: 3000
      });
    }
  }
  objectSpanMethod(args) {
    const {
      row,
      column,
      rowIndex,
      columnIndex
    } = args;
    if ([0, 5].includes(columnIndex) && row.productsVariation.id) {
      // check row - 1 has same variation id
      if (rowIndex > 0) {
        const prevRow = this.schemesDiscountList[rowIndex - 1];
        if (prevRow.productsVariation.id === row.productsVariation.id) {
          return {
            rowspan: this.variationIdRowsCount[row.productsVariation.id],
            colspan: 0
          };
        }
      }
      return {
        rowspan: this.variationIdRowsCount[row.productsVariation.id],
        colspan: 1
      };
    }
    return 0;
  }
  quantityChange(index, qtyType = 'carton') {
    switch (qtyType) {
      case 'pieces':
        this.schemesDiscountList[index].cartonQuantity = 0;
        this.schemesDiscountList[index].minimumQuantity = this.schemesDiscountList[index].looseQuantity;
        break;
      case 'carton':
        this.schemesDiscountList[index].looseQuantity = 0;
        this.schemesDiscountList[index].minimumQuantity = this.schemesDiscountList[index].cartonQuantity * this.selectedProduct.masterCarton;
        break;
      default:
        break;
    }
    // validate percent
    this.validatePercent(index);
  }
  validatePercent(index) {
    if (this.schemesDiscountList[index].minimumQuantity) {
      if (!this.schemesDiscountList[index].percentDiscount) {
        this.schemesDiscountList[index].percentDiscount = '1';
      }
    } else {
      this.schemesDiscountList[index].percentDiscount = 0;
    }
  }
  toggleInput(row) {
    if (this.disableVariation) {
      if ([null, 'null'].includes(row.productsVariation.id)) {
        return false;
      } else {
        return true;
      }
    } else {
      if ([null, 'null'].includes(row.productsVariation.id)) {
        return true;
      } else {
        return false;
      }
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        // either gst or vehicle number must be filled
        if (!this.formData.dealersRoleId && !this.formData.agentId && !this.formData.stateId) {
          this.$message({
            message: 'Either Dealers Role or Agent or State is required',
            type: 'warning'
          });
          return false;
        }
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      for (const scheme of this.schemesDiscountList) {
        if (scheme.productsVariation.id === 'null') {
          scheme.productsVariation.id = null;
        }
        // remove rows with quantity: 0
        if (scheme.minimumQuantity === 0) {
          scheme.enabled = false;
        } else {
          scheme.enabled = !this.toggleInput(scheme);
        }
      }
      if (this.schemesDiscountList.length) {
        await (0, _schemesDiscounts.manageSchemesDiscount)(this.schemesDiscountList);
      }
      this.$notify({
        title: 'Success',
        message: 'Scheme discount saved',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      // router.push("/schemes-discounts/list/" + this.formData.schemeId);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SchemesDiscountDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;