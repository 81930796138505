"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCity = exports.getCityById = exports.getCities = exports.getAllCities = exports.deleteCity = exports.defaultCityData = exports.createCity = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCityData = exports.defaultCityData = {
  id: 0,
  enabled: true,
  stateId: undefined,
  countryId: undefined,
  name: '',
  country: {
    id: undefined
  },
  state: {
    id: undefined
  }
};
const getCities = params => (0, _request.default)({
  url: '/cities',
  method: 'get',
  params
});
exports.getCities = getCities;
const getAllCities = params => (0, _request.default)({
  url: '/cities',
  method: 'get',
  params
});
exports.getAllCities = getAllCities;
const getCityById = id => (0, _request.default)({
  url: `/cities/${id}`,
  method: 'get'
});
exports.getCityById = getCityById;
const updateCity = (id, data) => (0, _request.default)({
  url: `/cities/${id}`,
  method: 'patch',
  data
});
exports.updateCity = updateCity;
const deleteCity = id => (0, _request.default)({
  url: `/cities/${id}`,
  method: 'delete'
});
exports.deleteCity = deleteCity;
const createCity = data => (0, _request.default)({
  url: '/cities/',
  method: 'post',
  data
});
exports.createCity = createCity;