"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAgent = exports.getAgents = exports.getAgentById = exports.deleteAgent = exports.defaultAgentData = exports.createAgent = exports.agentsCount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultAgentData = exports.defaultAgentData = {
  id: 0,
  enabled: true,
  name: '',
  code: ''
};
const getAgents = params => (0, _request.default)({
  url: '/agents',
  method: 'get',
  params
});
exports.getAgents = getAgents;
const getAgentById = id => (0, _request.default)({
  url: `/agents/${id}`,
  method: 'get'
});
exports.getAgentById = getAgentById;
const updateAgent = (id, data) => (0, _request.default)({
  url: `/agents/${id}`,
  method: 'patch',
  data
});
exports.updateAgent = updateAgent;
const deleteAgent = id => (0, _request.default)({
  url: `/agents/${id}`,
  method: 'delete'
});
exports.deleteAgent = deleteAgent;
const createAgent = data => (0, _request.default)({
  url: '/agents',
  method: 'post',
  data
});
exports.createAgent = createAgent;
const agentsCount = params => (0, _request.default)({
  url: '/agents/count',
  method: 'get',
  params
});
exports.agentsCount = agentsCount;