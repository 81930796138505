"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ordersDispatchesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const ordersDispatchesSubMenu = exports.ordersDispatchesSubMenu = [{
  path: '/orders-dispatches/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/list.vue'))),
  name: 'Dispatch',
  meta: {
    title: 'inventoryManagement.dispatch.title',
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Invoice Manager', 'Agent', 'Read Only'],
    noCache: true,
    hidden: false,
    icon: 'truck'
  }
}, {
  path: '/orders-dispatches/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/manage.vue'))),
  name: 'inventoryManagement.dispatch.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'inventoryManagement.dispatch.add',
    roles: ['Unit Manager'],
    noCache: true,
    activeMenu: '/orders-dispatches/list',
    hidden: true
  }
}, {
  path: '/orders-dispatches/info/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/info.vue'))),
  name: 'inventoryManagement.dispatch.info',
  props: {
    isEdit: true
  },
  meta: {
    title: 'inventoryManagement.dispatch.info',
    noCache: true,
    activeMenu: '/orders-dispatches/list',
    hidden: true
  }
}, {
  path: '/orders-dispatches/pending-list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/pending-orders-products.vue'))),
  name: 'inventoryManagement.dispatch.pendingOrder',
  meta: {
    title: 'inventoryManagement.dispatch.pendingOrder',
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Read Only'],
    noCache: true,
    hidden: false,
    icon: 'scale'
  }
}, {
  path: '/orders-dispatches/outstanding-list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/outstanding-list.vue'))),
  name: 'inventoryManagement.dispatch.outstanding',
  meta: {
    title: 'inventoryManagement.dispatch.outstanding',
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Agent', 'Read Only'],
    noCache: true,
    hidden: false,
    icon: 'scale'
  }
}, {
  path: '/orders-dispatches/overdue-list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/overdue-list.vue'))),
  name: 'inventoryManagement.dispatch.overdue',
  meta: {
    title: 'inventoryManagement.dispatch.overdue',
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Agent', 'Read Only'],
    noCache: true,
    hidden: false,
    icon: 'scale'
  }
}, {
  path: '/orders-dispatches/lr-detail/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/lr-detail.vue'))),
  name: 'inventoryManagement.dispatch.lrDetail',
  props: {
    isEdit: true
  },
  meta: {
    title: 'inventoryManagement.dispatch.lrDetail',
    noCache: true,
    activeMenu: '/orders-dispatches/list',
    hidden: true
  }
}, {
  path: '/orders-dispatches/update-eway/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/update-eway.vue'))),
  name: 'inventoryManagement.dispatch.updateEway',
  props: {
    isEdit: true
  },
  meta: {
    title: 'inventoryManagement.dispatch.updateEway',
    noCache: true,
    activeMenu: '/orders-dispatches/list',
    hidden: true
  }
}, {
  path: '/orders-dispatches/upload-eway-pdf/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/orders-dispatches/upload-eway-pdf.vue'))),
  name: 'inventoryManagement.dispatch.uploadEwayPdf',
  props: {
    isEdit: true
  },
  meta: {
    title: 'inventoryManagement.dispatch.uploadEwayPdf',
    noCache: true,
    activeMenu: '/orders-dispatches/list',
    hidden: true
  }
}];