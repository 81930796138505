"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _utils = require("@/utils");
var _categories = require("@/api/categories");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
    this.categories = [];
    this.selectedCategories = []; // remove this with formData
    this.expandedCategories = [];
    this.searchText = '';
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  activated() {
    // set tree nodes
    if (this.formData.category && this.formData.category.length) {
      // loop over categories to get parent and child nodes
      this.formData.category.filter(category => {
        // push children to show default selected
        if (category.parentId) {
          this.selectedCategories.push(category.id);
        }
        // push parent to show default expanded
        if (category.parentId && this.expandedCategories.indexOf(category.parentId) === -1) {
          this.expandedCategories.push(category.parentId);
        }
      });
      setTimeout(() => {
        const treeRef = this.$refs.tree;
        treeRef.setCheckedKeys(this.selectedCategories);
      }, 500);
    }
  }
  emitFormData() {
    const treeRef = this.$refs.tree;
    this.selectedCategories = treeRef.getCheckedKeys();
    this.$emit('updated', this.formData);
  }
  searchTree() {
    const treeRef = this.$refs.tree;
    treeRef.filter(this.searchText);
  }
  filterNode(value, data) {
    if (!value) return true;
    return data.name.toLowerCase().indexOf(value) !== -1;
  }
  async getCategories() {
    try {
      const data = await (0, _categories.getCategories)((0, _utils.getQuery)({
        enabled: true,
        rangeId: this.formData.rangeId,
        parentId: true,
        filter: {
          enabled: '$eq',
          rangeId: '$eq',
          parentId: '$isnull'
        }
      }));
      if (data && data.length) {
        data.map(item => {
          if (item.parent) {
            item.children = item.parent;
            delete item.parent;
          }
        });
      }
      this.categories = data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async saveForm() {
    this.loading = true;
    try {
      const treeRef = this.$refs.tree;
      const categoriesToSave = {
        category: []
      };
      for (const id of this.selectedCategories) {
        categoriesToSave.category.push({
          id: parseInt(id)
        });
      }
      if (categoriesToSave.category.length) {
        const productsCategories = await (0, _products.updateProductCategories)(this.formData.id, categoriesToSave);
        this.formData.category = productsCategories;
      }
      this.$notify({
        title: 'Success',
        message: 'Categories saved',
        type: 'success',
        duration: 2000
      });
      this.$emit('updated', this.formData);
    } catch (err) {
      this.$notify.error(err.toString());
    } finally {
      this.loading = false;
    }
  }
  async loadNode(node, resolve) {
    var _node$data;
    let data = [];
    const query = {
      enabled: true,
      rangeId: this.formData.rangeId,
      fields: 'id,name',
      filter: {
        enabled: '$eq',
        rangeId: '$eq'
      }
    };
    switch (node.level) {
      case 0:
        // get only parent
        query.parentId = true;
        query.filter.parentId = '$isnull';
        data = await (0, _categories.getCategories)((0, _utils.getQuery)(query));
        break;
      case 1:
        // get level 1 children
        query.parentId = (_node$data = node.data) === null || _node$data === void 0 ? void 0 : _node$data.id;
        query.filter.parentId = '$eq';
        data = await (0, _categories.getCategories)((0, _utils.getQuery)(query));
        if (data && data.length) {
          data.map(item => {
            item.id = item.id.toString();
            item.leaf = true;
          });
        }
        break;
      default:
        data = [];
        break;
    }
    return resolve(data);
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductCategoryTab',
  components: {
    HelpText: _index.default
  }
})], default_1);
var _default = exports.default = default_1;