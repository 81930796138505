"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "empty-text": "No data"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "160",
      align: "center",
      prop: "ordersDispatchId",
      label: "Dispatch ID"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/orders-dispatches/info/" + scope.row.ordersDispatchId,
            type: "primary"
          }
        }, [_c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(scope.row.ordersDispatchId) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "130px",
      align: "center",
      prop: "quantity",
      label: "Qty Dispatched"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Dispatch Date",
      width: "200",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.ordersDispatch.dispatchDate, "DD-MM-YYYY")) + " ")];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;