"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY"
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), !_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: _vm.$t("dealersDispatch.agent")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "agentId", $$v);
      },
      expression: "listQuery.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    });
  }), 1) : _vm._e(), _vm.listQuery.agentId ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    });
  }), 1) : _vm._e(), !_vm.listQuery.agentId ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1) : _vm._e(), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "States"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.stateId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "stateId", $$v);
      },
      expression: "listQuery.stateId"
    }
  }, _vm._l(_vm.stateList, function (state) {
    return _c("el-option", {
      key: state.id,
      attrs: {
        label: state.name,
        value: state.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(state.name))])]);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: !_vm.listQuery.stateId > 0,
      clearable: "",
      filterable: "",
      placeholder: "Cities"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.cityId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "cityId", $$v);
      },
      expression: "listQuery.cityId"
    }
  }, _vm._l(_vm.cityList, function (city) {
    return _c("el-option", {
      key: city.id,
      attrs: {
        label: city.name,
        value: city.id
      }
    });
  }), 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "mini"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")])], 1), _vm.dateRange ? _c("div", [_vm._v(" Selected date: "), _c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("moment")(_vm.dateRange.start, "DD-MM-YYYY hh:mm")) + " ")]), _vm._v(" - "), _c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("moment")(_vm.dateRange.end, "DD-MM-YYYY hh:mm")) + " ")])], 1) : _vm._e(), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "ID",
      prop: "dealer_id",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.dealer_id) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "175",
      align: "center",
      prop: "dealer_code",
      label: "Dealer Code",
      sortable: "custom"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "325",
      prop: "dealer_name",
      label: "Dealer Name",
      sortable: "custom"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "125",
      align: "center",
      prop: "dealer_city",
      label: "City",
      sortable: "custom"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "125",
      align: "center",
      prop: "state_name",
      label: "State",
      sortable: "custom"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      prop: "agent_name",
      label: "Agent",
      sortable: "custom"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      align: "left",
      label: "Sale",
      prop: "totalAmount",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.totalAmount
          }
        })];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;