"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _OrderInfoTab = _interopRequireDefault(require("./OrderInfoTab.vue"));
var _OrderProductTab = _interopRequireDefault(require("./OrderProductTab.vue"));
var _OrderUserTab = _interopRequireDefault(require("./OrderUserTab.vue"));
var _OrdersTransactionTab = _interopRequireDefault(require("./OrdersTransactionTab.vue"));
var _OrderDispatchTab = _interopRequireDefault(require("./OrderDispatchTab.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.orderId = null;
    this.orderInfoData = null;
  }
  activated() {
    this.orderId = this.$route.params.id;
    this.getOrderInfo();
  }
  async getOrderInfo() {
    try {
      this.orderInfoData = await (0, _order.getOrderInfo)(this.orderId);
    } catch (error) {
      this.$message(error.toString());
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrderInfo',
  components: {
    OrderInfoTab: _OrderInfoTab.default,
    OrderProductTab: _OrderProductTab.default,
    OrderUserTab: _OrderUserTab.default,
    OrdersTransactionTab: _OrdersTransactionTab.default,
    OrderDispatchTab: _OrderDispatchTab.default
  }
})], default_1);
var _default = exports.default = default_1;