"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const bannerRouter = [{
  path: '/banner/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/banner/list.vue'))),
  name: 'banners',
  meta: {
    title: 'bannerManagement.banners.title',
    noCache: true,
    icon: 'banner'
  }
}, {
  path: '/banner/add/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/banner/manage.vue'))),
  name: 'bannerManagement.banners.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'bannerManagement.banners.add',
    noCache: true,
    activeMenu: '/banner/list',
    hidden: true
  }
}, {
  path: '/banner/edit/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/banner/manage.vue'))),
  name: 'bannerManagement.banners.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'bannerManagement.banners.edit',
    noCache: true,
    activeMenu: '/banner/list',
    hidden: true
  }
}];
var _default = exports.default = bannerRouter;