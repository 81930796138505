"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ledgers = require("@/api/ledgers");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _dealers = require("@/api/dealers");
var _utils = require("@/utils");
var _products = require("@/api/products");
var _ordersDispatches = require("@/api/orders-dispatches");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _ledgers.defaultLedgerData);
    this.loading = false;
    this.dealerListLoading = false;
    this.dealerList = [];
    this.amountInWords = "";
    this.productListLoading = false;
    this.products = [];
    this.entryNumberListLoading = false;
    this.entryNumbers = [];
    this.quantity = undefined;
    this.rules = {
      dealerId: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      amount: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      paymentType: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      comments: {
        required: false,
        message: "This field is required",
        trigger: "blur"
      },
      paymentMode: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      transactionReference: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      }
    };
    this.paymentTypeOptions = [{
      label: "Cash in Bank",
      key: "Cash in Bank"
    }, {
      label: "Cheque",
      key: "Cheque"
    }, {
      label: "Net Banking",
      key: "Net Banking"
    }, {
      label: "Credit Note",
      key: "Credit Note"
    }, {
      label: "Debit Note",
      key: "Debit Note"
    }, {
      label: "Credit Note-Item Wise",
      key: "Credit Note-Item Wise"
    }, {
      label: "Debit Note-Item Wise",
      key: "Debit Note-Item Wise"
    }];
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _ledgers.defaultLedgerData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _ledgers.getLedgerById)(id);
      this.formData = data;
      // mark as journal entry
      this.formData.paymentType = "Journal Note";
      // fetch mapped dealer
      const dealer = await (0, _dealers.getDealerById)(data.dealerId);
      this.dealerList = [dealer];
      // Just for test
      const title = "Ledger";
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  mounted() {
    this.updateAmountInWords(this.formData.amount);
  }
  numberToWords(num) {
    const a = ["", "one ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine ", "ten ", "eleven ", "twelve ", "thirteen ", "fourteen ", "fifteen ", "sixteen ", "seventeen ", "eighteen ", "nineteen "];
    const b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    if ((num = num.toString()).length > 9) return "";
    const n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " : "";
    str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " : "";
    str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " : "";
    str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " : "";
    str += n[5] != 0 ? (str != "" ? "and " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) : "";
    return str;
  }
  updateAmountInWords(amount) {
    this.amountInWords = this.numberToWords(amount);
  }
  async getProductList(ordersDispatchId) {
    try {
      this.productListLoading = true;
      this.products = await (0, _products.searchDispatchedProducts)((0, _utils.getQuery)({
        ordersDispatchId
      }));
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  async handleEntryNumber(value) {
    if (value) {
      await this.getProductList(value);
    }
  }
  async getEntryNumberList(query) {
    try {
      this.entryNumberListLoading = true;
      if (query && query.length > 2) {
        this.entryNumbers = await (0, _ordersDispatches.searchEntryNumber)((0, _utils.getQuery)({
          keyword: query,
          dealerId: this.formData.dealerId
        }));
      }
      this.entryNumberListLoading = false;
    } catch (err) {
      this.entryNumberListLoading = false;
      throw err;
    }
  }
  handleQuantity(index) {
    this.$set(this.products[index], "CnDnQuantity", this.quantity);
    if (this.quantity) {
      this.$set(this.products[index], "CnDnAmount", parseFloat(this.products[index].nettRate) * parseFloat(this.quantity));
    }
    this.formData.CnDnProducts.push({
      name: this.products[index].productName,
      nettRate: this.products[index].nettRate,
      amount: this.products[index].CnDnAmount,
      quantity: this.products[index].CnDnQuantity
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        // delete id to create new journal entry
        delete this.formData.id;
        // await updateLedger(this.formData.id, this.formData);
      } else {
        // await createLedger(this.formData);
        // set ledger action as per payment type
        if (this.formData.paymentType === "Credit Note") {
          this.formData.action = "credit"; // reversed
          this.formData.status = "Pending";
        } else if (this.formData.paymentType === "Debit Note") {
          this.formData.action = "debit"; // reversed
        } else if (this.formData.paymentType === "Online") {
          this.formData.status = "pending";
          this.formData.paymentType = "online";
        } else if (this.formData.paymentType === "Cash in Bank") {
          this.formData.status = "pending";
          this.formData.paymentType = "offline";
          this.formData.paymentMode = "cash in bank";
        } else if (this.formData.paymentType === "Cheque") {
          this.formData.status = "pending";
          this.formData.paymentType = "offline";
          this.formData.paymentMode = "cheque";
        } else if (this.formData.paymentType === "Net Banking") {
          this.formData.status = "pending";
          this.formData.paymentType = "offline";
        } else if (this.formData.paymentType === "Credit Note-Item Wise") {
          this.formData.status = "Pending";
          this.formData.action = "credit";
          this.formData.isCnDn = true;
        } else if (this.formData.paymentType === "Debit Note-Item Wise") {
          this.formData.action = "debit";
          this.formData.isCnDn = true;
        }
      }
      this.formData.dealer = {
        id: this.formData.dealerId
      };
      if (this.formData.ordersDispatchId) {
        this.formData.ordersDispatch.id = this.formData.ordersDispatchId;
      }
      if (this.formData.CnDnProducts.length !== 0) {
        this.formData.CnDnProducts.forEach(item => {
          console.log("single", item.amount);
          this.formData.amount += item.amount;
          console.log("total", this.formData.amount);
        });
      }
      await (0, _ledgers.addLedgerTransaction)(this.formData);
      this.loading = false;
      this.$notify({
        title: "Success",
        message: "Ledger saved",
        type: "success",
        duration: 2000
      });
      _router.default.push("/ledgers/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "LedgerDetail",
  components: {}
})], default_1);
var _default = exports.default = default_1;