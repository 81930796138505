"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dealer",
      prop: "dealerId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: _vm.isEdit,
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: "Search Dealer"
    },
    model: {
      value: _vm.formData.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealerId", $$v);
      },
      expression: "formData.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1)], 1), !_vm.isEdit ? _c("el-form-item", {
    attrs: {
      label: "Payment Type",
      prop: "paymentType",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      name: "paymentType",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.paymentType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "paymentType", $$v);
      },
      expression: "formData.paymentType"
    }
  }, _vm._l(_vm.paymentTypeOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        required: "",
        label: item.label,
        value: item.key
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.paymentType === "Online" ? _c("el-form-item", {
    attrs: {
      label: "Payment Mode",
      prop: "paymentMode",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      name: "paymentMode",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.paymentMode,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "paymentMode", $$v);
      },
      expression: "formData.paymentMode"
    }
  }, _vm._l(["Credit Card", "Debit Card", "UPI"], function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        required: "",
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.paymentType === "Net Banking" ? _c("el-form-item", {
    attrs: {
      label: "Payment Mode",
      prop: "paymentMode",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      name: "paymentMode",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.paymentMode,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "paymentMode", $$v);
      },
      expression: "formData.paymentMode"
    }
  }, _vm._l(["RTGS", "NEFT", "IMPS"], function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        required: "",
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.paymentType === "Credit Note-Item Wise" || _vm.formData.paymentType === "Debit Note-Item Wise" ? _c("el-form-item", {
    attrs: {
      label: "Invoice Number"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: _vm.isEdit || !this.formData.dealerId,
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.entryNumberListLoading,
      "loading-text": "fetching invoices..",
      "remote-method": _vm.getEntryNumberList,
      placeholder: "Search Invoice Number"
    },
    on: {
      change: _vm.handleEntryNumber
    },
    model: {
      value: _vm.formData.ordersDispatchId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "ordersDispatchId", $$v);
      },
      expression: "formData.ordersDispatchId"
    }
  }, _vm._l(_vm.entryNumbers, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.invoiceNumber,
        value: item.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(item.invoiceNumber))])]);
  }), 1)], 1) : _vm._e(), _vm.formData.ordersDispatchId ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.productListLoading,
      expression: "productListLoading"
    }],
    ref: "productsTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.products,
      border: "",
      fit: "",
      "highlight-current-row": "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "ID",
      prop: "productsVariationId",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Name",
      prop: "productName",
      width: "230"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Quantity Dispatched",
      prop: "quantity",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            min: 0,
            step: 1,
            "step-strictly": "",
            disabled: ""
          },
          model: {
            value: scope.row.quantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "quantity", $$v);
            },
            expression: "scope.row.quantity"
          }
        })];
      }
    }], null, false, 178586232)
  }), _c("br"), _c("el-table-column", {
    attrs: {
      label: "Nett Rate",
      prop: "nettRate",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          attrs: {
            size: "mini",
            disabled: ""
          },
          model: {
            value: scope.row.nettRate,
            callback: function ($$v) {
              _vm.$set(scope.row, "nettRate", $$v);
            },
            expression: "scope.row.nettRate"
          }
        })];
      }
    }], null, false, 1051117258)
  }), _c("el-table-column", {
    attrs: {
      label: "Quantity",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            min: 0,
            step: 1,
            "step-strictly": ""
          },
          on: {
            change: function ($event) {
              return _vm.handleQuantity(scope.$index);
            }
          },
          model: {
            value: _vm.quantity,
            callback: function ($$v) {
              _vm.quantity = $$v;
            },
            expression: "quantity"
          }
        })];
      }
    }], null, false, 3659184078)
  }), _c("el-table-column", {
    attrs: {
      label: "Amount",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: "",
            size: "mini",
            min: 0,
            precision: 2,
            step: 1
          },
          model: {
            value: scope.row.CnDnAmount,
            callback: function ($$v) {
              _vm.$set(scope.row, "CnDnAmount", $$v);
            },
            expression: "scope.row.CnDnAmount"
          }
        })];
      }
    }], null, false, 1685943437)
  })], 1) : _vm._e(), _vm.formData.paymentType !== "Credit Note-Item Wise" && _vm.formData.paymentType !== "Debit Note-Item Wise" ? _c("el-form-item", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      label: "Amount",
      prop: "amount"
    }
  }, [_c("el-input-number", {
    attrs: {
      disabled: _vm.isEdit,
      min: 1,
      controls: true,
      "controls-position": "right"
    },
    on: {
      change: _vm.updateAmountInWords
    },
    model: {
      value: _vm.formData.amount,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  }), _vm.amountInWords ? _c("span", {
    staticClass: "amount-in-words"
  }, [_vm._v(_vm._s(_vm.amountInWords))]) : _vm._e()], 1) : _vm._e(), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      label: "Date"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "Select date"
    },
    model: {
      value: _vm.formData.createdTimestamp,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "createdTimestamp", $$v);
      },
      expression: "formData.createdTimestamp"
    }
  })], 1), _vm.formData.paymentType === "Online" || _vm.formData.paymentType === "Cheque" || _vm.formData.paymentType === "Net Banking" ? _c("el-form-item", {
    attrs: {
      label: "Transaction Reference",
      prop: "transactionReference"
    }
  }, [_c("el-input", {
    attrs: {
      name: "transactionReference"
    },
    model: {
      value: _vm.formData.transactionReference,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "transactionReference", $$v);
      },
      expression: "formData.transactionReference"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "comments"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3
    },
    model: {
      value: _vm.formData.comments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;