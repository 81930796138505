"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLedger = exports.getLedgers = exports.getLedgerList = exports.getLedgerById = exports.downloadExcel = exports.deleteLedger = exports.defaultLedgerData = exports.createLedger = exports.addLedgerTransaction = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultLedgerData = exports.defaultLedgerData = {
  id: 0,
  dealerId: undefined,
  ordersDispatchId: undefined,
  transactionId: undefined,
  amount: 0,
  action: '',
  paymentType: null,
  status: null,
  dealer: {
    id: null
  },
  ordersDispatch: {
    id: null
  },
  transaction: {
    id: null
  },
  quantity: undefined,
  productId: undefined,
  productsVariationId: undefined,
  CnDnProducts: [],
  isCnDn: false
};
const getLedgers = params => (0, _request.default)({
  url: '/ledgers',
  method: 'get',
  params
});
exports.getLedgers = getLedgers;
const getLedgerById = id => (0, _request.default)({
  url: `/ledgers/${id}`,
  method: 'get'
});
exports.getLedgerById = getLedgerById;
const updateLedger = (id, data) => (0, _request.default)({
  url: `/ledgers/${id}`,
  method: 'patch',
  data
});
exports.updateLedger = updateLedger;
const deleteLedger = id => (0, _request.default)({
  url: `/ledgers/${id}`,
  method: 'delete'
});
exports.deleteLedger = deleteLedger;
const createLedger = data => (0, _request.default)({
  url: '/ledgers/',
  method: 'post',
  data
});
exports.createLedger = createLedger;
const getLedgerList = data => (0, _request.default)({
  url: '/ledgers/list',
  method: 'post',
  data
});
exports.getLedgerList = getLedgerList;
const addLedgerTransaction = data => (0, _request.default)({
  url: '/ledgers/add',
  method: 'post',
  data
});
exports.addLedgerTransaction = addLedgerTransaction;
const downloadExcel = data => (0, _request.default)({
  url: '/ledgers/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;