"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _agents = require("@/api/agents");
var _dealers = require("@/api/dealers");
var _admin = require("@/store/modules/admin");
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
var _index4 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = false;
    this.downloadLoading = false;
    this.dateRange = "";
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.agentList = [];
    this.dealerList = [];
    this.stateList = [];
    this.cityList = [];
    this.dealerListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.totalGrandTotal = "";
    this.statusColorMap = {
      Pending: "warning",
      "Request Approval": "primary",
      Approved: "success",
      Rejected: "danger"
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      adminId: undefined,
      agentId: undefined,
      stateId: undefined,
      cityId: undefined,
      startDate: undefined,
      endDate: undefined,
      sort: "id,ASC",
      filter: {
        dealerId: "$eq",
        adminId: "$eq",
        agentId: "$eq",
        stateId: "$eq",
        cityId: "$eq"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.loggedInAdmin = null;
  }
  activated() {
    if (_admin.AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    this.loggedInAdmin = _admin.AdminModule;
    // check if logged in user is Agent/Admin
    if (this.loggedInAdmin.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = this.loggedInAdmin.agentId;
    }
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateRange = {
        start: this.$route.query.startDate,
        end: this.$route.query.endDate
      };
    }
    if (!this.dateRange) {
      this.getCurrentFinancialYear();
    }
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    // get dispatch list
    this.getList();
    // get agents
    this.fetchAgentList();
    this.getStateList();
  }
  async getList() {
    try {
      if (this.dateRange) {
        this.listQuery.startDate = this.dateRange.start;
        this.listQuery.endDate = this.dateRange.end;
      }
      const data = await (0, _ordersDispatches.dealerSalesReport)(this.listQuery);
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      this.totalGrandTotal = await (0, _ordersDispatches.getGrandTotal)(queryWithoutPagination);
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getCurrentFinancialYear() {
    const dateData = await (0, _ordersDispatches.getFinancialYear)();
    this.dateRange = {
      start: dateData.startDate,
      end: dateData.endDate
    };
    this.listQuery.startDate = this.dateRange.start;
    this.listQuery.endDate - this.dateRange.end;
    this.getList();
  }
  async downloadFax() {
    this.listLoading = true;
    try {
      const params = {};
      if (this.listQuery.adminId) {
        params.adminId = this.listQuery.adminId;
      }
      if (this.listQuery.agentId) {
        params.agentId = this.listQuery.agentId;
      }
      if (this.listQuery.dealerId) {
        params.dealerId = this.listQuery.dealerId;
      }
      if (this.listQuery.status) {
        params.status = this.listQuery.status;
      }
      if (this.dateRange) {
        params.startDate = this.dateRange.start;
        params.endDate = this.dateRange.end;
      }
      if (Object.keys(params).length) {
        const data = await (0, _ordersDispatches.generateFax)((0, _index2.getQuery)(params));
        window.open(data.url, "_blank");
        // const filename: string = "fax_" + new Date().getTime() + ".pdf";
        // this.downloadFile(data.url, filename);
      } else {
        this.$message({
          message: "Please select atleast one filter",
          type: "warning"
        });
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async fetchAgentList() {
    try {
      this.agentList = await (0, _agents.getAgents)((0, _index2.getQuery)({
        enable: true,
        sort: "name,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getDealerList(query = null) {
    try {
      if (query && query.length > 2) {
        this.dealerListLoading = true;
        // params
        const params = {
          name: query,
          agentId: this.isAgent ? _admin.AdminModule.agentId : undefined,
          filter: {
            name: "$contL",
            agentId: "$eq"
          }
        };
        // Sandeep: check if you are only getting dealers which are assigned to selected agent
        if (this.listQuery.agentId) {
          params.agentId = this.listQuery.agentId;
        }
        //  get dealers
        this.dealerList = await (0, _dealers.getDealers)((0, _index2.getQuery)(params));
      }
      this.dealerListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getStateList(query = null) {
    try {
      this.stateList = await (0, _states.getStates)((0, _index2.getQuery)({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getCityList(stateId = 0) {
    try {
      this.cityListLoading = true;
      this.cityList = await (0, _cities.getCities)((0, _index2.getQuery)({
        enabled: true,
        stateId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          stateId: "$eq"
        }
      }));
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getDealerCityList(params) {
    const data = await (0, _dealers.getDealerCities)(params);
    this.stateList = data.stateList;
    this.cityList = data.cityList;
  }
  async getAgentDealersList(params) {
    const data = await (0, _dealers.getAgentDealers)(params);
    this.dealerList = data;
  }
  handleFilter() {
    if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      this.listQuery.startDate = this.dateRange.start;
      this.listQuery.endDate = this.dateRange.end;
    } else {
      this.listQuery.startDate = undefined;
      this.listQuery.endDate = undefined;
    }
    if (this.listQuery.stateId) {
      this.getCityList(this.listQuery.stateId);
    } else {
      // empty city
      this.cityList = [];
      this.listQuery.cityId = undefined;
      this.listQuery.stateId = undefined;
    }
    if (!this.listQuery.cityId) {
      this.listQuery.cityId = undefined;
    }
    if (this.listQuery.dealerId) {
      let params = {
        dealerId: undefined
      };
      params.dealerId = this.listQuery.dealerId;
      this.getDealerCityList(params);
    }
    if (this.listQuery.agentId) {
      let params = {
        agentId: undefined
      };
      params.agentId = this.listQuery.agentId;
      this.getAgentDealersList(params);
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async downloadInvoice(id = 0) {
    // get invoice url
    this.listLoading = true;
    const url = await (0, _ordersDispatches.generateInvoice)(id);
    if (url) {
      window.open(url, "_blank");
      // const filename = "invoice_" + id + ".pdf";
      // this.downloadFile(data.url, filename);
    } else {
      this.$message({
        message: "Failed to generate invoice",
        type: "error"
      });
    }
    this.listLoading = false;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      const allDataResponse = await (0, _ordersDispatches.dealerSalesReport)(queryWithoutPagination);
      let startDate;
      let endDate;
      if (this.listQuery.startDate && this.listQuery.endDate) {
        startDate = this.listQuery.startDate;
        endDate = this.listQuery.endDate;
      }
      const allData = allDataResponse.data;
      let grandTotal = allData.reduce((total, item) => total + +item.totalAmount, 0);
      grandTotal = grandTotal.toFixed(2);
      const query = this.listQuery;
      const blob = await (0, _ordersDispatches.downloadExcel)({
        query,
        grandTotal,
        startDate,
        endDate
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sales-report.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  getSummaries(param) {
    const {
      columns,
      data
    } = param;
    const sums = [];
    const totalGrandTotalRow = [];
    columns.forEach((column, index) => {
      // first column
      if (index === 0) {
        sums[index] = "Total Page Sales: ";
        totalGrandTotalRow[index] = "Grand Total: "; // Add label for the total grand total row
        return;
      }
      // Grand Total column
      if (index === 6) {
        // get all values
        const values = data.map(item => Number(item[column.property]));
        // loop over & null check
        if (!values.every(value => isNaN(value))) {
          // sum them using reduce
          sums[index] = values.reduce((prev, curr) => {
            // value conversion (do check with Sandesh if toFixed something else required for decimal precision)
            const value = Number(curr);
            // null check
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0); // initial value
          // Calculate total grand total
          totalGrandTotalRow[index] = parseFloat(this.totalGrandTotal).toFixed(2); // Store the totalGrandTotal value
        } // loop if ends
      } // index check ends
    }); // column loop ends
    // Return sums and total grand total row
    if (sums && sums.length) {
      sums[6] = sums[6].toFixed(2);
    }
    return [,,,,, sums, totalGrandTotalRow]; // Return both the sums and total grand total row
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "SalesReportList",
  components: {
    Pagination: _index.default,
    Inr: _index3.default,
    Enabled: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;