"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _tagsView = require("@/store/modules/tags-view");
let default_1 = class extends _vuePropertyDecorator.Vue {
  get cachedViews() {
    return _tagsView.TagsViewModule.cachedViews;
  }
  get key() {
    return this.$route.path;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AppMain'
})], default_1);
var _default = exports.default = default_1;