import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { getOrders, orderExport, confirmOrder, createOrder, reviseOrder, downloadExcel, getOrdersProducts } from "@/api/order";
import { getDealerById, getDealerHoldInfo, searchDealers } from "@/api/dealers";
import Pagination from "@/components/Pagination/index.vue";
import { getQuery, formatJson } from "@/utils/index";
import { exportJson2Excel } from "@/utils/excel";
import Enabled from "@/components/Enabled/index.vue";
import Inr from "@/components/Inr/index.vue";
import { AdminModule } from "@/store/modules/admin";
import router from "@/router";
import OrderProductTab from "./components/OrderProductTab.vue";
import { getAgents } from "@/api/agents";
import { getDealersUsers } from "@/api/dealers-users";
import { getStates } from "@/api/locations/states";
import { getCities } from "@/api/locations/cities";
import { searchProducts } from "@/api/products";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.dealerListLoading = false;
    this.agentListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.isAgent = false;
    this.isReadOnly = false;
    this.isInvoiceManager = false;
    this.downloadLoading = false;
    this.dateRange = "";
    this.startDate = "";
    this.endDate = "";
    this.dialogFormVisible = false;
    this.dealerList = [];
    this.dealersUserList = [];
    this.agentList = [];
    this.stateList = [];
    this.cityList = [];
    this.orderExists = false;
    this.orderId = null;
    this.orderInfoData = null;
    this.dialogVisible = false;
    this.holdInfo = null;
    this.dealerId = null;
    this.dealersUserId = null;
    this.loggedInAdmin = null;
    this.agentId = null;
    this.productListLoading = false;
    this.products = [];
    this.selectedProduct = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      orderReference: undefined,
      dealerId: undefined,
      agentId: undefined,
      "billingAddress.cityId": undefined,
      "billingAddress.stateId": undefined,
      "ordersProductsDispatch.ordersProduct.productsVariationId": undefined,
      "ordersProductsDispatch.ordersProduct.productId": undefined,
      sort: "orderTimestamp,DESC",
      status: "Order Confirmed",
      orderTimestamp: undefined,
      join: "ordersProduct.ordersProductsDiscount",
      filter: {
        orderReference: "$startsL",
        status: "$eq",
        dealerId: "$eq",
        agentId: "$eq",
        orderTimestamp: "between",
        "billingAddress.cityId": "$eq",
        "billingAddress.stateId": "$eq",
        "ordersProductsDispatch.ordersProduct.productsVariationId": "$eq",
        "ordersProductsDispatch.ordersProduct.productId": "$eq"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "orderTimestamp,ASC"
    }, {
      label: "ID Descending",
      key: "orderTimestamp,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.totalColumn = {
      total: "Total",
      taxedAmount: "Taxed Amount",
      // freightCharges: 'Freight Charges',
      grandTotal: "Grand Total",
      amountReceived: "Amount Received"
    };
    this.statusColorMap = {
      "Order Placed": "primary",
      "Order Confirmed": "primary",
      "Partial Payment": "warning",
      "RTGS Payment": "warning",
      "Order Delivered": "success",
      "Order Cancelled": "danger",
      "Order Partial Cancelled": "danger",
      "Order Dispatched": "info",
      "Partial Dispatch": "info",
      "Delivery Confirmed": "success",
      "Not Delivered": "danger"
    };
    this.statusOptions = [
    // { label: "Order Placed", key: "Order Placed" },
    {
      label: "Order Confirmed",
      key: "Order Confirmed"
    },
    // { label: "Partial Payment", key: "Partial Payment" },
    // { label: "RTGS Payment", key: "RTGS Payment" },
    {
      label: "Order Dispatched",
      key: "Order Dispatched"
    }, {
      label: "Partial Dispatch",
      key: "Partial Dispatch"
    }, {
      label: "Order Delivered",
      key: "Order Delivered"
    },
    // { label: "Delivery Confirmed", key: "Delivery Confirmed" },
    {
      label: "Not Delivered",
      key: "Not Delivered"
    }, {
      label: "Order Cancelled",
      key: "Order Cancelled"
    }, {
      label: "Order Partial Cancelled",
      key: "Order Partial Cancelled"
    }];
    this.reviseOrderFlag = false;
    this.selectedOrderIds = [];
  }
  activated() {
    this.loggedInAdmin = AdminModule;
    if (AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    if (AdminModule.roles[0] === "Invoice Manager") {
      this.isInvoiceManager = true;
    }
    // check if logged in user is Agent/Admin
    if (AdminModule.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = AdminModule.agentId;
    }
    if (this.$route.query.status) {
      this.listQuery.status = this.$route.query.status;
    }
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateRange = {
        start: this.$route.query.startDate,
        end: this.$route.query.endDate
      };
    }
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    this.getList();
    // fetch states
    this.getStateList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await getOrders(getQuery(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await searchDealers({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getProductList(query) {
    try {
      this.productListLoading = true;
      if (query && query.length > 2) {
        this.products = await searchProducts(getQuery({
          keyword: query
        }));
      }
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  async validateDealer() {
    try {
      let message = null;
      const dealer = await getDealerById(this.dealerId);
      // set agent ID
      this.agentId = dealer.agentId;
      if (dealer.status !== "Approved") {
        message = "Dealer not approved";
      } else if (+dealer.creditLimitUsed >= +dealer.creditLimit) {
        message = "Dealer has reached maximum credit limit";
      }
      if (message) {
        this.$message({
          type: "warning",
          message,
          showClose: true
        });
        this.dealerId = null;
        return false;
      } else {
        this.fetchDealersUser();
      }
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchDealersUser() {
    try {
      this.dealersUserList = [];
      if (this.dealerId) {
        console.log("inside if dealerId", this.dealerId);
        this.dealersUserList = await getDealersUsers(getQuery({
          dealerId: this.dealerId,
          filter: {
            dealerId: "$eq"
          }
        }));
        if (this.dealersUserList.length === 1) {
          this.dealersUserId = this.dealersUserList[0].id;
        }
      }
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getAgentList(query = null) {
    try {
      if (query && query.length > 2) {
        this.agentListLoading = true;
        this.agentList = await getAgents(getQuery({
          name: query,
          filter: {
            name: "$contL"
          }
        }));
      }
      this.agentListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getStateList(countryId = 1) {
    try {
      this.stateListLoading = true;
      this.stateList = await getStates(getQuery({
        enabled: true,
        countryId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          countryId: "$eq"
        }
      }));
      this.stateListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getCityList(stateId = 0) {
    try {
      this.cityListLoading = true;
      this.cityList = await getCities(getQuery({
        enabled: true,
        stateId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          stateId: "$eq"
        }
      }));
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async loadProductComponent(order) {
    this.dialogVisible = true;
    this.orderInfoData = order;
  }
  handleProductSelection(value) {
    if (value) {
      if (value.startsWith("product-")) {
        this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = +value.replace("product-", "");
        this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = undefined;
      } else if (value.startsWith("variation-")) {
        this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = undefined;
        this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = +value.replace("variation-", "");
      }
    } else {
      // Clear the product filters when value is null or empty
      this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = undefined;
      this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = undefined;
    }
    this.handleFilter();
  }
  handleFilter() {
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      this.listQuery.orderTimestamp = [this.startDate, this.endDate].join(",");
    } else {
      this.listQuery.orderTimestamp = "";
    }
    // fetch city based on state selected
    if (this.listQuery["billingAddress.stateId"]) {
      this.getCityList(this.listQuery["billingAddress.stateId"]);
    } else {
      // empty city
      this.cityList = [];
      // reset city filter
      this.listQuery["billingAddress.cityId"] = undefined;
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  async dateval() {
    this.getList();
  }
  async fetchHoldInfo(dealerId = 0) {
    const data = await getDealerHoldInfo(dealerId);
    if (data) {
      this.holdInfo = "Held By: " + data.holdByAdmin.fullName + "<br />Held Date: " + this.$moment(data.holdTimestamp, "YYYY-MM-DD").format("DD-MM-YYYY") + "<br /> Reason: " + data.holdReason;
    }
  }
  async searchUsers(query = "") {
    try {
      this.orderExists = false;
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await searchDealers({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {
      this.$notify({
        title: "Error",
        type: "error",
        message: error.toString()
      });
    }
  }
  async checkInCartOrder(dealerId = 0, dealersUserId = 0) {
    if (dealerId) {
      this.orderId = null;
      const inCartOrder = await getOrders(getQuery({
        dealerId,
        dealersUserId,
        sort: "id,DESC",
        status: "in cart",
        limit: 1,
        filter: {
          dealerId: "$eq",
          dealersUserId: "$eq",
          status: "$eq"
        }
      }));
      if (inCartOrder.data.length) {
        this.orderId = inCartOrder.data[0].id;
        this.orderExists = true;
      } else {
        // create new order
        const order = await createOrder({
          dealerId,
          dealersUserId: this.dealersUserId,
          agentId: this.listQuery.agentId || this.agentId
        });
        this.orderId = order.id;
        this.createOrder();
      }
    } else {
      this.$message({
        type: "warning",
        message: "Please select user"
      });
    }
  }
  async createOrder() {
    this.dialogFormVisible = false;
    router.push({
      path: "/order/modify/" + this.orderId
    });
  }
  async resetOrder() {
    this.dialogFormVisible = true;
    this.orderExists = false;
    this.dealerList = [];
    this.dealersUserList = [];
    this.dealerId = null;
    this.dealersUserId = null;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      let startDate = "";
      let endDate = "";
      if (this.listQuery.startDate && this.listQuery.endDate) {
        startDate = this.listQuery.startDate;
        endDate = this.listQuery.endDate;
      }
      // let datas: any = await this.getListWithoutPagination();
      // let data = datas.data;
      const blob = await downloadExcel({
        query,
        startDate,
        endDate
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "orders.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async handleDownload() {
    const orderFilter = Object.assign({}, this.listQuery);
    delete orderFilter.limit;
    delete orderFilter.page;
    this.downloadLoading = true;
    const tHeader = ["Order Time", "Order Reference", "Status", "User Name", "Mobile Number", "Organization", "GST Number", "PAN Number", "Broker", "Billing Address", "Shipping Address", "Product", "Quantity", "Rate", "Total Amount", "Tax Amount",
    // 'Freight Charge',
    "Grand Total", "Payment", "Balance Payment", "Delivery Option", "VRL", "Nominated Transport Details"];
    const filterVal = ["orderTimestamp", "orderReference", "status", "user.fullName", "user.mobileNumber", "user.organization.name", "user.organization.gstNumber", "user.organization.panNumber", "broker.name", "address", "shippingAddress", "products", "quantity", "rate", "totalAmount", "taxAmount",
    // 'freightCharges',
    "grandTotal", "amountReceived", "balance", "deliveryOption", "vrlDeliveryLocation.name", "nominatedTransportDetails"];
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      var exportData = await orderExport({
        startDate: this.startDate,
        endDate: this.endDate
      });
      const data = formatJson(filterVal, exportData);
      exportJson2Excel(tHeader, data, this.$tc("route.orderManagement.orders.title"));
      this.downloadLoading = false;
    } else {
      this.$message({
        type: "info",
        message: "Please select a date range"
      });
      this.downloadLoading = false;
    }
  }
  isSuperAdmin() {
    return ["Super Admin"].includes(AdminModule.roles.toString());
  }
  async confirmOrder(order = null, event) {
    if (order) {
      try {
        order.status = "loading";
        const data = await confirmOrder(order.id);
        this.getList();
      } catch (error) {
        order.status = "in cart";
      }
    }
  }
  dispatchOrder(orderId) {
    router.push({
      path: "/orders-dispatches/list/" + orderId
    });
  }
  async _reviseOrder(orderId = 0) {
    try {
      this.reviseOrderFlag = true;
      const confirm = await this.$confirm("Are you sure to recalculate this order?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "el-button--success",
        cancelButtonClass: "el-button--danger",
        type: "warning"
      });
      // check if confirm
      if (confirm === "confirm") {
        const data = await reviseOrder({
          id: orderId,
          saveDiscounts: true,
          skipReturn: true
        });
        this.$message({
          type: "success",
          message: "Order recalculated successfully"
        });
        await this.getList();
      }
    } catch (error) {
      this.$message({
        type: "error",
        message: error.toString()
      });
    } finally {
      this.reviseOrderFlag = false;
    }
  }
  handleSelectionChange(selectedOrders) {
    // empty Selection
    this.selectedOrderIds = [];
    // add ids
    this.selectedOrderIds = selectedOrders.map(order => order.id);
  }
  async showSelectedProductInfo() {
    // get orders-products-dispatches for selected order ids
    const ordersProduct = await getOrdersProducts(getQuery({
      orderId: this.selectedOrderIds.join(","),
      sort: ["schemesCategoryId,DESC", "productId,DESC"],
      filter: {
        orderId: "$in"
      }
    }));
    let schemesCategoryIndexMapping = {};
    const schemesCategoryWiseData = [];
    // loop over
    ordersProduct.forEach(ordersProduct => {
      // get schemesCategoryId
      const schemesCategoryId = ordersProduct.schemesCategoryId || 0;
      // check if it doesn't exist
      if (!(schemesCategoryId in schemesCategoryIndexMapping)) {
        var _ordersProduct$scheme;
        // push
        const newIndex = schemesCategoryWiseData.push({
          id: "sc-" + schemesCategoryId,
          product: {
            name: ((_ordersProduct$scheme = ordersProduct.schemesCategory) === null || _ordersProduct$scheme === void 0 ? void 0 : _ordersProduct$scheme.name) || "NA"
          },
          productsVariation: null,
          mrp: null,
          ordersProductsDiscount: [],
          nettRate: null,
          quantity: 0,
          quantityDispatched: 0,
          numberOfCartons: 0,
          subTotal: 0,
          taxedAmount: 0,
          children: []
        }) - 1;
        // add to mapping
        schemesCategoryIndexMapping[schemesCategoryId] = newIndex;
      }
      // get index
      const index = schemesCategoryIndexMapping[schemesCategoryId];
      // change for  quantity
      let quantity = +ordersProduct.quantity;
      // check if order is Order Partial Cancelled
      if (ordersProduct.order.status === 'Order Partial Cancelled') {
        quantity = ordersProduct.quantityDispatched;
      }
      // increase
      schemesCategoryWiseData[index].quantity += quantity;
      schemesCategoryWiseData[index].quantityDispatched += +ordersProduct.quantityDispatched;
      schemesCategoryWiseData[index].numberOfCartons += +ordersProduct.numberOfCartons;
      schemesCategoryWiseData[index].subTotal += +ordersProduct.subTotal;
      schemesCategoryWiseData[index].taxedAmount += +ordersProduct.taxedAmount;
      // add to child
      schemesCategoryWiseData[index].children.push(ordersProduct);
    });
    this.orderInfoData = {
      ordersProduct: schemesCategoryWiseData
    };
    this.dialogVisible = true;
  }
};
default_1 = __decorate([Component({
  name: "OrderList",
  components: {
    Pagination,
    Enabled,
    Inr,
    OrderProductTab
  }
})], default_1);
export default default_1;