"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _scrollTo = require("@/utils/scroll-to");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  get currentPage() {
    return +this.page;
  }
  set currentPage(value) {
    this.$emit('update:page', value);
  }
  get pageSize() {
    return +this.limit;
  }
  set pageSize(value) {
    this.$emit('update:limit', value);
  }
  handleSizeChange(value) {
    this.$emit('pagination', {
      page: this.currentPage,
      limit: value
    });
    if (this.autoScroll) {
      (0, _scrollTo.scrollTo)(0, 800);
    }
  }
  handleCurrentChange(value) {
    this.$emit('pagination', {
      page: value,
      limit: this.pageSize
    });
    if (this.autoScroll) {
      (0, _scrollTo.scrollTo)(0, 800);
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "total", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 1
})], default_1.prototype, "page", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 2
})], default_1.prototype, "limit", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: () => [2, 10, 20, 30, 50]
})], default_1.prototype, "pageSizes", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'total, sizes, prev, pager, next, jumper'
})], default_1.prototype, "layout", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: true
})], default_1.prototype, "background", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: true
})], default_1.prototype, "autoScroll", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "hidden", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Pagination'
})], default_1);
var _default = exports.default = default_1;