"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersProductsDispatches = require("@/api/orders-products-dispatches");
var _index = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _router = _interopRequireDefault(require("@/router"));
var _ordersDispatches = require("@/api/orders-dispatches");
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.list = [];
    this.listLoading = false;
    this.loadingForRequestApproval = false;
    this.loadingForApprove = false;
    this.loadingForReject = false;
    this.loadingForPreview = false;
    this.loggedInAdmin = null;
    this.formData = {
      id: null,
      status: "",
      tdsAmount: 0
    };
    this.rejectDialogFormVisible = false;
    this.approveDialogFormVisible = false;
    // private dispatchInfo: IOrdersDispatchData;
    this.dispatchInfo = null;
    this.showRequestApprovalButton = false;
    this.showApproveButton = false;
    this.showRejectButton = false;
    this.ordersDispatchId = 0;
    this.showPreviewButton = false;
    this.rules = {
      rejectReason: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      }
    };
  }
  activated() {
    this.loggedInAdmin = _admin.AdminModule;
    const id = this.$route.params && this.$route.params.id;
    this.ordersDispatchId = parseInt(id);
    this.getList(this.ordersDispatchId);
  }
  async getList(ordersDispatchId) {
    try {
      this.listLoading = true;
      // get products in dispatch
      const data = await (0, _ordersProductsDispatches.listOrdersProductsDispatches)(ordersDispatchId);
      for (const item of data) {
        item.isDisabled = true;
        item.maxQuantity = item.quantity;
      }
      this.list = data;
      // get dispatch info
      const info = await (0, _ordersDispatches.getOrdersDispathById)(ordersDispatchId);
      // editing flag for terms of delivery
      if (info.status === "Request Approval") {
        info.editingTermsOfDelivery = false;
        info.modifiedTermsOfDelivery = info.termsOfDelivery;
      }
      // assign
      this.dispatchInfo = info;
      // set tds amount
      this.formData.tdsAmount = this.dispatchInfo.tdsAmount;
      // show action buttons if status is pending and logged in user is admin or invoice manager
      if ([1, 2, 4].includes(this.loggedInAdmin.adminsRoleId)) {
        const dateToCompare = this.$moment().subtract(24, "h").format("YYYY-MM-DDTHH:mm:ss.SSS");
        switch (this.dispatchInfo.status) {
          case "Request Approval":
            this.showRequestApprovalButton = false;
            this.showApproveButton = true;
            this.showRejectButton = true;
            this.showPreviewButton = true;
            break;
          case "Approved":
            this.showRequestApprovalButton = false;
            this.showApproveButton = false;
            this.showPreviewButton = false;
            // show reject button if duration after approval is less than 24 hrs compare with ackDate
            if (this.dispatchInfo.ackDate) {
              if (this.$moment(dateToCompare).isBefore(this.$moment(this.dispatchInfo.ackDate, "YYYY-MM-DDTHH:mm:ss.SSS"))) {
                this.showRejectButton = true;
              }
            } else {
              this.showRejectButton = true;
            }
            break;
          default:
            this.showApproveButton = false;
            this.showRejectButton = false;
            this.showRequestApprovalButton = false;
            this.showPreviewButton = false;
            break;
        }
      } else if (this.loggedInAdmin.adminsRoleId === 3 && this.dispatchInfo.status === "Pending") {
        this.showRequestApprovalButton = true;
        this.showApproveButton = false;
        this.showRejectButton = false;
      }
      // update dispatch ID for saving form
      this.formData.id = ordersDispatchId;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  validateForm(action = "Reject") {
    this.$refs.formData.validate(valid => {
      if (valid) {
        switch (action) {
          case "Request Approval":
            this.sendForApproval();
            break;
          case "Approve":
            this.approveForm();
            break;
          case "Reject":
            this.rejectForm();
            break;
          default:
            break;
        }
      } else {
        return false;
      }
    });
  }
  async sendForApproval() {
    this.formData.status = "Request Approval";
    await (0, _ordersDispatches.updateOrdersDispath)(this.formData.id, this.formData);
    _router.default.push("/orders-dispatches/list");
  }
  async approveForm() {
    this.approveDialogFormVisible = false;
    this.formData.status = "Approved";
    this.formData.invoiceManagerId = this.loggedInAdmin.id;
    await (0, _ordersDispatches.updateOrdersDispath)(this.formData.id, this.formData);
    _router.default.push("/orders-dispatches/list");
  }
  async rejectForm() {
    this.rejectDialogFormVisible = false;
    this.formData.status = "Rejected";
    this.formData.invoiceManagerId = this.loggedInAdmin.id;
    await (0, _ordersDispatches.updateOrdersDispath)(this.formData.id, this.formData);
    _router.default.push("/orders-dispatches/list");
  }
  async toggleDisable(index, row, action = null) {
    this.list[index].isDisabled = !row.isDisabled;
    if (action === "update") {
      await (0, _ordersProductsDispatches.modifyOrdersProductsDispatch)(row.id, {
        quantity: row.quantity
      });
      this.$notify({
        type: "success",
        title: "Success",
        message: "Quantity updated",
        duration: 2000
      });
      // if all quantity to be removed, delete table row
      if (row.quantity === row.maxQuantity) {
        this.list.splice(index, 1);
      }
    } else {
      this.list[index].quantity = row.maxQuantity;
    }
  }
  async downloadInvoice(id = 0) {
    // get invoice url
    this.listLoading = true;
    const data = await (0, _ordersDispatches.viewInvoice)(id);
    if (data && data.url) {
      window.open(data.url, "_blank");
      // const filename = "invoice_" + id + ".pdf";
      // this.downloadFile(data.url, filename);
    } else {
      this.$message({
        message: "Failed to generate invoice",
        type: "error"
      });
    }
    this.listLoading = false;
  }
  isSuperAdmin() {
    return ["Super Admin"].includes(_admin.AdminModule.roles.toString());
  }
  async saveTermsOfDelivery() {
    this.dispatchInfo.editingTermsOfDelivery = false;
    const updated = await (0, _ordersDispatches.updateOrdersDispath)(this.dispatchInfo.id, {
      termsOfDelivery: this.dispatchInfo.modifiedTermsOfDelivery
    });
    if (updated) {
      this.dispatchInfo.termsOfDelivery = updated.termsOfDelivery;
      this.dispatchInfo.modifiedTermsOfDelivery = updated.termsOfDelivery;
    }
  }
  editTermsOfDelivery() {
    this.dispatchInfo.editingTermsOfDelivery = true;
    this.dispatchInfo.modifiedTermsOfDelivery = this.dispatchInfo.termsOfDelivery;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "DispatchInfo",
  components: {
    Inr: _index.default
  }
})], default_1);
var _default = exports.default = default_1;