"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateInventoryInwardsLog = exports.manageInventoryInwardsLog = exports.getInventoryInwardsLogs = exports.getInventoryInwardsLogById = exports.deleteInventoryInwardsLog = exports.defaultIInventoryInwardsLogData = exports.createInventoryInwardsLog = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultIInventoryInwardsLogData = exports.defaultIInventoryInwardsLogData = {
  id: 0,
  productId: 0,
  product: {
    id: 0
  },
  productsVariationId: 0,
  productsVariation: {
    id: 0
  },
  inventoryInwardId: null,
  inventoryInward: {
    id: null
  },
  unitId: 0,
  unit: {
    id: 0
  },
  adminId: 0,
  admin: {
    id: 0
  },
  quantity: 0,
  looseQuantity: 0,
  cartonQuantity: 0,
  masterCarton: 0,
  // extra cols
  currentStock: 0,
  rowSpan: 1
};
const getInventoryInwardsLogs = params => (0, _request.default)({
  url: '/inventory-inwards-logs',
  method: 'get',
  params
});
exports.getInventoryInwardsLogs = getInventoryInwardsLogs;
const getInventoryInwardsLogById = id => (0, _request.default)({
  url: `/inventory-inwards-logs/${id}`,
  method: 'get'
});
exports.getInventoryInwardsLogById = getInventoryInwardsLogById;
const updateInventoryInwardsLog = (id, data) => (0, _request.default)({
  url: `/inventory-inwards-logs/${id}`,
  method: 'patch',
  data
});
exports.updateInventoryInwardsLog = updateInventoryInwardsLog;
const deleteInventoryInwardsLog = id => (0, _request.default)({
  url: `/inventory-inwards-logs/${id}`,
  method: 'delete'
});
exports.deleteInventoryInwardsLog = deleteInventoryInwardsLog;
const createInventoryInwardsLog = data => (0, _request.default)({
  url: '/inventory-inwards-logs',
  method: 'post',
  data
});
exports.createInventoryInwardsLog = createInventoryInwardsLog;
const manageInventoryInwardsLog = data => (0, _request.default)({
  url: '/inventory-inwards-logs/manage',
  method: 'post',
  data
});
exports.manageInventoryInwardsLog = manageInventoryInwardsLog;