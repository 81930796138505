"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Unit Manager",
      prop: "adminId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Select Unit Manager",
      disabled: _vm.isEdit || _vm.loggedInAdmin.adminsRoleId === 3
    },
    on: {
      change: _vm.fetchUnitList
    },
    model: {
      value: _vm.formData.adminId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "adminId", $$v);
      },
      expression: "formData.adminId"
    }
  }, _vm._l(_vm.unitManagers, function (admin) {
    return _c("el-option", {
      key: admin.id,
      attrs: {
        label: admin.fullName,
        value: admin.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Unit",
      prop: "unitId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "Select Unit",
      disabled: _vm.isEdit
    },
    on: {
      change: _vm.dealerOrUnitChanged
    },
    model: {
      value: _vm.formData.unitId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "unitId", $$v);
      },
      expression: "formData.unitId"
    }
  }, _vm._l(_vm.unitList, function (unit) {
    return _c("el-option", {
      key: unit.id,
      attrs: {
        label: unit.name,
        value: unit.id
      }
    }, [_vm._v(" " + _vm._s(unit.name) + " - " + _vm._s(unit.code) + " ")]);
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dealer",
      prop: "dealerId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      "remote-method": _vm.getDealerList,
      loading: _vm.dealerLoading,
      placeholder: "Select Dealer",
      disabled: _vm.isEdit
    },
    on: {
      change: _vm.dealerOrUnitChanged
    },
    model: {
      value: _vm.formData.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealerId", $$v);
      },
      expression: "formData.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "14px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "14px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), _vm.dealerAddress ? _c("div", [_vm._v(" Address: " + _vm._s(_vm.dealerAddress) + " ")]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Transporter",
      prop: "dealersTransporterId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Select Transporter"
    },
    model: {
      value: _vm.formData.dealersTransporterId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealersTransporterId", $$v);
      },
      expression: "formData.dealersTransporterId"
    }
  }, _vm._l(_vm.transporterList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.transporter.name + "-" + item.transporter.city.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Private Marka",
      prop: "privateMarka"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.privateMarka,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "privateMarka", $$v);
      },
      expression: "formData.privateMarka"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dispatch Date",
      prop: "dispatchDate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "Select Date",
      format: "dd-MM-yyyy",
      "picker-options": {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    },
    model: {
      value: _vm.formData.dispatchDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dispatchDate", $$v);
      },
      expression: "formData.dispatchDate"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Narrations",
      prop: "comments"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3
    },
    model: {
      value: _vm.formData.comments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1)], 1)], 1), _c("el-form-item", [_c("el-popconfirm", {
    attrs: {
      title: "Are you sure to dispatch?"
    },
    on: {
      confirm: _vm.submitForm
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      slot: "reference",
      disabled: _vm.quantityToSettle === 0,
      type: "success"
    },
    slot: "reference"
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1)], 1)], 1)])]), _c("el-col", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "form-block"
  }, [_vm.order ? _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Order Details")])]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(" ID: " + _vm._s(_vm.order.id) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(" Reference: " + _vm._s(_vm.order.orderReference) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(" Qty Ordered: " + _vm._s(_vm.order.totalQuantity) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(" Qty Dispatched: " + _vm._s(_vm.order.totalQuantityDispatched) + " ")])]) : _vm._e()], 1)])], 1), _c("div", {
    staticClass: "el-form-item__label",
    staticStyle: {
      float: "right"
    }
  }, [_vm._v(" Total Carton(s): " + _vm._s(_vm.totalCartons) + " ")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.ordersProductsListLoading,
      expression: "ordersProductsListLoading"
    }],
    key: _vm.tableKey,
    ref: "ordersProductsDispactchesTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "mini",
      data: _vm.ordersProductsDispatch,
      border: "",
      fit: "",
      height: "250"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "50",
      align: "left",
      label: "SNo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "250px",
      align: "left",
      label: "Product",
      prop: "productId"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.product.name) + " "), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "110",
      align: "left",
      label: "Color",
      prop: "productsVariationId"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.productsVariation.name) + " "), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Quantity",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "100",
      align: "right",
      label: "Ordered",
      prop: "quantityOrdered"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "right",
      label: "Dispatched",
      prop: "quantityDispatched"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "right",
      label: "Pending",
      prop: "quantityPending"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "right",
      label: "Available",
      prop: "quantityAvailable"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "160px",
      align: "left",
      label: "Adjusted"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            disabled: !scope.row.quantityAvailable || scope.row.quantityAvailable < scope.row.product.piecesPerBox,
            size: "mini",
            min: 0,
            max: scope.row.quantityPending > scope.row.quantityAvailable ? scope.row.quantityAvailable : scope.row.quantityPending,
            step: scope.row.product.piecesPerBox,
            "step-strictly": ""
          },
          on: {
            change: function ($event) {
              return _vm.quantityUpdated(scope.row);
            }
          },
          model: {
            value: scope.row.quantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "quantity", $$v);
            },
            expression: "scope.row.quantity"
          }
        })];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "right",
      label: "CR",
      prop: "totalCartonCount"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;