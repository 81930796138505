"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _pincode = require("@/api/pincode");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _router = _interopRequireDefault(require("@/router"));
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.cityId = 0;
    this.listQuery = {
      page: 1,
      limit: 10,
      pincodeName: undefined,
      pincode: undefined,
      cityId: undefined,
      enabled: true,
      sort: 'id,ASC',
      filter: {
        pincodeName: '$contL',
        pincode: '$contL',
        cityId: '$eq',
        enabled: '$eq'
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  activated() {
    if (_admin.AdminModule.roles[0] === 'Read Only') {
      this.isReadOnly = true;
    }
    this.cityId = parseInt(this.$route.params && this.$route.params.id);
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      this.listQuery.cityId = this.cityId;
      const data = await (0, _pincode.getPincodes)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc('table.deleteConfirmation', undefined, {
        name: data.pincodeName
      }), this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => {
        (0, _pincode.deletePincode)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      });
    }
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'fullname', 'email', 'contact', 'role'];
    const filterVal = ['id', 'fullName'];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
  edit(id = 0) {
    const query = this.listQuery;
    delete query.filter;
    _router.default.push({
      path: '/pincode/edit/' + id,
      query
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PincodeList',
  components: {
    Pagination: _index.default,
    Enabled: _index4.default,
    Inr: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;