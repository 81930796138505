"use strict";

var _interopRequireDefault = require("C:/Users/sandy/Desktop/CONA/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRange = exports.getRanges = exports.getRangeById = exports.fileTransferHeaders = exports.deleteRange = exports.defaultRangeData = exports.createRange = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultRangeData = exports.defaultRangeData = {
  id: 0,
  enabled: true,
  name: '',
  tallyAlias: '',
  icon: '',
  image: '',
  description: '',
  priority: 0,
  featured: false,
  bgcolor: null
};
const getRanges = params => (0, _request.default)({
  url: '/ranges',
  method: 'get',
  params
});
exports.getRanges = getRanges;
const getRangeById = id => (0, _request.default)({
  url: `/ranges/${id}`,
  method: 'get'
});
exports.getRangeById = getRangeById;
const updateRange = (id, data) => (0, _request.default)({
  url: `/ranges/${id}`,
  method: 'patch',
  data
});
exports.updateRange = updateRange;
const deleteRange = id => (0, _request.default)({
  url: `/ranges/${id}`,
  method: 'delete'
});
exports.deleteRange = deleteRange;
const createRange = data => (0, _request.default)({
  url: '/ranges',
  method: 'post',
  data
});
exports.createRange = createRange;